import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
  public logsEnabled = true;

  setLogging(val: boolean) {
    this.logsEnabled = val;
  }

  log(...args: any) {
    if (this.logsEnabled) {
      console.log.apply(console, args);
    }
  }

  error(...args: any) {
    if (this.logsEnabled) {
      console.error.apply(console, args);
    }
  }

  warn(...args: any) {
    if (this.logsEnabled) {
      console.warn.apply(console, args);
    }
  }
}
