import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { EventEmitterService, LoaderService, RestService } from '../services';
import { API_KEYPOINT, APP_EVENTS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private restService: RestService,
    private loadingService: LoaderService,
    private eventEmitterService: EventEmitterService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !this.isRefreshing
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null); // Clear previous refresh token value

      if (this.authService.isLoggedIn()) {
        return this.restService
          .post(
            API_KEYPOINT.refreshToken,
            this.authService.getAuthData().refreshToken
          )
          .pipe(
            switchMap((data: any) => {
              if (data.code === 200) {
                let userData = this.authService.getAuthData();
                userData.refreshToken = data.result.refreshToken;
                userData.token = data.result.token;
                this.authService.setSession(userData);
                this.refreshTokenSubject.next(data.result.token); // Broadcast new token
                return next.handle(request);
              } else {
                // Handle other error codes appropriately
                throw new HttpErrorResponse({ status: data.code, error: data });
              }
            }),
            catchError((error) => {
              if (error.status === 401) {
                this.eventEmitterService.emit({
                  type: APP_EVENTS.WARNING_POP_UP,
                  data: true,
                });
                // Delay the logout by 2 seconds
                setTimeout(() => {
                  this.eventEmitterService.emit({
                    type: APP_EVENTS.WARNING_POP_UP,
                    data: false,
                  });
                  this.authService.logout();
                  this.loadingService.setLoading(false);
                }, 3000);
              }
              this.refreshTokenSubject.error(error); // Broadcast error
              return throwError(() => error);
            }),
            finalize(() => {
              this.isRefreshing = false;
            })
          );
      } else {
        this.authService.logout();
        this.loadingService.setLoading(false);
      }
    } else {
      // Queue other requests while the token is being refreshed
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(request))
      );
    }

    // If already refreshing, queue the request
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(request))
    );
  }
}
