import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-deletepopup',
  templateUrl: './deletepopup.component.html',
  styleUrls: ['./deletepopup.component.scss'],
})
export class DeletepopupComponent {
  @Input() show: any;
  @Output() onCancel = new EventEmitter<any>();
  @Output() onConfirm = new EventEmitter<any>();
  @Input() title: any;
  @Input() subTitle?: any;

  constructor() {}

  close() {
    this.onCancel.emit(false);
  }

  confirm() {
    this.onConfirm.emit(true);
  }
}
