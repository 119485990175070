import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
  constructor(private eRef: ElementRef) { }
  @Input() x = 0;
  @Input() y = 0;
  @Input() menuitems = '';
  @Input() selectedAssets = '';
  theMenuItems: any = [];
  showSubContextMenu: boolean = false;
  @Output() menuItemSelected = new EventEmitter();
  ngOnInit() {
    this.theMenuItems = this.menuitems;
  }
  subContextMenuList: any = [
    { id: 'whatsapp', name: 'Whatsapp', icon: '/assets/images/pages/context-menu/whatsapp.svg' },
    { id: 'mail', name: 'Mail', icon: '/assets/images/pages/context-menu/mail-icon.svg' },
    { id: 'copyLink', name: 'Copy Link', icon: '/assets/images/pages/context-menu/copy-link.svg' },
    { id: 'gDrive', name: 'G-Drive', icon: '/assets/images/pages/context-menu/g-drive.svg' }
  ]
  checkBeforeCloseOfContextMenu(event: any) {
    event.stopPropagation();
  }
  outputSelectedMenuItem(menuitem: any) {
    if (menuitem.id == "share") {
      this.showSubContextMenu = !this.showSubContextMenu;
    }
    this.menuItemSelected.emit(menuitem.id);
  }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    let clickedInside = this.eRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.menuItemSelected.emit(false);
    }

  }
  toggleSubContextMenu() {
    this.showSubContextMenu = !this.showSubContextMenu
  }

}
