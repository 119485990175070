<p-dialog [modal]="true" [(visible)]="isModalClose" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
    [style]="{width: '1100px'}" [showHeader]="false" [resizable]="false" [closeOnEscape]="false">
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="card drag-drop-panel radius-24 text-center h-100">
                <input [disabled]="selectedFilesList.length > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)" #fileInput class="btn bg-transparent w-100 drag-drop-file h-100" type="file" [multiple]="allowMultipleSelection()"
                    ngFileDragDrop (change)="selectFiles($event)" title="" />
                <div class="card-body d-flex flex-column justify-content-center min-h-auto">
                    <div class="p-2 mb-4">
                        <img src="assets/images/icons/upload-asset.png" alt="" width="" height="">
                    </div>
                    <div class="upload-btn-wrapper w-100">
                        <button [disabled]="selectedFilesList.length > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)" class="btn bg-transparent w-100"> Drag & Drop files to upload </button>
                    </div>
                    <div class="fs-24 my-3">
                        Or
                    </div>
                    <div class="my-2">
                        <div class="row">
                            <div class="col-8 m-auto">
                                <div class="upload-btn-wrapper w-100">
                                    <button [disabled]="selectedFilesList.length > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)" class="btn btn-primary w-100"> Browse File </button>
                                    <input [disabled]="selectedFilesList.length > 0 && modalType==='zip' || (!uploadDone && isUploadClicked)" type="file" multiple={{allow}} #inputFile (change)="selectFiles($event)"
                                        (click)="inputFile.value = ''" title="" class="cursorpointer" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card h-100 border-0 upload-files-items">
                <div class="card-body min-h-auto">
                    <h3 class="font-bold">Selected Files</h3>
                    <div class="d-grid d-flex gap-3 font-medium justify-content-between"
                        *ngIf="selectedFilesList && selectedFilesList.length > 0">
                        <div> Total Uploads: {{selectedFilesList.length}} ({{totalAssetSize | formatBytes:2:'exactValue'}})
                        </div>
                        <div> Successful: {{successFileDetails.length}}
                        </div>
                        <div> Failed: {{fileAlreadyExistsOrError.length}}
                        </div>
                    </div>
                    <div class="upload-list-panel mt-3">
                        <ng-container>
                            <div class="upload-list" *ngFor="let file of selectedFilesList; let i = index">
                                <div class="d-flex d-grid gap-3 pt-3 pb-4">
                                    <div class="icon-left justify-content-start">
                                        <i *ngIf="!successFileNameArray.includes(file.name) && !fileAlreadyExistsOrError.includes(replaceName(file.name)) && !fileAlreadyExistsOrError.includes(file.name)"
                                            class="nm_icon mt-1 align-items-start">
                                            <img src="/assets/images/icons/ic_folder_upload.svg" alt="">
                                        </i>
                                        <i *ngIf="successFileNameArray.includes(file.name) && !fileAlreadyExistsOrError.includes(replaceName(file.name))  && !fileAlreadyExistsOrError.includes(file.name)"
                                            class="nm_icon">
                                            <img src="/assets/images/icons/btn-select.svg" style="height: 17px;" alt="">
                                        </i>
                                        <i *ngIf="fileAlreadyExistsOrError.includes(replaceName(file.name))  || fileAlreadyExistsOrError.includes(file.name) || unSupportedFile.includes(file.name)"
                                            class="nm_icon">
                                            <img src="assets/images/icons/alert.png" alt="">
                                        </i>
                                    </div>
                                    <div *ngIf="!fileAlreadyExistsOrError.includes(replaceName(file.name))  && !fileAlreadyExistsOrError.includes(file.name)"
                                        class="file-name flex-grow-1 fs-18 font-bold text-break "> {{file.name}}

                  </div>
                  <div
                    *ngIf="fileAlreadyExistsOrError.includes(replaceName(file.name))  || fileAlreadyExistsOrError.includes(file.name)"
                    class="file-name flex-grow-1 fs-18 font-bold text-break text-danger "> {{file.name}}

                  </div>
                  <div class="asset-size" *ngIf="!fileAlreadyExistsOrError.includes(file.name)">
                    {{file.size | formatBytes:2:'exactValue'}}</div>
                  <div
                    *ngIf="(!fileAlreadyExistsOrError.includes(replaceName(file.name))  && !fileAlreadyExistsOrError.includes(file.name))"
                    class="icon-end">
                    <button *ngIf="progressList[i]==0 || progressList[i]==null" class="btn btn-sm icon-only-btn"> <img
                        src="/assets/images/icons/ic_delete_upload.svg" (click)="removeImage(file,i)"> </button>
                  </div>
                  <div
                    *ngIf="fileAlreadyExistsOrError.includes(replaceName(file.name))  || fileAlreadyExistsOrError.includes(file.name)"
                    class="icon-end">
                    <div class="asset-size text-danger">{{file.size | formatBytes:2:'exactValue'}}</div>
                    <button class="btn btn-sm icon-only-btn" *ngIf="internalServerErrorWithFile.includes(file)">
                      <img src="assets/images/icons/ic_retry.svg" (click)="singleRetry(file,i)" alt="refresh">
                    </button>
                    <button class="btn btn-sm icon-only-btn">
                      <img src="assets/images/icons/ic_delete.svg" (click)="removeImage(file,i)" alt="delete">
                    </button>
                  </div>
                </div>
                <div *ngIf="internalServerErrorWithFile.includes(file) && message.length == 0"
                  class="flex-grow-1 fs-18 font-bold text-break text-danger "> Upload failed due to a
                  server error
                </div>
                <div *ngIf="unSupportedFile.includes(file)" class="flex-grow-1 fs-18 font-bold text-break text-danger ">
                  This File is not Supported .
                </div>
                <div
                  *ngIf="!internalServerErrorWithFile.includes(file) && !unSupportedFile.includes(file) && fileAlreadyExistsOrError.includes(file.name) "
                  class="flex-grow-1 fs-18 font-bold text-break text-danger "> File already exists!
                </div>
                <div class="progress-bar-group pt-2"
                  *ngIf="progressOfUplodingFile[i] && progressOfUplodingFile[i].i==i && !internalServerErrorWithFile.includes(file)">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar"
                      attr.aria-valuenow="{{ progressOfUplodingFile[i]?.progress }}" aria-valuemin="0"
                      aria-valuemax="100" [ngStyle]="{ width: progressOfUplodingFile[i]?.progress + '%' }"> </div>
                  </div>
                  <div class="dam-primary-100 fs-14 font-bold mt-1">
                    {{ progressOfUplodingFile[i].progress }}%
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="message.length" role="alert">
            <li class="invalid-feedback d-block font-medium mb-2 p-3">{{ message[0] }}</li>
          </div>
        </div>
        <div *ngIf="modalType === 'zip' && zipSizeValue==true &&isSaveDisabled==false"
          class="valid-feedback d-block font-medium mb-2 p-3">
          File size is greater than 100 MB, it could take longer ...
        </div>
        <div *ngIf="isSaveButtonClicked && uploadDone==false && uploadDoneWithError==false"
          class="valid-feedback d-block font-medium mb-2 p-3">
          Please wait while your files are being uploaded....
        </div>
        <div *ngIf="modalType === 'zip' && selectedFilesList.length> 1"
          class="invalid-feedback d-block font-medium mb-2 p-3">
          Please do not upload over 1 files at once.
        </div>
        <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
          <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
            [disabled]="(!uploadDone && isUploadClicked)">Cancel</button>
          <!-- <button type="button" class="btn btn-primary btn-min-width" [disabled]="!selectedFiles || isSaveDisabled"></button> -->
          <button *ngIf="uploadDone==false " type="button" class="btn btn-primary btn-min-width"
            [disabled]="(modalType === 'zip' && selectedFilesList.length> 1  )||(selectedFilesList.length<=0 || isSaveButtonClicked || isSaveDisabled) || isFileZip"
            (click)="uploadFiles()">Upload</button>
          <button *ngIf="uploadDone==true" type="button" class="btn btn-primary btn-min-width"
            [disabled]="fileAlreadyExistsOrError.length>0 || selectedFilesList.length==0"
            (click)="saveFiles();[$event.stopPropagation()]">Save</button>
          <button *ngIf="uploadDoneWithError==true && internalServerErrorWithFile.length>0 " type="button"
            class="btn btn-secondary btn-min-width" (click)="uploadFiles()">Retry Failed Uploads</button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
