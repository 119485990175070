<div class="flex-container">
  <div class="metrics">
    {{slide1}}s
  </div>
  <section class="range-slider">
    <input #rangeSlider [(value)]="valueMin" class="form-range" [min]="min" [max]="max" [step]="step" type="range" (change)="doChangeOnSlider($event.target)">
    <input #rangeSlider [(value)]="maxslide" class="form-range" [min]="min" [max]="max" [step]="step" type="range" (change)="doChangeOnSlider($event.target)">
  </section>
  <div class="metrics">
      {{slide2| number:'1.0-3'}}s
      <!-- {{slide2}}s       -->
  </div>
</div>
