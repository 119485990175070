import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppService } from './core/services/app.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './common/components/spinner/spinner.component';
import { DropdownModule } from 'primeng/dropdown';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { CoreModule } from './core/core.module';
import { AssetNotFoundComponent } from './common/components/asset-not-found/asset-not-found.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { AuthComponent } from './layout/auth/auth.component';
import { ClientComponent } from './layout/client/client.component';
import { MessageService } from 'primeng/api';
import { MenuItems } from './common/menu-items/menu-items';
import { WarningPopUpComponent } from './common/components/warning-pop-up/warning-pop-up.component';
import { TokenInterceptor } from './core/interceptors/token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SpinnerComponent,
    AssetNotFoundComponent,
    ClientComponent,
    WarningPopUpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    DropdownModule,
    DialogModule,
    DatePipe,
    CalendarModule,
    ToastModule,
  ],
  providers: [
    AppService,
    MessageService,
    MenuItems,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
