import { Component } from '@angular/core';

@Component({
  selector: 'app-shared-error-page',
  templateUrl: './shared-error-page.component.html',
  styleUrls: ['./shared-error-page.component.scss']
})
export class SharedErrorPageComponent {

}
