<p-dialog [modal]="true" [(visible)]="show" [showHeader]="false" class="onboard-user-popup" [resizable]="false"
  [draggable]="false" [style]="{'width': '500px', 'border-radius': '5px'}" [dismissableMask]="false">
  <div class="p-3 text-center">
    <img src="assets/images/icons/warningIcon.svg" alt="Warning" class="img-fluid mb-2" style="width: 100px;">
    <div class="modal-body p-4 pt-2">
      <h5 class="font-medium">{{heading}}</h5>
      <div class="sub-title">{{subHeading}}.</div>
    </div>
  </div>
</p-dialog>
