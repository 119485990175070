export const CLIPLIST_SORT_BY = [
  { name: 'Select', code: '' },
  { name: 'Asset Size', code: 'assetSize' },
  // { name: 'Asset Created At', code: 'assetCreatedAt' },
  // { name: 'Asset Name', code: 'assetName' },
  { name: 'Event Date', code: 'eventDate' },
  // { name: 'Event Location', code: 'eventLocation' },
  { name: 'Event Name', code: 'eventName' },
  { name: 'No. of downloads', code: 'downloads' },
];

export const AUTHMODES: { [key: string]: string } = {
  '1': 'google',
  '2': 'microsoft',
  '3': 'email',
  '4': 'saml',
};
export const SORT_BY_OPTIONS = [
  { id: 1, value: '', label: '-- Select --' },
  { id: 2, value: 'assetSize', label: 'Asset Size' },
  { id: 3, value: 'eventDate', label: 'Event Date' },
  { id: 4, value: 'eventName', label: 'Event Name' },
  { id: 5, value: 'downloads', label: 'No. of downloads' },
];

export const ORDER_BY_OPTIONS = [
  { id: 1, value: 'asc', label: 'Ascending' },
  { id: 2, value: 'desc', label: 'Descending' },
];
export const ASSET_TYPE_OPTIONS = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Images' },
  { id: 3, value: 2, label: 'Videos' },
  { id: 4, value: 4, label: 'Audios' },
];
export const ASSET_TYPE_OPTIONS_2 = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Images' },
  { id: 3, value: 2, label: 'Videos' },
  { id: 4, value: 3, label: 'Documents' },
  { id: 5, value: 4, label: 'Audios' },
];

export const PAGE_SIZES_OPTIONS = [
  { id: 1, value: 50, label: '50' },
  { id: 2, value: 100, label: '100' },
  { id: 3, value: 200, label: '200' },
  { id: 4, value: 500, label: '500' },
  { id: 5, value: 1000, label: '1000' },
];

export const ASSET_TYPE_FILTERS = [
  { id: 0, assetType: 'All', isSelected: true },
  { id: 1, assetType: 'Images', isSelected: false },
  { id: 2, assetType: 'Videos', isSelected: false },
  { id: 3, assetType: 'Audios', isSelected: false },
];

export const ACCESS_TYPE_FILTERS = [
  { id: 1, name: 'Read' },
  { id: 2, name: 'Read-Upload' },
  { id: 3, name: 'Read-Write-Upload' },
  { id: 4, name: 'Read-Write-Upload-Delete' },
];

export const TAG_STATUS_TYPE_OPTIONS = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Completed' },
  { id: 3, value: 2, label: 'Pending' },
];

export const TAG_TYPE_FILTERS = [
  { tagName: 'Manual' },
  { tagName: 'AI' },
  { tagName: 'Default' },
];
export const APP_CONSTANTS = {
  PAGE_SIZE: 10,
  PAGE_SIZE_50: 50,
};
export const APP_EVENTS = {
  BROWSER_BACK: 'BROWSER_BACK',
  TOGGLE_CLIENT_ADMIN_OVERFLOW: 'TOGGLE_CLIENT_ADMIN_OVERFLOW',
  HIDE_HEADER: 'HIDE_HEADER',
  TOGGLE_DIS_F_GLOB_UPLOAD: 'TOGGLE_DISPLAY_FOR_GLOBAL_UPLOAD',
  WARNING_POP_UP: 'WARNING_POP_UP',
};

export const SORT_BY_OPTIONS_2 = [
  { id: 1, value: '', label: '-- Select --' },
  { id: 2, value: 'assetSize', label: 'Asset Size' },
  { id: 3, value: 'eventDate', label: 'Event Date' },
  { id: 4, value: 'eventName', label: 'Event Name' },
];

export const PEOPLE_MANAGEMENT_FILTER = [
  { id: 1, value: '', label: 'All' },
  { id: 2, value: '1', label: 'Named' },
  { id: 3, value: '2', label: 'Un Named' },
  { id: 4, value: '3', label: 'Hidden' },
];

export const DUMMY_CATEGORIES = [
  {
    id: 1,
    title: 'Events',
    count: '3',
    assets: [
      {
        id: 11,
        assetName: '1.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/categories/events',
        thumbnailAssetName: '1.svg',
      },
      {
        id: 12,
        assetName: '2.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/categories/events',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 13,
        assetName: '3.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/categories/events',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
  {
    id: 2,
    title: 'Celebrities',
    count: '3',
    assets: [
      {
        id: 21,
        assetName: '1.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/celebrities',
        thumbnailAssetName: '1.svg',
      },
      {
        id: 22,
        assetName: '2.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/celebrities',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 23,
        assetName: '3.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/celebrities',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
  {
    id: 3,
    title: 'International Figures',
    count: '3',
    assets: [
      {
        id: 31,
        assetName: '1.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/international',
        thumbnailAssetName: '1.svg',
      },
      {
        id: 32,
        assetName: '2.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/international',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 33,
        assetName: '3.svg',
        assetType: 0,
        folderPath:
          'assets/images/pages/dashboard/dummy/categories/international',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
];

export const DUMMY_COLLECTIONS = [
  {
    id: 1,
    title: 'Images',
    count: '3',
    assets: [
      {
        id: 11,
        assetName: '1.svg',
        folderPath: 'assets/images/pages/dashboard/dummy/collections/image',
        thumbnailAssetName: '1.svg',
        assetType: 0,
      },
      {
        id: 12,
        assetName: '2.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/image',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 13,
        assetName: '3.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/image',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
  {
    id: 2,
    title: 'Videos',
    count: '3',
    assets: [
      {
        id: 21,
        assetName: '1.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/video',
        thumbnailAssetName: '1.svg',
      },
      {
        id: 22,
        assetName: '2.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/video',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 23,
        assetName: '3.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/video',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
  {
    id: 2,
    title: 'Need Review',
    count: '3',
    assets: [
      {
        id: 31,
        assetName: '1.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/review',
        thumbnailAssetName: '1.svg',
      },
      {
        id: 32,
        assetName: '2.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/review',
        thumbnailAssetName: '2.svg',
      },
      {
        id: 33,
        assetName: '3.svg',
        assetType: 0,
        folderPath: 'assets/images/pages/dashboard/dummy/collections/review',
        thumbnailAssetName: '3.svg',
      },
    ],
  },
];

export const APP_ROUTE = {
  home: '/main/home',
  profile: '/main/profile',
  changePassword: '/main/changepassword',
  dashboard: '/main/dashboard',
  manage: '/main/manage',
  roles: '/main/manage/roles',
  users: '/main/manage/users',
  events: '/main/manage/events',
  folders: '/main/manage/folders',
  tags: '/main/manage/tags',
  groups: '/main/manage/groups',
  clients: '/client/manage',
  searchDetails: '/main/search-details',
  assets: '/main/assets',
  allUploads: '/main/assets/all-uploads',
  coreUploads: '/main/assets/core-uploads',
  collections: '/main/collections/collection',
  categories: '/main/collections/category',
  cliplist: '/main/cliplist',
  peoples: '/main/peoples',
};

export const APP_PATTERN = {
  contactNumber: '^[0-9]{10}$',
  name: '^[a-zA-Z]*$',
  email: '^(?!.* )[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
  password:
    '^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_`~:;+=-]).{8,20}$',
  profAgency: '^[a-zA-Z0-9@.\\-_ ]+$',
};

export const API_KEYPOINT = {
  permission: {
    getPermissionsMenu: 'permission/getPermissionsMenu',
    getSideMenu: 'permission/getSideMenu',
  },
  elk: {
    searchAssets: 'elk/searchAssets/',
    syncAssets: 'elk/publishPendingAssetsToElk/',
    publishAssets: 'elk/publishAssetsToELK/',
    bulkAiTag: 'elk/bulkAiTagging',
  },
  dashboard: {
    getDownloadHistory: 'dashboard/getDownloadHistory',
    getStatus: 'dashboard/getStatus',
    getQueueStatus: 'dashboard/getQueueStatus',
    clearDoneListOfQueue: 'dashboard/clearDoneListOfQueue',
    deleteFromQueue: 'dashboard/deleteFromQueue/',
  },
  user: {
    userList: 'user/userList',
    getUserList: 'user/getUserList',
    userDetails: 'user/userDetails/',
    createUser: 'auth/signUp',
    editUser: 'user/editUser',
    deleteUser: 'user/deleteUser/',
    getUserRoles: 'user/getUserRoles',
    passwordReset: 'auth/passwordReset/',
  },
  role: {
    getRoles: 'role/getRoles',
    roleDelete: 'role/deleteRole/',
  },
  event: {
    getEventsList: 'event/getEventsList',
    createEvent: 'event/createEvent',
    editEvent: 'event/editEvent',
    getEventDetailsById: 'event/getEventDetailsById/',
    deleteEvent: 'event/deleteEvent/',
    getAssetsByEvent: 'event/getAssetsByEvent',
    getEvents: 'event/GetEvents',
    reassignEventToAsset: 'event/reassignEventToAsset',
  },
  assets: {
    assetDetails: 'asset/getAssetDetail',
    assetDelete: 'asset/deleteAsset',
    cliplist: 'asset/getTrimmedChildAsset',
    deleteCliplist: 'asset/deleteAssetInClipList',
    assetGenerateRenditions: 'asset/generateRenditions',
    assetPopularSearchList: 'asset/getPopularSearchList',
    assetDownloadCount: 'asset/getDownloadCount',
    assetsCountByType: 'asset/getAssetCountByType',
    assetsCount: 'asset/getAssetsCounts',
    assetDownload: 'asset/download',
    assetsList: 'asset/getAssetsList',
    assetMultiDownload: 'asset/getMultiAssetDownload',
    trimVideo: 'asset/trimVideo',
    downloadTranscript: 'asset/downloadTranscript',
    updateFaceFinderTags: 'asset/updateFaceFinderTags',
    faceFinderSearch: 'asset/facefinderSearch',
    assetTagList: 'asset/getTagList',
    assetUploadTags: 'asset/uploadTags',
    assetUpadateTagName: 'asset/updateTagName',
    getAssetsByTag: 'asset/getAssetsByTag',
    saveShareLink: 'asset/saveShareLink',
    updateAssetStatus: 'asset/updateAssetStatus',
    reTagAsset: 'asset/reTagToAsset',
    saveTrimmedAsset: 'asset/saveTrimmedAsset',
    copyMoveAsset: 'asset/copyOrMoveAssetToAnotherFolder',
    uploadBulkTags: 'asset/uploadBulkTags',
    clearStorage: 'asset/clearStorage',
    saveUploads: 'asset/saveUploadsAsync',
    getReadPresignedUrl: 'asset/getReadPresignedUrl',
  },
  transcript: {
    getVideoTranscription: 'transcript/getvideotranscription',
    getTranscriptStatus: 'transcript/transcriptStatus',
    prioritizeTranscription: 'transcript/prioritizeTranscription',
    downloadTranscription: 'transcript/downloadtranscript',
  },
  folder: {
    folderList: 'folder/getMediaValidatorFoldersCount',
    folderDetailsById: 'folder/getUserFolderDetailByFolderId',
    folderInfo: 'folder/getfolderinfo/',
    folderCopyMove: 'folder/copyOrMoveFolder',
    folderDownload: 'folder/getFolderDownload',
    untaggedAssetsByFolder: 'folder/getUntaggedAssetsByFolder',
    folderDelete: 'folder/deleteFolder',
    folderCreate: 'folder/createFolder',
    folderInfoPatterByFolderId: 'folder/getFolderInfoPattern2ByFolderId/',
    folderExist: 'folder/isFolderExistInDam',
    getParentFolderAccess: 'folder/getParentFolderAccess',
    importFolderFromS3: 'folder/importFolderFromS3',
    renameFolder: 'folder/renameFolder',
    backupFolder: 'folder/folderBackup',
    uploadZipFolder: 'folder/uploadzipFolder',
  },
  collections: {
    createCollection: 'collections/createCollection',
    fetchCollection: 'collections/fetchUserCollectionAssetIds',
    deleteCollectionAsset: 'collections/deleteAssetsFromSingleCollection/',
    deleteCollection: 'collections/deleteCollectionapi',
    downloadCollection: 'collections/downloadCollectionapi',
    showCollectionList: 'collections/showUserSelectedCollection',
    collectionReassignAsset: 'collections/reassignAssetsToACollection',
  },
  categories: {
    categoryList: 'categories/categoryListing',
    downloadCategory: 'categories/downloadCategoryapi',
    fetchCategory: 'categories/fetchUserCategoryAssetIds',
    getCategory: 'categories/getCategories',
  },
  reverseSearch: {
    imageReverseList: 'reverseSearch/getSimilarImages',
    uploadRevImages: 'reverseSearch/uploadRevImages',
    imageRevStatus: 'reverseSearch/getStatus',
    deleteJob: 'reverseSearch/deleteJobs',
  },
  client: {
    checkEmailExistForSameClient: 'client/checkEmailExistForSameClient',
    clientListAsPerAuth: 'login/getClientListAsPerAuth',
    getFeatureList: 'client/getFeatureList',
    getAuthList: 'client/getAuthList',
    edit: 'client/edit',
    onboard: 'client/onboard',
    getClientData: 'client/getClientData',
    checkEmailExist: 'client/checkEmailExist',
    switchToAnotherClient: 'switchToAnotherClient',
  },
  peopleManagement: {
    renameCluster: 'peoplemanagement/renameCluster',
    getAssetDetailByClusterId: 'peoplemanagement/getAssetDetailByClusterId',
    getAllCluster: 'peoplemanagement/getAllClusters',
    mergeCluster: 'peoplemanagement/mergeClusters',
    hideUnhideClusteres: 'peoplemanagement/hideUnhideClusters',
  },
  refreshToken: 'refreshToken',
  logOut: 'logout',
  logs: 'logs',
};

export const ASSET_TYPE_CONFIG = {
  NORMAL_UPLOAD: '0',
  RIS: '1',
  FF: '2',
};

export const DOMAIN_LIST: { [key: string]: string } = {
  ddc: 'https://ddc-dev.matrix.jio',
  nmacc: 'https://nmacc-dev.matrix.jio',
  viacomm: 'https://viacomm-dev.matrix.jio',
  tira: 'https://tira-dev.matrix.jio',
};
