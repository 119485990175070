import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  switchMap,
  timer,
} from 'rxjs';
import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { API_KEYPOINT } from '../constants';
import { environment } from 'src/app/environment/environment';
export class ImageRevStatusData {
  processed: number;
  total: number;
}

export interface AssetsMasterFilter {
  assetTag: String;
}
@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private flagSubject = new BehaviorSubject<ImageRevStatusData>({
    processed: 0,
    total: 0,
  });
  flag$ = this.flagSubject.asObservable();
  imageRevPollingStatus: any;
  private apiSubscription: Subscription;
  private timerSubscription: Subscription;

  constructor(
    private restService: RestService,
    private authService: AuthService
  ) {}

  private assetsMasterFilter: AssetsMasterFilter;
  private isAdvFilterApplied: boolean = false;
  private isHeader: boolean = false;

  private dataSource = new BehaviorSubject<any>('null');
  currentData = this.dataSource.asObservable();

  private assets = new BehaviorSubject<any>('null');
  public currentAssets = this.assets.asObservable();

  private dataAssets = new BehaviorSubject<any>('null');
  currentDataAssets = this.dataAssets.asObservable();

  private folderDataCreation = new BehaviorSubject<any>({});
  currentfolderDataCreation = this.folderDataCreation.asObservable();

  //observable created to call the method from asset master component from custom context menu
  private changeSubject = new BehaviorSubject<any>('null');
  changeOccurred = this.changeSubject.asObservable();

  notifyChange(data: any) {
    this.changeSubject.next(data);
  }

  private changeFilter = new BehaviorSubject<any>('null');
  filterChanged = this.changeFilter.asObservable();

  filterChangedNoti(data: any) {
    this.changeFilter.next(data);
  }

  changeData(data: any) {
    this.dataSource.next(data);
  }

  //observable created for the use of custom-context menu
  custom_context_menu_currentAssetArray: any = [];

  private custom_context_menu_assets = new BehaviorSubject<any>('null');

  public custom_context_menu_currentAssets =
    this.custom_context_menu_assets.asObservable();

  changeValueMethod(val: boolean) {
    this.custom_context_menu_assets.next(val);
  }
  //method to get the current selected array of object for custom-context-menu
  getSelectedArray() {
    return this.custom_context_menu_currentAssetArray;
  }

  //observable created for listening to add to collection button click and its subsequent color change
  private addToCollectionSubject = new Subject<any>();
  setAddtoCollectionEvent(data: any) {
    this.addToCollectionSubject.next(data);
  }
  getAddtoCollectionEvent() {
    return this.addToCollectionSubject.asObservable();
  }

  getFlag() {
    return this.isAdvFilterApplied;
  }

  setFlag(value: boolean) {
    this.isAdvFilterApplied = value;
  }

  setHeader(value: boolean) {
    this.isHeader = value;
  }

  getHeader() {
    return this.isHeader;
  }

  //behavior subject for storing the map object send from the asset-master
  private selectedAssets = new BehaviorSubject<any>(new Map());
  selectedAssetsByUser = this.selectedAssets.asObservable();
  //function to set new map object for subscribers
  addAssetToSelectedAssets(newAsset: any) {
    this.selectedAssets.next(newAsset);
  }

  getUserDetails() {
    let userData = this.authService.getAuthData();
    return this.restService.get('user/userDetails/' + userData.userId);
  }

  startPolling(): Observable<any> {
    return timer(0, 60000).pipe(
      switchMap(() =>
        this.restService.get('reverseSearch/getStatus', '', '', false)
      )
    );
  }

  stopPolling(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  startPollingFlag(): void {
    this.apiSubscription = this.startPolling().subscribe((response: any) => {
      if (response.result) {
        this.updatePollingStatus(response.result);
      } else {
        this.stopPollingFlag();
      }
    });
  }

  updatePollingStatus(data: any[]) {
    const processedCount = data.filter((item: any) => item.status === 1);
    this.flagSubject.next({
      processed: processedCount.length,
      total: data.length,
    });
  }

  stopPollingFlag(): void {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.stopPolling();
  }

  clearFlag(): void {
    this.flagSubject.next({ processed: 0, total: 0 });
  }

  changeDataAssets(data: any) {
    this.dataAssets.next(data);
  }

  sendData(data: any) {
    this.assets.next(data);
  }

  getData(): Observable<any> {
    return this.assets.asObservable();
  }

  getAssetsFilterState(): AssetsMasterFilter {
    return this.assetsMasterFilter;
  }

  setAssetsFilterState(filters: AssetsMasterFilter): void {
    this.assetsMasterFilter = filters;
  }

  async generatePreSignedUrl(assetIds: any | any[], preSignedUrl: any = {}) {
    // Ensure assetIds is an array, even if a single ID is passed
    const assetIdArray = Array.isArray(assetIds) ? assetIds : [assetIds];

    // Filter out assetIds that already have a presigned URL
    const assetIdsToFetch = assetIdArray.filter(
      (assetId) => !preSignedUrl[assetId]
    );

    // If there are no assetIds to fetch, exit the function
    if (assetIdsToFetch.length === 0) {
      return preSignedUrl;
    }

    const params = {
      assetIds: assetIdsToFetch,
      urlType: 2,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(API_KEYPOINT.assets.getReadPresignedUrl, params, '', false)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              // Populate the preSignedUrl object with the fetched URLs
              data.result.forEach((item: any) => {
                preSignedUrl[item.assetId] = item.url;
              });
              resolve(preSignedUrl); // Resolve the promise after updating preSignedUrl
            } else {
              reject(preSignedUrl);
            }
          },
          error: (err: any) => {
            reject(preSignedUrl);
          },
        });
    });
  }
  
  serverLog(payload: {}){
    this.restService.post(API_KEYPOINT.logs,payload,'',false).subscribe();
  }

  changePassword(formdata: any) {
    return this.restService.post(
      `login/changePassword`,
      formdata,
      ''
    );
  }

  changeFolderCreation(data: any) {
    this.folderDataCreation.next(data);
  }

}
