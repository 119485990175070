import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { AppDataService, AppService, LocalStorageService} from 'src/app/core/services';
import { FormatS3UrlPipe } from 'src/app/shared/pipes';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss'],
})
export class AssetPreviewComponent {
  @Input() tagArray: any = [];
  @Input() assetsList: any;
  @Input() currentItem: any;
  @Input() preSignedUrl: any;
  @Output() closeSliderModal = new EventEmitter<any>();
  @Output() onActionPerformed = new EventEmitter();
  assetImageList: any = [];
  assetImageSelected: any = [];
  slideConfig: any;
  formatS3UrlPipe = new FormatS3UrlPipe();
  flattenedItem: any;
  assetPreviewAssetList: any = [];
  selectedItem: any;
  commonUtils = new CommonUtils();
  private assetsSubject = new BehaviorSubject<string>('');
  asset$ = this.assetsSubject.asObservable();

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @HostListener('window:keydown', ['$event'])
  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    const e = <KeyboardEvent>event;
    const charCode = e.key;
    if (this.slickModal.initialized) {
      if (charCode === 'ArrowRight') {
        this.slickModal.slickNext();
      } else if (charCode === 'ArrowLeft') {
        this.slickModal.slickPrev();
      }
    }
  }

  constructor(
    public appService: AppService,
    protected cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    private localStorageService: LocalStorageService
  ) {
    this.slideConfig = { slidesToShow: 1, slidesToScroll: 1, infinite: true };
  }


  async ngOnInit(){
    this.selectedItem = {};
    if (this.currentItem._source) {
      this.selectedItem = {
        ...this.currentItem._source,
        _index: this.currentItem._index,
        _id: this.currentItem._id,
        _score: this.currentItem._score,
        sort: this.currentItem.sort,
        isSelected: this.currentItem.isSelected,
      };
    } else {
      this.selectedItem = this.currentItem;
    }
    // loop to remove source
    for (const item of this.assetsList) {
      if (item._source) {
        this.flattenedItem = {
          ...item._source,
          _index: item._index,
          _id: item._id,
          _score: item._score,
          sort: item.sort,
          isSelected: item.isSelected,
        };
        this.assetPreviewAssetList.push(this.flattenedItem);
      } else {
        this.assetPreviewAssetList.push(item);
      }
    }

    // to key in _id format for redirection
    if (!this.assetPreviewAssetList._id) {
      for (const element of this.assetPreviewAssetList) {
        if (element['assetId']) {
          element['_id'] = element['assetId'];
          //delete element['assetId'];
        }
        if (element['id']) {
          element['_id'] = element['id'];
          //delete element['id'];
        }
      }
    }
    if (!this.selectedItem._id) {
      if (this.selectedItem['assetId']) {
        this.selectedItem['_id'] = this.selectedItem['assetId'];
        delete this.selectedItem['assetId'];
      }
      if (this.selectedItem['id']) {
        this.selectedItem['_id'] = this.selectedItem['id'];
        delete this.selectedItem['id'];
      }
    }
    this.fetchInitialImageUrl([this.currentItem._id]);
    this.assetImageList = [];
    this.assetImageSelected = [];
    let indexToSplit = this.assetPreviewAssetList.findIndex(
      (x: any) => x._id == this.selectedItem._id
    );
    let first = this.assetPreviewAssetList.slice(0, indexToSplit);
    let second = this.assetPreviewAssetList.slice(indexToSplit + 1);
    this.assetImageList.push(this.selectedItem);
    this.assetImageList = this.assetImageList.concat(second.concat(first));

    this.assetImageList = this.assetImageList.filter(
      (selectedItem: any) =>
        selectedItem?.assetType == 0 ||
        selectedItem?.assetType == 1 ||
        selectedItem?.assetType == 3
    );
    this.slickModal?.initSlick();
  }

  closeSlider() {
    this.assetImageList = [];
    this.assetImageSelected = [];
    this.slickModal.unslick();
    this.onActionPerformed.emit({ type: 'close', data: undefined });
  }

  beforeChange($event: any) {
    this.assetsSubject.next('');
    let id = '#media' + $event.currentSlide;
    $event?.event?.target?.children[1]?.querySelector(id)?.pause();
  }

  async afterChange(event: any){
    const currentIndex = event.currentSlide;
    const asset = this.assetImageList[currentIndex];
    if (asset) {
      this.fetchInitialImageUrl(asset.id || asset._id);
    }
  }

  searchResult(id: any, assetPath: any) {
    // this.router.navigateByUrl("/main/" + Id + "/search-result");
    this.appService.searchResult(id, { assetPath: assetPath });
    //load all tags of perticular asset Id from ELK and Database
    this.localStorageService.addItem(
      '/main/' + id + '/search-result-manualTagArr',
      JSON.stringify(
        this.tagArray.filter((obj: any) => obj.assetId == id)[0]
          .longTagArrManual
      )
    );
    this.localStorageService.addItem(
      '/main/' + id + '/search-result-aisuggtags',
      JSON.stringify(
        this.tagArray.filter((obj: any) => obj.assetId == id)[0].longTagArrAi
      )
    );
  }

  redirectToForPreviewInNewTab(asset: any) {
    let url = this.formatS3UrlPipe.transform(
      this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
    );
    window.open(`${url}`, '_blank');
  }

  onActionChange(asset: any) {
    this.onActionPerformed.emit({
      type: 'download',
      data: asset,
    });
  }

  async fetchInitialImageUrl(assetId: any) {
    if(! this.preSignedUrl[assetId]){
      this.preSignedUrl = await this.appDataService.generatePreSignedUrl(assetId, this.preSignedUrl);
    } 
    this.assetsSubject.next(this.preSignedUrl[assetId]); 
  }

  ngOnDestroy() {}
}
