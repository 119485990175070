export const environment = {
  label: 'Development',
  production: false,
  infra: 'gcp',
  HOST_URL: 'https://jio-dam-dev.matrix.jio/',
  apiUrl: 'https://dam-dev.matrix.jio/',
  s3BaseUrl: 'https://nmarchive-images-uat.narendramodi.in/',
  // S3 BUCKET URL TO BE PASSED WHILE CROPPING/TRIMMING ASSETS
  s3Url: 'https://s3.ap-south-1.amazonaws.com/damuatbucket1/',
  // S3 BaseUrl for Pre-SignedUrl
  s3PresignedBaseUrl: 'https://damuatbucket1.s3.ap-south-1.amazonaws.com/',
  // S3Bucket Name
  BUCKET: 'jio-dam-dev-api-bucket',
  // GCP BaseUrl
  GCPBaseUrl: 'https://storage.googleapis.com/jio-dam-dev-api-bucket/',
  // Transfer_Accelerated_URL
  TRANSFER_ACCELERATED_URL:
    'https://damuatbucket1.s3-accelerate.amazonaws.com/',
  secretKey: '#@1234@#',
  // G-drive properties
  // TODO(developer): Set to client ID and API key from the Developer Console
  CLIENT_ID:
    '733534191142-dh76gjofhg7qdvuuicmv8fjm1i3dsecr.apps.googleusercontent.com',
  API_KEY: 'AIzaSyB4bj2pdL5Uk8dKfAQhYPr3ftK8NFtGcOw',
  // Discovery doc URL for APIs used by the quickstart
  DISCOVERY_DOC: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  SCOPES: 'https://www.googleapis.com/auth/drive.file',
  // to enable disable logs
  logsEnabled: false,
};
