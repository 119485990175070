<!-- dialog box for creating sub-folder -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isCreateModalShow" class="onboarduser-form" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '600px' }" [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form [formGroup]="creareSubfolderForm">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="font-regular mb-1 mt-1">Create New Folder</h3>
            <button type="button" (click)="closeCreateModal()" class="btn-close" aria-label="Close"></button>
          </div>

          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2 field-title">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" formControlName="folderName" name="folderName" required
                          />
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm.controls['folderName'].hasError(
                              'required'
                            ) &&
                            creareSubfolderForm.controls['folderName'].dirty
                          ">
                          Folder Name is required
                        </div>
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm
                              .get('folderName')
                              .hasError('minlength') || 
                              creareSubfolderForm
                              .get('folderName')
                              .hasError('maxlength')                          
                          ">
                          Folder Name should range from 2 to 100 characters
                        </div>
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm
                              .get('folderName')
                              .hasError('pattern')
                          ">
                          Only - and _ are allowed as special characters.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                (click)="createSubfolder(creareSubfolderForm)" [disabled]="
                  creareSubfolderForm.invalid ||
                  (creareSubfolderForm.controls['folderName'].length >= 1 &&
                    creareSubfolderForm.controls['folderName'].length < 2 &&
                    (creareSubfolderForm.controls['folderName'].dirty ||
                      creareSubfolderForm.controls['folderName'].touched ||
                      creareSubfolderForm.submitted)) ||
                  handleButtonClicked
                ">
                Create
              </button>
              <button type="button" (click)="closeCreateModal()" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- dialog box for creating folder with assignee -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isRootCreateFolderModalShow" class="onboarduser-form"
  [resizable]="false" [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '800px' }"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form #frmFolder="ngForm" role="form" novalidate>
      <!-- start new html-->
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="mb-1 font-regular">{{isEditMode==='add' ? 'Create New Folder' : 'Edit Folder'}}</h3>

            <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn-close"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="folder.folderName" name="folderName"
                          #folderName="ngModel" id="folderName" required placeholder="Enter Folder Name"
                          pattern="^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$" [disabled]="isEditMode === 'edit'" />
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="folder.folderName && 
                            ((folder.folderName.length >= 1 &&
                              folder.folderName.length < 2) ||
                              folder.folderName.length > 100) &&
                            (folderName.dirty ||
                              folderName.touched ||
                              frmFolder.submitted)
                          ">
                          Folder Name should range from 2 to 100 characters
                        </div>
                        <div class="invalid-feedback d-block font-medium mb-2 textarea-error-space-msg w-75"
                          *ngIf="folderName.errors?.['pattern']">
                          Only - and _ are allowed as special characters.
                        </div>

                        <div *ngIf="folder.folderName && 
                            folderName.errors &&
                            (folderName.dirty ||
                              folderName.touched ||
                              frmFolder.submitted)
                          " class="invalid-feedback d-block font-medium mb-2">
                          <div *ngIf="folderName.errors?.['required']">
                            Folder Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5"></div>
                  <div class="col-12">
                    <ng-container *ngIf="folder.folderAccess.length > 1">
                      <label class="mb-2 col-lg-5">Assign to<span class="text-danger">*</span></label>
                      <label class="mb-2 col-lg-5 ps-3">Access Type<span class="text-danger">*</span></label>
                    </ng-container>
                    <div class="row" *ngFor="let row of folder.folderAccess; let i = index">
                      <!-- <div class="row" [hidden]="type==='add' && row.roleId===1"> -->
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          <span class="placeholder-text font-regular" *ngIf="!row.assignedTo">Select</span>
                          <select [hidden]="row.roleId === 1 || (isEditMode!=='edit' && row.roleId === 2)"
                            [disabled]="isEditMode==='edit'?((userDetails.roleId == 6 && (row.roleId == 6) )):(row.assignedTo==userDetails.userId)"
                            class="form-select form-control custom-select" [(ngModel)]="row.assignedTo"
                            #assignedTo="ngModel" name="assignedTo_{{i}}" (click)="changeUser($event, i)" required>
                            <option value="Select" disabled>Select</option>
                            <option *ngFor="let item of userList" [ngValue]="item.id" [disabled]="item.isDisabled">
                              {{item.firstName+' '+item.lastName}} [{{item.email}}]</option>
                          </select>
                          <div *ngIf="frmFolder.submitted" class="invalid-feedback d-block font-medium mb-2">
                            <div *ngIf="assignedTo.errors?.['required']">
                              Assign to is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          <span class="placeholder-text font-regular" *ngIf="!row.accessType">Select</span>
                          <select [hidden]="row.roleId === 1 || (isEditMode!=='edit' && row.roleId === 2)"
                            [disabled]="isEditMode==='edit'?((userDetails.roleId == 6 && row.roleId == 6 )):(row.assignedTo==userDetails.userId)"
                            class="form-select form-control custom-select"
                            class="form-select form-control custom-select" [(ngModel)]="row.accessType"
                            #accessType="ngModel" name="accessType_{{i}}" required>
                            <option value="Select" disabled>Select</option>
                            <option *ngFor="let item1 of accessTypeList" [ngValue]="item1.id">{{item1.name}}</option>
                          </select>
                          <div *ngIf="frmFolder.submitted" class="invalid-feedback d-block font-medium mb-2">
                            <div *ngIf="accessType.errors?.['required']">
                              Access Type is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div [ngClass]="i == 0 ? 'delete-row-icon' : '' "
                        class="col-md-1 d-flex justify-content-end align-items-center" *ngIf="folder.folderAccess.length>1 &&
                         !(isEditMode==='edit'?(this.userDetails.roleId == 6 && row.roleId == 6):
                         ( row.roleId === 1 ||
                         row.roleId === 2 ||
                         row.roleId === 6 ||
                         row.roleId === 4) && row.assignedTo===this.userDetails.userId)">
                        <a role="button" class="d-flex align-items-center ms-3 trash-row"
                          (click)="removeUserOnfolderAccessList(i)">
                          <img src="assets/images/icons/delete.svg" class="ms-1" alt="Delete">
                        </a>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="col-12">
                    <a (click)="addItem()" class="text-decoration-none fs-16 font-medium dam-primary-btn-color cursorpointer">
                      <img src="assets/images/icons/Add-user.svg" class="ms-1" alt="add new user" />Add New User
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                [disabled]="frmFolder.invalid || !frmFolder.dirty ||(((folder.folderName.length >= 1 && folder.folderName.length < 2) || folder.folderName.length > 100) && (folderName.dirty || folderName.touched || frmFolder.submitted))"
                (click)="submitFormDataToCreateFolder(frmFolder)">
                {{isEditMode=='add'?'Save':'Update'}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end new html-->
    </form>
  </div>
</p-dialog>