import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/app/environment/environment';
import { LoaderService } from './loader.service';

@Injectable()
export class RestService {
  private activeRequests = 0;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private loadingService: LoaderService
  ) {}

  private createHeaders(authRequired: boolean, options: any = {}): HttpHeaders {
    const contentType = options.contentType || 'application/json';

    let headers = new HttpHeaders();
    headers.append('Content-Type', contentType);
    if (authRequired) {
      const token = this.authService.getAuthData()?.token;
      
      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }

    return headers;
  }

  private incrementActiveRequests(): void {
    this.activeRequests++;
    if (this.activeRequests === 1) {
      this.loadingService.setLoading(true);
    }
  }

  private decrementActiveRequests(): void {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loadingService.setLoading(false);
    }
  }

  /*
  ## How to Use the get Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the GET request will be sent. This is a required parameter.
  - `params`: The params to be sent in the request body. This is an optional parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public get(endpoint: string, params?: any, options: any = {}, loadingRequired: boolean = true, authRequired: boolean = true): Observable<any> {
    const headers = this.createHeaders(authRequired, options);
    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    let httpParams = new HttpParams();
    if (params) {
      for (const key of Object.keys(params)) {
        httpParams = httpParams.set(key, params[key]);
      }
    }

    return this.httpClient.get(`${environment.apiUrl}` + endpoint, {
      headers,
      params: httpParams,
      responseType: options.responseType || 'json',
      observe: options.observe || 'body',
      reportProgress: options.reportProgress || false
    }).pipe(
      finalize(() => {
        // Hide loader after response is received or error occurs
        if (loadingRequired) {
          this.decrementActiveRequests();
        }
      })
    );
  }


/*
  ## How to Use the post Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the post request will be sent. This is a required parameter.
  - `data`: The data to be sent in the request body. This is a required parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

public post(endpoint: string, data: any, options: any = {}, loadingRequired: boolean = true, authRequired: boolean = true): Observable<any> {
  const headers = this.createHeaders(authRequired, options);
  
  if (loadingRequired) {
    this.incrementActiveRequests();
  }

  return this.httpClient.post(`${environment.apiUrl}` + endpoint, data, {
    headers,
    responseType: options.responseType || 'json',
    observe: options.observe || 'body',
    reportProgress: options.reportProgress || false
  }).pipe(
    finalize(() => {
      if (loadingRequired) {
        this.decrementActiveRequests();
      }
    })
  );
}



  /*
  ## How to Use the put Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the PUT request will be sent. This is a required parameter.
  - `data`: (any) The data to be sent in the request body. This is a required parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public put(endpoint: string, data: any, options: any = {}, loadingRequired:boolean = true, authRequired: boolean = true): Observable<any> {
    const headers = this.createHeaders(authRequired, options);
    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }

    return this.httpClient.put(`${environment.apiUrl}` + endpoint, data, {
      headers,
      responseType: options.responseType || 'json',
      observe: options.observe || 'body',
      reportProgress: options.reportProgress || false
    }).pipe(
      finalize(() => {
        // Hide loader after response is received or error occurs
        if (loadingRequired) {
          this.decrementActiveRequests();
        }
      })
    );
  }

  /*
  ## How to Use the delete1 Function

  Parameters:
  - `endpoint`: (string) The API endpoint to which the DELETE request will be sent. This is a required parameter.
  - `data`: (any) The data to be sent in the request body. This is an optional parameter.
  - `options`: (object) Additional options to customize the request. This is an optional parameter and may include:
    - `responseType`: The type of response expected (e.g., 'json', 'text', 'blob'). Default is 'json'.
    - `observe`: Specifies which part of the HTTP response to return (e.g., 'body', 'events', 'response'). Default is 'body'.
    - `reportProgress`: A boolean indicating whether to report the progress of the request. Default is false.
    - `contentType`: The content type of the request (e.g., 'application/json'). Default is 'application/json'.
    - `loadingRequired`: (boolean) A flag indicating whether the request should show loader. Default is true. Set to false if loader is not required.
  - `authRequired`: (boolean) A flag indicating whether the request should include an authorization token in the headers. Default is true. Set to false if the bearer token is not required.
*/

  public delete(endpoint: string, data?: any, options: any = {}, loadingRequired: boolean = true , authRequired: boolean = true): Observable<any> {
    const headers = this.createHeaders(authRequired, options);

    // Show loader if required
    if (loadingRequired) {
      this.incrementActiveRequests();
    }
  
    const requestOptions: any = {
      headers,
      responseType: options.responseType || 'json',
      observe: options.observe || 'body',
      reportProgress: options.reportProgress || false
    };
  
    // Only include the body property if data is provided
    if (data) {
      requestOptions.body = data;
    }
  
    return this.httpClient.delete(`${environment.apiUrl}${endpoint}`, requestOptions).pipe(
      finalize(() => {
        // Hide loader after response is received or error occurs
        if (loadingRequired) {
          this.decrementActiveRequests();
        }
      })
    );
  }
  
}
