import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  $headerSubject: Subject<any> = new Subject();
  headerData: any;

  // private isShowSidenav = new BehaviorSubject<any>('null'); 
  // sideNavData = this.isShowSidenav.asObservable();

  constructor() {}

  emitHeaderSubject(data: any) {
    this.headerData = data;
    this.$headerSubject.next(data);
  }

  getHeaderData() {
    return this.headerData;
  }

  // emitSidenavSubject(data: any) {
  //   this.isShowSidenav.next(data);
  // }

  
}
