<div class="table-component">
  <div class="table-content min-h-auto">
    <table class="table">
      <thead>
        <tr>
          <th class="th-sm" scope="col" *ngFor="let column of columns" [ngStyle]="{ width: column.width }">
            <div class="{{column.thClass}}">
              {{ column.label }}
              <div class="column-sorting ms-3" *ngIf="column.sortKey"
                [ngClass]="{'desc': (reverse && currenSortBy === column.sortKey || (order === 'DESC' && sort === column.sortKey)),
                'asc': (!reverse && currenSortBy === column.sortKey || (order === 'ASC' && sort === column.sortKey))}">
                <span (click)='setOrder(column)'><i class="bi bi-caret-up-fill"></i></span>
                <span (click)='setOrder(column)'><i class="bi bi-caret-down-fill"></i></span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <ng-container *ngIf="dataSource && dataSource.length && dataSource.length > 0;else dataNotFound">
        <tbody class="font-medium">
          <tr *ngFor="let data of dataSource; let i = index">
            <td class="name-space {{column.tdClass}}" *ngFor="let column of columns"
              [ngClass]="{'read-more': column.readMore}">
              <ng-container *ngIf="!column.template">
                <ng-container *ngIf="!column.type">
                  <ng-container *ngIf="column.readMore; else normalText">
                    <ng-container *ngIf="!data.isReadMore; else completeText">
                      {{data[column.key]|| '-' | slice:0:50 }}
                      <button type="button" *ngIf="data[column.key].length > 50"
                        class="btn btn-sm text-primary p-0 h-auto font-bold border-0" (click)="readMore(i)">...read more
                      </button>
                    </ng-container>
                    <ng-template #completeText>
                      {{data[column.key]|| '-'}}
                      <button type="button" class="btn btn-sm text-primary p-0 h-auto font-bold border-0"
                        (click)="readMore(i)">read less
                      </button>
                    </ng-template>
                  </ng-container>
                  <ng-template #normalText>
                    <div [ngClass]="{ 'description-ellipse': column.ellipse }"
                      [title]="column.ellipse ? data[column.key] : ''">
                      {{ data[column.key] }}
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.type == 'link'">
                  <ng-container *ngIf="column.linkConfig; else linkWithoutRoute">
                    <a class="view-link cursorpointer"
                      [routerLink]="[column.linkConfig.routerLink,data[column.linkConfig.pathParamKey]]">
                      {{ data[column.key] }}
                    </a>
                  </ng-container>
                  <ng-template #linkWithoutRoute>
                    <div [ngClass]="{ 'description-ellipse': column.ellipse }"
                      [title]="column.ellipse ? data[column.key] : ''">
                      {{ data[column.key] }}
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.type == 'serial'">
                  {{reverse && currenSortBy === 'serialNumber' ? dataSource.length+pagination.limit *(pagination.pageno-1)-i : pagination.limit *(pagination.pageno-1)+i+1}}
                </ng-container>
                <ng-container *ngIf="column.type == 'date'">
                  {{
                    data[column.key] | date : column.format || "dd-MM-yy HH:mm"
                  }}
                </ng-container>
                <ng-container *ngIf="column.type == 'amount'">
                  {{
                    data[column.key]
                      | currency
                        : "&#8377; "
                        : "symbol"
                        : column.format || "1.0-0"
                  }}
                </ng-container>
                <div class="status" *ngIf="column.type == 'status'">
                  <span [ngClass]="data[column.key]">{{
                    data[column.key]
                  }}</span>
                </div>
              </ng-container>

              <ng-container *ngIf="column.template" [ngTemplateOutlet]="column.template"
                [ngTemplateOutletContext]="{ data: data, field: column.key }">
              </ng-container>
            </td>
          </tr>
        </tbody>
      </ng-container>
      <ng-template #dataNotFound>
        <tbody>
          <tr>
            <td class="no-data" [attr.colspan]="columns.length">
              <h3 class="text-center">No Records Found</h3>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div class="custom-pagination pt-1 pb-3" *ngIf="pagination && dataSource && dataSource.length>=0">
    <pagination [itemsPerPage]="pagination.limit" [totalItems]="pagination.totalResults"
      (pageChanged)="onPageChange($event)" [(ngModel)]="pagination.pageno" name="currentPage" [maxSize]="5"
      [boundaryLinks]="true" [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
      [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate" (contextmenu)="disableRightClick($event)">
    </pagination>
    <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angle-left-solid.svg" alt="">
    </ng-template>
    <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angle-right-solid.svg" alt="">
    </ng-template>
    <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angles-right-solid.svg" alt="">
    </ng-template>
    <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
      <img src="assets/images/icons/angles-left-solid.svg" alt="">
    </ng-template>
  </div>
</div>
