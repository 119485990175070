import {
  ElementRef,
  Component,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/core/services/app.service';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from 'src/app/common/utils/folderMgmtUtill';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { GdriveShare } from 'src/app/common/gdriveshare/gdriveshare';
import { environment } from '../../../environment/environment';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PermissionsEngine } from '../../../common/permission/permission';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormatS3UrlPipe } from '../../pipes';
import { AppDataService, EventData, EventEmitterService, RestService } from 'src/app/core/services';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { API_KEYPOINT, APP_EVENTS, APP_ROUTE } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';

interface ITagMore {
  shortTagArrManual: Array<String>;
  longTagArrManual: Array<String>;
  showMoreManualTag: Boolean;
}

@Component({
  selector: 'app-custom-context-menu',
  templateUrl: './custom-context-menu.component.html',
  styleUrls: ['./custom-context-menu.component.scss'],
})
export class CustomContextMenuComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick:false,
    children: [],
  };
  // Variables for integrate parent and child methods and data
  @Output() triggerMethod = new EventEmitter();
  @Input() isAssetView: Boolean;
  @Input() isCollapse: any;
  @Input() parentId: any;
  @Input() folderDetails: any;
  @Input() objectForSourceTrack: any;
  @Input() childFolderId: any;
  @Input() modalType: any;
  @Input() contextMenuSelection: any;
  @Input() displayCoreAssetsFolderStructure = false;
  @Output() menuEmitter = new EventEmitter();
  @ViewChild('toggleFolderShareButton') toggleFolderShareButton: ElementRef;
  @ViewChild('toggleMultiShareButton') toggleMultiShareButton: ElementRef;

  // End

  assetArr: any = [];
  selectedItems: any = [];
  isUploadModalClose: boolean = false;
  showAllAssetsActionButtons: boolean = false;
  allSelectedAssetsAreReady: any;
  myparams: any = {
    limit: 500,
    pageno: 1,
  };
  userId: any;
  hasUploadPerm: Boolean = false;
  bulkTagAccess: Boolean = false;
  fileDetails: any;
  assetUploadPath: any;
  folderPath: any;
  customMenu: any;
  uploadTagsForm: any;
  multiSharePopUpIsOpen = false;
  assetsIdArray: any = [];
  eventsList: any = [];
  contextmenu = false;
  submitted: boolean = false;
  IsmodelEventShow: boolean = false;
  tagArray: Array<ITagMore> = [];
  manualTagArray: Array<ITagMore> = [];
  totalText = 1000;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  eventId: any;
  urlStr: any;
  assetStatus: any;
  assetTag: any = [0, 1];
  isDisabled: boolean = false;
  isBulkAssetTag: boolean = false;
  assetNameArray: any = [];
  uploadBulkTagsForm: any;
  isActiveTab: any = 2;
  eventName: string;
  folderSharePopUpIsOpen: boolean = false;
  assetDescription: any;
  assetTitle: any;
  shortTagArrManual: any = [];
  longTagArrManual: any = [];
  formatS3UrlPipe = new FormatS3UrlPipe();
  isuploadBulkTagsForm: boolean = false;
  commonUtils = new CommonUtils();
  preSignedUrl: any = {};
  subscriptions: Subscription [] = [];
  private eventSubscription: Subscription | undefined;
  constructor(
    public permissionsEngine: PermissionsEngine,
    public appService: AppService,
    public authService: AuthService,
    private messageService: MessageService,
    private folderMgmt: FolderMgmtUtill,
    private gdriveShare: GdriveShare,
    private sanitise: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private loader: LoaderService,
    private restService: RestService,
    private router: Router,
    private appDataService: AppDataService,
    private eventEmitterService: EventEmitterService
  ) {
    this.uploadTagsForm = new FormGroup({
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl('', [Validators.required]),
      eventId: new FormControl('', [Validators.required]),
      folderId: new FormControl(''),
    });

    this.uploadBulkTagsForm = new FormGroup({
      assetTag: new FormControl('0'),
      assetArr: new FormControl(),
      tagArray: new FormControl([]),
      eventId: new FormControl(''),
      folderId: new FormControl(''),
      assetDescription: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
      assetTitle: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
    });
    this.appDataService.addAssetToSelectedAssets(new Map());
    //used this service to select store all selected assets in an array ie "allSelectedAssetsAreReady"
    this.subscriptions.push(this.appDataService.selectedAssetsByUser.subscribe(
      (data) => {
        if (data.size > 0) {
          this.showAllAssetsActionButtons = true;
          this.allSelectedAssetsAreReady = [...data.values()];
          this.selectedItems = this.allSelectedAssetsAreReady;
          this.assetTag = this.selectedItems[0].assetTag;
        } else if (data.size === 0) {
          this.showAllAssetsActionButtons = false;
          this.allSelectedAssetsAreReady = [];
          this.selectedItems = this.allSelectedAssetsAreReady;
        } else {
          this.showAllAssetsActionButtons = false;
        }
      }
    ));
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
    // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
    this.renderer.listen('window', 'contextmenu', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
  }
  public appRouteUrl = APP_ROUTE;

  ngOnChanges() {
    this.customMenu = this.contextMenuSelection;
    this.menuSelection(this.customMenu);
    this.assetUploadPath = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
    this.hasUploadPerm =
      this.permissionsEngine.folderAccessOnHasPermission(
        this.objectForSourceTrack.folderId,
        this.permissionsEngine.folderAccessType[1]
      ) &&
      this.isCollapse &&
      this.selectedItems.length < 1;
    this.bulkTagAccess = this.permissionsEngine.folderAccessOnHasPermission(
      this.objectForSourceTrack.folderId,
      this.permissionsEngine.folderAccessType[2]
    );
  }

  async ngOnInit() {
    this.subscribeToEvent();
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Re-subscribe to the event if necessary when navigation ends
        if (!this.eventSubscription || this.eventSubscription.closed) {
          this.subscribeToEvent();
        }
      }
    }));
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe((event: EventData) => {
      if (event.type === APP_EVENTS.BROWSER_BACK) {
        if (this.isUploadModalClose) {
          this.isUploadModalClose = false;
          this.emptyFileList();
          this.router.navigate([this.appRouteUrl.assets]);
        }
      }
    });
    this.subscriptions.push(this.eventSubscription);
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  menuSelection(menu: any) {
    switch (menu) {
      case 'download':
        this.downloadMultipleAssets();
        break;
      case 'bulkTagAsset':
        this.bulkTagAsset();
        break;
      case 'share':
        this.menuEmitter.emit('share');
        break;
      case 'clearAll':
        this.clearAllAssests();
        break;
      default:
        break;
    }
  }
  //=========== close event modal ==========//
  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.uploadBulkTagsForm.patchValue({
        eventId: event.id,
      });
      this.getEvents();
      this.uploadBulkTagsForm.markAsDirty();
    }
    this.IsmodelEventShow = false;
  }

  downloadMultipleAssets() {
    let FolderPath: any = {};
    let assetId: any = [];
    if (this.selectedItems && this.selectedItems.length) {
      for (let item of this.selectedItems) {
        this.assetArr.push(
          this.appService.s3BaseUrl + item.assetPath + '/' + item.assetName
        );
        assetId.push(item.id);
      }
      let path = this.selectedItems[0].assetPath;
      FolderPath.Ids = path.split('/');
      FolderPath.Ids = this.commonUtils.removeClinetID(FolderPath.Ids);
      FolderPath.Url = this.shareFolderUrl();
    }

    const params = {
      assetArr: this.assetArr,
      OriginPath: FolderPath,
    }

    const options = {
      responseType: 'blob'
    }

    this.restService
      .post(API_KEYPOINT.assets.assetMultiDownload, params, options, false)
      .subscribe({
        next: (res: any) => {
          this.selectedItems = [];
          let params = { fileDetails: this.objectForSourceTrack };
          this.triggerMethod.emit(params);
          this.folderMgmt.setOpenQueueTrigger(true);
          this.messageService.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'Zip will be downloaded and will be sent to you via email.',
          });
          this.loader.setLoading(false);
        },
        error: (error: any) => {
          this.selectedItems = [];
          this.appDataService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    this.logs(assetId, "ASSETDOWNLOAD");
    this.assetArr = [];
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.selectedItems = [];
    let tempParentFolder: IFolderDetails = this.objectForSourceTrack;
    while (tempParentFolder.parentId != 0) {
      tempParentFolder = this.folderMgmt.getTrackRecord(
        tempParentFolder.parentId
      );
      tempParentFolder.isCollapse = true;
    }
    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
  }
  //method to bulk tag assets
  bulkTagAsset() {
    this.isActiveTab = 2;
    this.isBulkAssetTag = true;
    this.assetNameArray = [];
    this.assetsIdArray = [];
    //clear ManualTagArray
    this.manualTagArray = [];
    if (this.selectedItems && this.selectedItems.length) {
      this.selectedItems.forEach((el: any) => {
        this.assetsIdArray.push(el.id);
        this.assetNameArray.push(el.assetName);
        //populate ManualTagArray
        this.manualTagArray.push({
          shortTagArrManual:
            el.manualTags != undefined
              ? el.manualTags.length > 2
                ? [...el.manualTags].sort().splice(0, 2)
                : [...el.manualTags].sort()
              : [],
          longTagArrManual:
            el.manualTags != undefined ? [...el.manualTags].sort() : [],
          showMoreManualTag: false,
        }); //manualTagArray.push()
      });
    }
    this.getEvents();
  }
  getEvents() {
    this.restService.get(API_KEYPOINT.event.getEvents).subscribe({
      next: (data: any) => {
        if (data.code == 200) {
          this.eventsList = data.result;
        }
      },
      error: (error: any) => {
        this.selectedItems = [];
        this.appDataService.addAssetToSelectedAssets(new Map());
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
  }

  eventSelection(event: any) {
    this.eventName = event.eventName;
  }

  //This function is used to check valid tags while tagging any asset
  checkInput(event: any) {
    if (
      event.value.replace(/[^a-z0-9\-\s]/gi, '').length < event.value.length
    ) {
      this.uploadTagsForm.value.tagArray.pop(); // remove last entry from values
    }
  }
  //==========================Open Create Event Modal while tagging asset================================//
  openEventModal() {
    this.IsmodelEventShow = true;
  }
  //========================Close POPUP For TaggingAsset=========================//
  goBack() {
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.isBulkAssetTag = false;
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
  }

  //====================RESET BULK TAG FORM ====================================//
  resetBulkTagForm() {
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset({
      assetTag: 0, // Set the assetTag control to 0 (Pending) by default
    });
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.assetTag = 0;
  }

  // Method to clean the component — for example, to cancel background tasks.
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  openAssetsModal(modalType: any) {
    this.modalType = modalType;
    this.isUploadModalClose = true;
  }
  closeModalEvent(event: any) {
    this.isUploadModalClose = false;
    if (event && this.modalType === 'zip') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        zipUpload: 'zipUpload',
        folderId: event.folderId,
      };
      this.triggerMethod.emit(params);
    } else if (event && this.modalType === 'image') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        folderId: event.folderId,
        assetUploadCount: event.assetUploadCount,
      };
      this.triggerMethod.emit(params);
    } else {
      this.emptyFileList();
    }
  }

  //====================FUNCTION TO SAVE SHARE LINK HISTORY =============================
  saveShareLink() {
    let assetArr: any = [];
    let assetId: any = [];
    let path = this.selectedItems[0].assetPath;
    let folderPath = path.split('/');
    folderPath = this.commonUtils.removeClinetID(folderPath);
    for (let item of this.selectedItems) {
      let assetArrObj = {
        assetName: item.assetName,
        assetId: item.id,
        folderId:Number(folderPath[folderPath.length - 1])
      }
      assetArr.push(assetArrObj);
      assetId.push(item.id);
    }
    const params = {
      assetArr: assetArr,
      OriginPath: { Ids: folderPath},
    };
    //call saveShareLink API to save the history of shared assets
    this.restService.post(API_KEYPOINT.assets.saveShareLink, params).subscribe({
      next: (data: any) => {
        return;
        //this.downloadHistoryList = data.result;
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
    this.logs(assetId, "ASSETSHARE");
  }
  ShareMultipleSelectedAssetsOnMail() {
    let selectedAssetUrlsArray: any = [];
    this.selectedItems.forEach((asset: any) => {
      selectedAssetUrlsArray.push(this.preSignedUrl[asset.id]);
    });
    // Convert the array of URLs to a string with line breaks
    const selectedAssetUrlsString = selectedAssetUrlsArray.join('\n');
    // Encode the URL string
    const encodedUrls = encodeURIComponent(selectedAssetUrlsString);
    // Generate the mailto link
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      'Share'
    )}&body=${encodedUrls}`;

    // Open the mailto link in a new browser window or tab
    window.open(mailtoLink, '_blank');
  }
  getAssertUrlsShareMultipleSelectedAssets() {
    if (this.selectedItems == undefined) return '';
    let selectedAssetUrls = '';
    this.selectedItems.forEach((asset: any) => {
      selectedAssetUrls =
        selectedAssetUrls + this.preSignedUrl[asset.id];
      selectedAssetUrls = selectedAssetUrls + '\n\n';
    });
    return selectedAssetUrls;
  } //getAssertUrlsShareMultipleSelectedAssets

  async ShareMultipleSelectedAssetsOnGdrive() {
    let sharedAssetsId: any = [];
    this.selectedItems.forEach((asset: any) => {
      sharedAssetsId.push(asset);
    });
    if (!this.gdriveShare.isUserLoggedIn()) {
      await this.gdriveShare.getUserLoggedIn(() => {
        sharedAssetsId.forEach((_source: any) => {
          this.shareAssetOnGdrive(_source);
        });
      });
    }
  } //ShareMultipleSelectedAssetsOnGdrive

  async shareAssetOnGdrive(_source: any) {
    await this.gdriveShare.handleAuthClick(
     this.preSignedUrl[_source.id],
      _source.assetName
    );
  } //shareAssetOnGdrive



  yearDayMonthHoursMinutes() {
    // Create a new Date object using the timestamp
    const timestamp = Date.now();
    const dateObj = new Date(timestamp);

    // Extract the individual date and time components
    const year = dateObj.getFullYear().toString().substr(-2);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    // Generate the desired timestamp format
    const formattedTimestamp = `${year}${day}${month}_${hours}${minutes}`;
    return formattedTimestamp;
  }

  // =========== Function to preview document =============
  cleanUrl(url: any) {
    let str = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + url.assetPath + '/' + url.assetName
    );
    this.urlStr = this.sanitise.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/gview?url=' + str
    );
    window.open(this.urlStr.changingThisBreaksApplicationSecurity, '_blank');
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.stopPropagation();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  clearAllAssests() {
    if (this.selectedItems && this.selectedItems.length) {
      for (let item of this.selectedItems) {
        if (item.isSelected == true) item.isSelected = false;
      }
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethod.emit(params);
    }
    this.multiSharePopUpIsOpen = false;
  }
  onFolderShare() {
    this.folderSharePopUpIsOpen = !this.folderSharePopUpIsOpen;
  }

  shareFolderUrl() {
    let editUrl = '';
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath.split('/');
      editUrl = this.commonUtils
        .removeClinetID(editUrl)
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      this.appRouteUrl.assets +
      '/' +
      '?folderPath=' +
      editUrl;
    return shareLink;
  }
  //=========== download folder call=========//
  onFolderDownload() {
    if (this.objectForSourceTrack) {
      if (this.objectForSourceTrack.folderCount >= 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'The folder you are trying to download has subfolders',
        });
      } else if (this.objectForSourceTrack.assetConts == 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'This folder is empty! Please add some assets before downloading it.',
        });
      } else {
        let id = this.objectForSourceTrack.folderId;
        let url = this.shareFolderUrl();
        const options = {
          responseType: 'blob'
        }
        const params = {
          folderId: id,
          folderURL: url,
        };
        this.restService.post(API_KEYPOINT.folder.folderDownload, params).subscribe({
          next: (res: any) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(res);
            a.href = objectUrl;
            a.download = 'attachment.zip';
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
      }
    }
  }

  SaveBulkTagInfo() {
    this.assetDescription = this.uploadBulkTagsForm.value.assetDescription;
    this.assetTitle = this.uploadBulkTagsForm.value.assetTitle;
    this.tagArray = this.uploadBulkTagsForm.value.tagArray;
    this.eventsList.filter((newel: any) => {
      if (newel.id === this.uploadBulkTagsForm.value.eventId) {
        this.event.eventName = newel.eventName;
        this.event.eventDescription = newel.description;
        this.event.eventLocation = newel.eventLocation;
        this.event.eventTime = newel.eventTime;
      }
      this.isuploadBulkTagsForm = false;
    });
    if (this.tagArray && this.tagArray.length) {
      this.shortTagArrManual = this.tagArray.slice(0, 10);
      this.longTagArrManual = this.tagArray.slice(10);
    }
    this.uploadBulkTag();
    this.uploadBulkTagsForm.get('tagArray').reset([]);
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsUntouched();
    this.uploadBulkTagsForm.markAsPristine();
    this.isBulkAssetTag = false;
  }
  // reset function for cancel button
  resetdata() {
    this.uploadBulkTagsForm.reset();
  }
  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.
  Checkmultipleseledcted() {
    if (this.selectedItems.length < 2) {
      if (this.multiSharePopUpIsOpen) {
        this.multiSharePopUpIsOpen = false;
      }
      return false;
    } else {
      return true;
    }
  }
  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.

  uploadBulkTag() {
    this.uploadBulkTagsForm.value.assetArr = this.assetsIdArray;
    //this.uploadTagsForm.value.assetStatus = (this.uploadTagsForm.value.assetStatus === null || this.uploadTagsForm.value.assetStatus === undefined || this.uploadTagsForm.value.assetStatus==='') ? 0 : this.uploadTagsForm.value.assetStatus;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
        this.uploadTagsForm.value.assetTag === undefined ||
        this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadBulkTagsForm.value.folderId = this.childFolderId;

    //set Default value for form attributes
    if (this.uploadBulkTagsForm.value.tagArray == null) {
      this.uploadBulkTagsForm.value.tagArray = [];
    }

    // Bug 183199: DAM: Admin: User is not able to perform Bulk Tag operation on assets from the top options.
    //set Default value for assetDescription,assetTitle
    if (this.uploadBulkTagsForm.value.assetDescription == null) {
      this.uploadBulkTagsForm.value.assetDescription = '';
    }
    if (this.uploadBulkTagsForm.value.assetTitle == null) {
      this.uploadBulkTagsForm.value.assetTitle = '';
    }
    if (this.uploadBulkTagsForm.value.assetTag == null) {
      this.uploadBulkTagsForm.value.assetTag = 0;
    }

    this.restService
      .post(API_KEYPOINT.assets.uploadBulkTags, this.uploadBulkTagsForm.value)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethod.emit(params);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
          }
        },
        error: (error: any) => {
          this.selectedItems = [];
          this.isBulkAssetTag = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  handleAdd(tagToAdd: any) {
    const tagValue = tagToAdd.value.toLowerCase();
    const tagArray = this.uploadBulkTagsForm.value.tagArray;

    for (let i = 0; i < tagArray.length - 1; i++) {
      if (tagArray[i].toLowerCase() === tagValue) {
        // Tag already exists, remove the last added tag
        this.uploadBulkTagsForm.value.tagArray.pop();
      }
    }
  }

  async checkingForPreSignedUrl() {
    if (this.selectedItems == undefined) return '';
    let selectAssetIds:any = [];
    this.selectedItems.forEach((asset: any) => {
      selectAssetIds.push(asset.id);
    })
    this.preSignedUrl = await this.appDataService.generatePreSignedUrl(selectAssetIds, this.preSignedUrl);
  }   

  logs(assetId: any, action: any){
    const logs = {
      data : {
        assetId : assetId
      },
      action : action
    }
    this.appDataService.serverLog(logs);
  }
}
