import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
  ElementRef,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { MessageService } from 'primeng/api';
import { GdriveShare } from '../../../common/gdriveshare/gdriveshare';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from '../../../common/utils/folderMgmtUtill';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShareService } from 'ngx-sharebuttons';
import { PermissionsEngine } from '../../../common/permission/permission';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormatS3UrlPipe } from '../../pipes';
import { Subscription, forkJoin } from 'rxjs';
import {
  API_KEYPOINT,
  APP_EVENTS,
  APP_ROUTE,
  PAGE_SIZES_OPTIONS,
  TAG_STATUS_TYPE_OPTIONS,
} from 'src/app/core/constants';
import { BtnDropdownDto } from '../btn-dropdown/btn-dropdown.component';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import {
  AppDataService,
  EventData,
  EventEmitterService,
  RestService,
} from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth.service';

interface ITagMore {
  assetId: Number;
  shortTagArrManual: Array<String>;
  longTagArrManual: Array<String>;
  shortTagArrAi: Array<String>;
  longTagArrAi: Array<String>;
  showMoreManualTag: Boolean;
  showMoreAiTag: Boolean;
  fileExt: Array<String>;
}

@Component({
  selector: 'app-assets-master',
  templateUrl: './assets-master.component.html',
  styleUrls: ['./assets-master.component.scss'],
})
export class AssetsMasterComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy
{
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick:false,
    children: [],
  };

  assetMapToStoreSelectedAssets = new Map();
  @Output() triggerMethodAssets = new EventEmitter();
  @Output() triggerMethod = new EventEmitter();
  @Input() parentId: any;
  @Input() childFolderId: any;
  @Input() assetContextMenuList: any;
  @Input() isAssetView: Boolean;
  @Input() isCollapse: any;
  @Input() folderDetails: any;
  @Input() objectForSourceTrack: any;
  @Input() breadCrumbArrayOfFolderItem: any;
  @Input() isFolderSearchParams: boolean;
  queryParams: any;
  croppedsrcUrl: any;
  pageSizeOptions = PAGE_SIZES_OPTIONS;
  tagStatusOptions = TAG_STATUS_TYPE_OPTIONS;
  // following is the keydown event on windows when press the keyboard keys
  // when user is come on the window and pressed the left and right arrow key slider is working
  selectedAssets: any = [];
  myparams: any = {
    limit: 50,
    pageno: 1,
  };
  index1: number = -1;
  index2: number = -1;
  selectAllCheck: boolean = false;
  assetDetails: any = [];
  totalRecords: any;
  currentPage = 1;
  openSharePopUpAsset: any;
  assetArray: any = [];
  assetArr: any = [];
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  aiArrayOfTag: Array<{
    id: String;
    tagName: String;
  }> = [];
  arrayOfReTag: Array<{
    id: String;
    tagname: String;
  }> = [];
  arrayOfReTagName: Array<String> = [];
  tagArray: Array<ITagMore> = [];
  multiSelect: boolean = false;
  assetsIdArray: any = [];
  isToolbarShown: boolean = false;
  isAssetTag: boolean = false;
  eventsList: any = [];
  submitted: boolean = false;
  uploadTagsForm: any;
  IsmodelEventShow: boolean = false;
  eventId: any;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  showSuccessMessage: any = false;
  totalText = 1000;
  userDetails: any;
  userId: any;
  trimImageFrom: any = '';
  imageUrl: any = '';
  imageExt: any = '';
  output?: any;
  isTrimImage: boolean = false;
  isTrimVideo: boolean = false;
  croppedFile: any = '';
  tagStatusArray = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Completed' },
  ];
  tagCategory: any = 0;
  assetStatus: any = [0, 1];
  assetTag: any = [0, 1];
  pageSize: any = 50;
  isPreview: boolean = false;
  assetTagPath: any;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ViewChild('contextMenuW') contextMenuW: ElementRef;
  assetsHeader: any = '';
  assetsFooter: any = '';
  assetsCopyType: any = 0;
  isCopyVisible: boolean = true;
  hasAccess: boolean = false;
  displayDialogBox: boolean = false;
  isDeleteModalShow: boolean = false;
  destinationFolderId: any = '';
  destinationLocation: any = '';
  sourceLocation: any = '';
  sourceFolderId: any = '';
  fileDetails: any;
  destinationfolderDetails: any;
  previousFolderInfoForRedirction: any;
  parentFolderList: any = [];
  newAssetContextMenuList: any = [];
  newAssetDetails: any = [];
  rangeSliderValue: any = 2;
  iscrossicon: boolean = false;
  multiSharePopUpIsOpen = false;
  isViewMode: boolean = false;
  inputTagAssetDefaultName: string = '';
  assetDescription: any;
  assetTitle: any;
  bulkAssetStatus: any = 0;
  shortTagArrManual: any = [];
  longTagArrManual: any = [];
  isBulkAssetTag: boolean = false;
  assetNameArray: any = [];
  uploadBulkTagsForm: any;
  isActiveTab: any = 2;
  eventName: string;
  manualTagArray: any = [];
  //variable to store previous collapsed folders
  previoulyPoppedFolderItem: any = null;
  reStoreSourceObject: any;
  imageNameForOnCancel: string = '';
  reStoreBreadCrum: any = [];
  isDestinationFolderDisabled: boolean = false;
  formatS3UrlPipe = new FormatS3UrlPipe();
  private assetsScrollEventHandler: EventListenerOrEventListenerObject;
  selectedFilter = {
    pageSize: {} as BtnDropdownDto,
    assetStatus: [{} as BtnDropdownDto],
  };
  private subscriptions: Subscription[] = [];
  slideritemdata: any;
  showAssetPreview: boolean = false;
  commonUtils = new CommonUtils();
  assetUploadPath: any;
  preSignedUrl: any = {};
  openImageCrop: boolean = false;
  private eventSubscription: Subscription | undefined;

  constructor(
    private eRef: ElementRef,
    public permissionsEngine: PermissionsEngine,
    public appService: AppService,
    public authService: AuthService,
    private messageService: MessageService,
    private gdriveShare: GdriveShare,
    private service: NgxPhotoEditorService,
    private cdRef: ChangeDetectorRef,
    private folderMgmt: FolderMgmtUtill,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shareButtons: ShareService,
    private loader: LoaderService,
    private eventEmitterService: EventEmitterService,
    private restService: RestService,
    private appDataService: AppDataService
  ) {
    this.shareButtons.addButton('functionButton', {
      type: 'functionButton',
      icon: ['fas', 'link'],
    });
    this.queryParams = {
      search: '',
      folderId: this.childFolderId,
      searchedBy: 1,
      assetType: [],
      sortBy: 'DESC',
      fromDate: '',
      toDate: '',
      limit: this.myparams.limit,
      pageno: this.myparams.pageno,
      tagCategory: this.tagCategory,
      assetStatus: this.assetStatus,
      assetTag: this.assetTag,
    };
    this.userDetails = this.authService.getAuthData();
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
    }
    /* Add Reactive Form for cropped image validation */
    this.trimImageFrom = new FormGroup({
      imageName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern(
          /^[\p{L}\p{Mark}\p{N}_-]+(?: [\p{L}\p{Mark}\p{N}_-]+)*$/u
        ),
      ]),
    });

    this.uploadTagsForm = new FormGroup({
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl('', [Validators.required]),
      eventId: new FormControl('', [Validators.required]),
      folderId: new FormControl(''),
    });
    this.uploadBulkTagsForm = new FormGroup({
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl([]),
      eventId: new FormControl(''),
      folderId: new FormControl(''),
      assetDescription: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
      assetTitle: new FormControl('', [
        this.commonUtils.assetInputValidator(this.totalText),
      ]),
    });

    this.reStoreSourceObject = { ...this.objectForSourceTrack };
    this.reStoreBreadCrum = this.breadCrumbArrayOfFolderItem;
  }

  ngAfterViewInit(): void {
    this.listenAssetScroll();
  }

  handleMouseLeave() {
    this.openSharePopUpAsset = '';
  }

  ngOnInit() {
    this.selectedFilter.assetStatus = [];
    this.selectedFilter.pageSize = PAGE_SIZES_OPTIONS[0];
    this.iscrossicon = false;

    this.subscriptions.push(
      this.appDataService.selectedAssetsByUser.subscribe((data: any) => {
        this.assetMapToStoreSelectedAssets = data;
        this.selectedAssets = Array.from(data.values());
      }),
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.rangeSlider) {
          this.rangeSliderValue = Number(params.rangeSlider);
        }
      }),
      this.appDataService.changeOccurred.subscribe((data) => {
        this.tagCategory = 0;
        this.assetTag = [0, 1];
        this.selectAllCheck = false;
        if (data?.folderId) {
          this.queryParams.folderId = data.folderId;
          this.queryParams.tagCategory = this.tagCategory;
          this.queryParams.assetStatus = this.assetStatus;
          this.queryParams.assetTag = this.assetTag;
          this.queryParams.limit = this.selectedFilter.pageSize.value;
          this.getAllAssetsList();
          this.iscrossicon = false;
        } else {
          this.queryParams.tagCategory = this.tagCategory;
          this.queryParams.assetStatus = this.assetStatus;
          this.queryParams.assetTag = this.assetTag;
          this.queryParams.limit = this.selectedFilter.pageSize.value;
          this.queryParams.folderId = data;
          this.getAllAssetsList();
        }
      }));
    this.tagStatusOptions.forEach((option: any) => {
      option.checked = true;
    });
    this.subscribeToEvent();
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Re-subscribe to the event if necessary when navigation ends
        if (!this.eventSubscription || this.eventSubscription.closed) {
          this.subscribeToEvent();
        }
      }
    }));
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe((event: EventData) => {
      if (event.type === APP_EVENTS.BROWSER_BACK) {
        if (this.showAssetPreview || this.isDeleteModalShow || this.displayDialogBox || this.openImageCrop || this.isTrimImage || this.isTrimVideo) {
          this.showAssetPreview = false;
          this.isDeleteModalShow = false;
          this.isTrimImage = false;
          this.isTrimVideo = false;
          if(this.displayDialogBox){
            this.emptyFileList();
          }
          if(this.openImageCrop){
            this.openImageCrop = false;
            this.closeCropImage();
          }
          this.router.navigate([APP_ROUTE.assets]);
        }
      }
    });
    this.subscriptions.push(this.eventSubscription);
  }

  listenAssetScroll() {
    let ele = document.getElementById('assetsMngScrollContainer');
    this.assetsScrollEventHandler = () => {
      this.contextmenu = false;
      if (ele) {
        ele.removeEventListener('scroll', this.assetsScrollEventHandler);
      }
      this.reAddScrollListener();
    };
    if (ele) {
      ele.addEventListener('scroll', this.assetsScrollEventHandler);
    }
  }

  reAddScrollListener(): void {
    let ele = document.getElementById('assetsMngScrollContainer');
    if (ele) {
      ele.addEventListener('scroll', this.assetsScrollEventHandler);
    }
  }

  onChangeRangeSlider(value: number) {
    this.rangeSliderValue = value;
  }

  ngOnChanges() {
    this.assetUploadPath = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
    this.assetTagPath = this.folderDetails?.source.substring(
      0,
      this.folderDetails?.source.length - 1
    );
    this.sourceFolderId = this.parentId;
    this.sourceLocation = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
  }

  // Method to clean the component — for example, to cancel background tasks.
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.displayDialogBox = false;
    this.closeCropImage();
  }

  // Method of lifecycle hook after all child components have been initialized and checked
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  // Function to delete an element from array of object if the item is deselected

  // Function to console.log selectedItems
  // Function to get list of all assets inside a folder

  getAllAssetsList() {
    this.queryParams.pageno = this.myparams.pageno;
    if (this.selectedFilter.assetStatus[0]?.value === 1) {
      this.queryParams.assetTag = [1];
    } else if (this.selectedFilter.assetStatus[0]?.value === 2) {
      this.queryParams.assetTag = [0];
    } else {
      this.queryParams.assetTag = [0, 1];
    }
    this.restService
      .post(API_KEYPOINT.assets.assetsList, this.queryParams)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            data.result.forEach((ele: any) => {
              ele.isSelected = this.selectAllCheck;
            });
            this.totalRecords = data.totalCount ? data.totalCount : 0;
            this.assetDetails = data.result;
            this.assetDetails.filter((newel: any) => {
              this.arrayOfReTag = newel.tags;
              this.aiArrayOfTag = newel.aiTags;
              newel['contextmenu'] = false;
              newel['manualTags'] = [];
              newel['aiTagsArray'] = [];
              if (this.arrayOfReTag && this.arrayOfReTag.length) {
                this.arrayOfReTag.forEach((obj) => {
                  newel['manualTags'].push(obj.tagname);
                  this.arrayOfReTagName.push(obj.tagname);
                });
              }
              if (this.aiArrayOfTag && this.aiArrayOfTag.length) {
                newel['aiTagsArray'] = this.commonUtils.removeDuplicatesByKey(
                  this.aiArrayOfTag,
                  'tagId',
                  'tagName'
                );
              }
              this.scrollToAssetContainerTop();
            });

            // clear TagArray
            this.tagArray = [];
            // populate TagArray
            this.assetDetails.forEach((el: any) => {
              this.tagArray.push({
                assetId: el.id,
                shortTagArrManual:
                  el.manualTags != undefined
                    ? el.manualTags.length > 2
                      ? [...el.manualTags].sort().splice(0, 2)
                      : [...el.manualTags].sort()
                    : [],
                longTagArrManual:
                  el.manualTags != undefined ? [...el.manualTags].sort() : [],
                shortTagArrAi:
                  el.aiTagsArray != undefined
                    ? el.aiTagsArray.length > 2
                      ? [...el.aiTagsArray].sort().splice(0, 2)
                      : [...el.aiTagsArray].sort()
                    : [],
                longTagArrAi:
                  el.aiTagsArray != undefined ? [...el.aiTagsArray].sort() : [],
                fileExt: this.commonUtils.getFileExt(el),
                showMoreManualTag: false,
                showMoreAiTag: false,
              }); //tagArray.push()
            }); // assetsList.forEach()
            this.isToolbarShown = false;
            this.multiSelect = false;
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
          this.totalRecords = 0;
          this.assetDetails = null;
        },
      });
  }

  // Function to store every selected asset into the map
  assetSelected(asset: any) {
    if (this.assetMapToStoreSelectedAssets.has(asset.id)) {
      this.assetMapToStoreSelectedAssets.delete(asset.id);
      this.contextmenu = false;
    } else {
      this.contextmenu = false;
      this.assetMapToStoreSelectedAssets.set(asset.id, asset);
    }
    this.selectedAssets = [...this.assetMapToStoreSelectedAssets.values()];
    this.appDataService.addAssetToSelectedAssets(
      this.assetMapToStoreSelectedAssets
    );
    this.selectAllCheck = !!(
      this.selectedAssets &&
      this.selectedAssets.length === this.assetDetails.length
    );
    if (this.selectedAssets.length < 1) this.multiSharePopUpIsOpen = false;
  }

  /* start get the positioning of the context menu */
  getPosition(e: any) {
    let windowWidth;
    let menuWidth;
    let windowHeight = window.innerHeight;
    if (!e) var e: any = window.event;
    if (e.pageX || e.pageY) {
      this.contextmenuX = e.pageX;
      this.contextmenuY = e.pageY;
    }
    if (e.pageY > windowHeight - 400) {
      this.contextmenuY = windowHeight - 400;
    }
    menuWidth = this?.contextMenuW.nativeElement.offsetWidth + 10;
    windowWidth = window.innerWidth;
    if (windowWidth - this.contextmenuX < menuWidth) {
      this.contextmenuX = windowWidth - menuWidth;
    } else {
      this.contextmenuX = this.contextmenuX;
    }
    return {
      x: this.contextmenuX,
      y: this.contextmenuY,
    };
  }
  /* end get the positioning of the context menu */
  //activates the menu with the coordinates
  onrightClick(event: any, asset: any) {
    this.newAssetContextMenuList = [];
    if (this.selectedAssets && this.selectedAssets.length > 1) {
      this.assetContextMenuList.forEach((element: any) => {
        if (element.multi) {
          this.newAssetContextMenuList.push(element);
        }
      });
    } else {
      if (
        this.selectedAssets[0].assetType === 2 ||
        this.selectedAssets[0].assetType === 4 ||
        this.selectedAssets[0].parentId != 0
      ) {
        this.newAssetContextMenuList = this.assetContextMenuList.filter(
          (item: any) => item.id !== 'trim/crop'
        );
      } else {
        this.newAssetContextMenuList = this.assetContextMenuList;
      }
    }
    this.getPosition(event);
    asset['contextmenu'] = !!asset.isSelected;

    this.contextmenu = asset['contextmenu'];
    // Bug 181276: DAM : ADMIN : Gen AI tags option is present for Video assets & when user selects it an progress popup is also shown
    if (
      this.selectedAssets &&
      this.selectedAssets.length == 1 &&
      this.selectedAssets[0].assetType != 0
    ) {
      this.newAssetContextMenuList = this.newAssetContextMenuList.filter(
        (item: any) => item.id !== 'genAiTag'
      );
    }
  }

  //========== Function to handle context menu click ==============//
  handleMenuSelection(menuselection: any) {
    switch (menuselection) {
      case 'copy':
        this.assetCopyFuction();
        this.contextmenu = false;
        break;
      case 'move':
        this.assetMoveFuction();
        this.contextmenu = false;
        break;
      case 'delete':
        this.opendeleteModal();
        this.contextmenu = false;
        break;
      case 'download':
        this.onItemDownloading();
        this.contextmenu = false;
        break;
      case 'bulkTag':
        this.bulkTagAsset();
        this.contextmenu = false;
        break;
      case 'genAiTag':
        this.generateAiTagForAssets();
        this.contextmenu = false;
        break;
      case 'syncWithELK':
        this.syncAssetsWithELK();
        this.contextmenu = false;
        break;
      case 'trim/crop':
        if (this.selectedAssets[0].assetType === 0) {
          this.fileChangeHandler(this.selectedAssets);
          this.contextmenu = false;
        } else {
          this.contextmenu = false;
          this.TrimVideo();
          this.contextmenu = false;
        }
        break;
      case 'details':
        this.openAssetDetailModal();
        this.contextmenu = false;
        break;
      default:
        this.contextmenu = false;
        break;
    }
  }

  //================== FUNCTION TO SYNC ASSETS WITH ELK =========================
  syncAssetsWithELK() {
    this.restService
      .get(API_KEYPOINT.elk.syncAssets + this.selectedAssets[0].id)
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Successfull!',
            detail: data.message,
          });
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  //============= Function to share multiple asset urls ===============
  //getAssertUrlsShareMultipleSelectedAssets

  //============= Function to share multiple asset urls on gdrive===============

  async ShareAssetsOnGdrive(asset: any) {
    let sharedAssetsId: any = [];
    sharedAssetsId.push(asset);
    if (!this.gdriveShare.isUserLoggedIn()) {
      await this.gdriveShare.getUserLoggedIn(() => {
        sharedAssetsId.forEach((_source: any) => {
          this.shareAssetOnGdrive(_source);
        });
      });
    } else {
      sharedAssetsId.forEach((_source: any) => {
        this.shareAssetOnGdrive(_source);
      });
    }
  } //ShareAssetsOnGdrive
  //============= Function to share asset on gdrive===============
  async shareAssetOnGdrive(_source: any) {
    await this.gdriveShare.handleAuthClick(
      this.preSignedUrl[_source.id],
      _source.assetName
    );
  } //shareAssetOnGdrive

  //========Function to open share asset popup ==========
  openSharePopUp(AssetName: any, event: any) {
    event.stopPropagation();
    this.openSharePopUpAsset =
      this.openSharePopUpAsset == AssetName ? '' : AssetName;
  } //openSharePopUp

  // ============ Function to generate AI tags for assets ==============//
  generatedAiTagResponse: Map<String, Array<String>>;
  generatedAiTagResponseLength: number = 0;
  generateAiTagForAssets() {
    let assetIdgenerateAiTagArr: Array<Number> = [];
    let selectedAssetArr: Array<{
      id: number;
    }> = this.selectedAssets;
    this.generatedAiTagResponse = new Map<String, Array<String>>();

    //fetch all the selected Asset id
    selectedAssetArr.forEach((asset) => {
      assetIdgenerateAiTagArr.push(asset.id);
    });
    let requestBody = {
      assetArr: assetIdgenerateAiTagArr,
    };
    try {
      this.messageService.add({
        severity: 'success',
        summary: 'Successfull!!',
        detail:
          'AI tags are being generated. Please refresh later to view the tags.',
      });
      let params = {
        fileDetails: this.objectForSourceTrack,
        action: 'genAITag',
      };
      this.triggerMethodAssets.emit(params);
      this.restService
        .post(API_KEYPOINT.elk.publishAssets, requestBody, '', false)
        .subscribe({
          next: (data: any) => {
            let responseArr: Array<{
              tagName: string;
              id: number;
              category: number;
              tagcategory: string;
            }> = data.result;
            responseArr.forEach((obj) => {
              if (this.generatedAiTagResponse.has(obj.tagcategory)) {
                this.generatedAiTagResponse
                  .get(obj.tagcategory)
                  ?.push(obj.tagName);
              } else {
                this.generatedAiTagResponse.set(obj.tagcategory, [obj.tagName]);
              }
            });
            this.isToolbarShown = false;
            this.selectedAssets = [];
            this.contextmenu = false;
            this.selectAllCheck = false;
            this.assetArray = [];
            this.generatedAiTagResponseLength =
              this.generatedAiTagResponse.size;
            // get model Element By Id
            let element = document.getElementById('generateAiTagModelButton');
            //click model open Button element
            element?.click();
            /* Task 179192: FE Changes - Bulk AI tagging for folder & removal of loader on 'Gen AI tag' action*/
            // Intially 'Ai tags generation started' msg added so no need to show toast after api success bcs loader remove for this api//
          },
          error: (error: any) => {
            this.selectedAssets = [];
            this.contextmenu = false;
            this.selectAllCheck = false;
            this.assetArray = [];
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: 'Unable to generate AI tags at this moment',
            });
            throw error;
          },
        });
    } catch (error) {}
  } //generateAiTagForAssets

  yearDayMonthHoursMinutes() {
    // Create a new Date object using the timestamp
    const timestamp = Date.now();
    const dateObj = new Date(timestamp);

    // Extract the individual date and time components
    const year = dateObj.getFullYear().toString().substring(2);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const second = dateObj.getSeconds().toString().padStart(2, '0');

    // Generate the desired timestamp format
    return `${year}${day}${month}_${hours}${minutes}${second}`;
  }

  //============Function to crop any asset ========
  async fileChangeHandler(assetDetail: any) {
    // Assetname is showing after image cropping.
    let assetsDummyName = assetDetail[0].assetName.slice(0, 88).split('.');
    this.inputTagAssetDefaultName =
      assetsDummyName[0] + '_' + this.yearDayMonthHoursMinutes();
    this.imageNameForOnCancel = this.inputTagAssetDefaultName;
    this.trimImageFrom.controls['imageName'].setValue(
      this.inputTagAssetDefaultName
    );
    this.contextmenu = false;
    this.trimImageFrom.controls['imageName'].enable();
    if (assetDetail[0].assetType === 0) {
      this.imageUrl = this.formatS3UrlPipe.transform(
        this.appService.s3BaseUrl +
          assetDetail[0].assetPath +
          '/' +
          assetDetail[0].assetName
      );
      this.imageExt = this.imageUrl.substring(
        this.imageUrl.lastIndexOf('.') + 1
      );
    }
    if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'jfif') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'jfif file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'webp') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'webp file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'tiff') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'tiff file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'ico') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'ico file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'svg') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'svg file format is not supported for trim/crop action!',
      });
    } else {
      this.openImageCrop = true;
      this.preSignedUrl = await this.appDataService.generatePreSignedUrl(assetDetail[0].id, this.preSignedUrl);
      const url = this.preSignedUrl[assetDetail[0].id];
      this.appService.getImage(url).subscribe({
        next: (data: any) => {
          this.service.open(data, {
            aspectRatio: 4 / 3,
            autoCropArea: 1,
            viewMode: 1,
          }).subscribe((data) => {
            this.isTrimImage = true;
            this.croppedFile = data.file;
            this.openImageCrop = false;
            this.croppedsrcUrl = data.base64;
            this.output = data;
          });
        }
      })
    }
  }
  //Function to trim audio / video files
  async TrimVideo() {
    this.isTrimVideo = true;
    this.preSignedUrl = await this.appDataService.generatePreSignedUrl(this.selectedAssets[0].id, this.preSignedUrl);
  }

  /* parent method for integrate video-trim component*/
  showPreview(event: any) {
    if (!event) {
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethodAssets.emit(params);
      this.isTrimVideo = false;
    } else if (event.trimAssetId && event.IsVideoSave === 1) {
      this.deleteTrimVideo(event.trimAssetId, event.IspreviewShow);
      this.isTrimVideo = false;
    } else if (event.trimAssetId && event.IsVideoSave === 0) {
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethodAssets.emit(params);
      this.isTrimVideo = false;
    }
  }

  /* delete api When user can not save trim assets*/
  deleteTrimVideo(trimAssetId: any, IspreviewShow: any) {
    this.assetArray = [];
    this.assetArray.push({ assetId: trimAssetId });
    const param: any = {
      assetArr: this.assetArray,
    };
    this.restService.post(API_KEYPOINT.assets.assetDelete, param).subscribe({
      next: (data: any) => {
        if (data.code === 200) {
          if (!IspreviewShow) {
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethodAssets.emit(params);
          }
        }
      },
      error: (err) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message,
        });
      },
    });
  }

  /* image editor close modal event */
  closeTrimImage(formImage: any) {
    this.submitted = false;
    this.isTrimImage = false;
    formImage.reset();
    this.trimImageFrom.markAsPristine();
    this.trimImageFrom.markAsUntouched();
  }
  /* image editor save cropped image */
  /* image editor closed cropped image */

  /* Method for save and upload cropped image*/
  async submitFormDataImage(formImage: any) {
    this.submitted = false;
    if (formImage.valid) {
      const fd = new FormData();
      const myFile = new File(
        [this.croppedFile],
        this.trimImageFrom.value.imageName + '.' + this.imageExt,
        {
          type: this.croppedFile.type,
        }
      );
      this.trimImageFrom.controls['imageName'].disable();
      fd.append('folderId', this.parentId);
      fd.append('referenceAssetId', this.selectedAssets[0].id);
      fd.append('uploadedBy', '1');
      fd.append('assetPath', this.selectedAssets[0].assetPath);
      fd.append('asset', myFile);
      const options = {
        contentType: 'multipart/form-data',
        reportProgress: true,
        observe: 'events',
      };
      // changes done for  bug 175716 wrong toast was appearing when user user crop the asset.
      //added differnt popup messages ass per asset which is getting trim/crop.
      await new Promise((resolve, reject) => {
        this.restService
          .post(API_KEYPOINT.assets.saveTrimmedAsset, fd, options)
          .subscribe({
            next: (event: any) => {
              let type = this.selectedAssets[0];

              if (event.type === HttpEventType.UploadProgress) {
              } else if (event instanceof HttpResponse) {
                this.isTrimImage = false;
                this.submitted = false;
                this.trimImageFrom.reset();
                this.trimImageFrom.markAsPristine();
                this.trimImageFrom.markAsUntouched();
                let params = { fileDetails: this.objectForSourceTrack };
                this.triggerMethodAssets.emit(params);
                this.isToolbarShown = false;
                if (
                  event.body &&
                  event.body.message === 'Asset already exist'
                ) {
                  this.messageService.add({
                    severity: 'warn',
                    summary: 'Warning!',
                    detail: 'Asset with this name already exists',
                  });
                } else {
                  if (type.assetType === 0)
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Success!',
                      detail: 'Image Cropped Sucessfully!',
                    });
                  else if (type.assetType === 1)
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Success!',
                      detail: 'Video trimmed successfully!',
                    });
                  else if (type.assetType === 3)
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Success!',
                      detail: 'Audio trimmed successfully!',
                    });
                }
              }
              resolve(event);
            },
            error: (err: any) => {
              this.selectedAssets = [];
              this.appDataService.addAssetToSelectedAssets(new Map());
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: err.error.message,
              });
              reject(err.error.message);
            },
          });
      });
    }
  }

  // =========== Function to preview document =============

  /* Full screen slider open on preview button click*/
  async openSliderModal(item: any, event: any) {
    this.showAssetPreview = true;
    event.stopPropagation();
    this.slideritemdata = item;
  }

  async onAssetPreviewChange(action: any) {
    switch (action.type) {
      case 'close':
        this.showAssetPreview = false;
        break;
      case 'download':
        this.preSignedUrl = await this.appDataService.generatePreSignedUrl(action.data?.id, this.preSignedUrl);
        window.open(this.preSignedUrl[action.data?.id], '_blank');
        this.logs([action.data?.id],"ASSETDOWNLOAD");
        break;
    }
  }

  assetCopyFuction() {
    this.callGetAllListParent();
    if (this.selectedAssets.length === 0) return;
    if (this.selectedAssets.length === 1) {
      this.assetsHeader = 'Copy ' + this.selectedAssets.length + ' asset';
    } else {
      this.assetsHeader = 'Copy ' + this.selectedAssets.length + ' assets';
    }
    this.displayDialogBox = true;
    this.assetsFooter = 'Copy';
    this.assetsCopyType = 0;
    this.contextmenu = false;
    let params = { action: 'copy' };
    this.triggerMethodAssets.emit(params);
  }
  //this function works on clicking move to button
  assetMoveFuction() {
    this.callGetAllListParent();
    if (this.selectedAssets.length === 0) return;
    if (this.selectedAssets.length === 1) {
      this.assetsHeader = 'Move ' + this.selectedAssets.length + ' asset';
    } else {
      this.assetsHeader = 'Move ' + this.selectedAssets.length + ' assets';
    }
    this.assetsFooter = 'Move Here';
    this.displayDialogBox = true;
    this.assetsCopyType = 1;
    this.contextmenu = false;
    let params = { action: 'copy' };
    this.triggerMethodAssets.emit(params);
  }
  //======================= All root folder Listg =========//
  async callGetAllListParent() {
    await this.folderMgmt.setFolderElementList({
      limit: 500,
      pageno: 1,
      parentId: 0,
      userId: this.userId,
      isFolderSearch: false,
      // Bug 83368: DAM: Admin/Validator/Photographer/Retreiever: User is able to copy/move a folder A with RWUD access to another folder B which has only read access.
      isCopyMoveModal: true,
    });
    this.firstItem.children = this.folderMgmt.getFolderList();
    this.firstItem.isCollapse = false;

    //set Track
    this.folderMgmt.setTrackRecord(this.firstItem);

    if (this.isFolderSearchParams) {
      let path = this.sourceLocation.split('/');
      path = this.commonUtils.removeClinetID(path);

      let arr = [];
      for (let i = 0; i < path.length; i++) {
        let tempObject = {
          folderItem: {
            folderId: parseInt(path[i]),
          },
        };
        arr.push(tempObject);
      }
      this.breadCrumbArrayOfFolderItem = [];
      await this.doRedirectionUsingRedirectionArray(
        this.firstItem.children,
        arr,
        0
      );
    } else {
      let path = this.sourceLocation.split('/');
      path = this.commonUtils.removeClinetID(path);
      let arr = [];
      for (let i = 0; i < path.length; i++) {
        let tempObject = {
          folderItem: {
            folderId: parseInt(path[i]),
          },
        };
        arr.push(tempObject);
      }
      this.breadCrumbArrayOfFolderItem = [];
      await this.doRedirectionUsingRedirectionArray(
        this.firstItem.children,
        arr,
        0
      );
      this.firstItem.isCollapse = false;
    }
  }
  async checkSubmitCopyData() {
    //checking if destination folder is a child of the source folder
    const addressArray = this.destinationLocation.split('/');
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index] === this.sourceFolderId.toString()) {
        this.index1 = index;
      }
      if (addressArray[index] === this.destinationFolderId.toString()) {
        this.index2 = index;
      }
    }
    //the case where parent folder is being copied / moved into a child folder .
    if (
      this.sourceFolderId.toString() === this.destinationFolderId.toString()
    ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: `Source and destination folder is the same!`,
      });
    } else if (this.index1 === -1 || this.index2 === -1) {
      await this.submitCopyData();
    } else {
      await this.submitCopyData();
    }
  }
  //======================= copy assets method =========//
  async submitCopyData() {
    this.assetArray = [];
    this.selectedAssets.forEach((ele: any) => {
      this.assetArray.push({
        assetId: ele.id,
        assetName: ele.assetName,
        assetType: ele.assetType,
        thumbnailAssetName: ele.thumbnailAssetName,
      });
    });

    const param: any = {
      assetArr: this.assetArray,
      sourceLocation: this.sourceLocation,
      destinationLocation: this.destinationLocation,
      sourceFolderId: this.sourceFolderId,
      destinationFolderId: this.destinationFolderId,
      copyOrMove: this.assetsCopyType,
      destinationLink: this.commonUtils.shareCopyFolderUrl(
        this.destinationLocation
      ), // link of copied/moved folder received in the mail
    };
    this.restService.post(API_KEYPOINT.assets.copyMoveAsset, param).subscribe({
      next: async (data: any) => {
        if (data.code === 200) {
          this.contextmenu = false;
          const resultToSendToClient = data?.result?.resultToSendToClient;
          this.checkAssetStatus(resultToSendToClient);
          let folderId = this.destinationLocation.split('/');
          folderId = this.commonUtils
            .removeClinetID(folderId)
            .filter((segment: any) => segment !== '')
            .join(',');

            //User Story 163817: Number of assets in the folder on hover
            this.fileDetails['destinationLocation'] =
              this.destinationLocation;
            let params = {
              fileDetails: this.fileDetails,
              action: 'completeCopy',
            };
            this.triggerMethodAssets.emit(params);
            this.displayDialogBox = false;
            this.destinationFolderId = '';
            this.destinationLocation = '';
            this.sourceFolderId = '';
            this.sourceLocation = '';
            this.assetsHeader = '';
            this.assetsFooter = '';
            this.selectedAssets = [];
          }
        },
        error: (err) => {
          this.contextmenu = false;
          this.displayDialogBox = false;
          this.selectedAssets = [];
          this.fileDetails['destinationLocation'] = this.destinationLocation;
          this.fileDetails.folderId = Number(this.sourceLocation.split('/')[1]);
          let params = {
            fileDetails: this.fileDetails,
            action: 'error',
          };
          this.triggerMethodAssets.emit(params);
          this.appDataService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
  }

  checkAssetStatus(assetArray: any) {
    const notCopiedAsset = assetArray.filter(
      (asset: any) => asset.statusMessage !== 'SUCCESS'
    );
    const copiedAsset = assetArray.filter(
      (asset: any) => asset.statusMessage == 'SUCCESS'
    );
    let message = {
      severity: 'warn',
      summary: 'Warning!',
      detail: '',
    };
    switch (true) {
      case notCopiedAsset.length > 0 && copiedAsset.length >= 0:
        message.detail = `Some assets may not have been ${
          this.assetsCopyType === 0 ? 'copied' : 'moved'
        }`;
        if (copiedAsset.length === 0 && notCopiedAsset.length === 1) {
          message.detail = notCopiedAsset[0].message;
        }
        break;
      default:
        message.severity = 'success';
        message.summary = 'Success!';
        message.detail = `Assets have been successfully ${
          this.assetsCopyType === 0 ? 'copied' : 'moved'
        }`;
        break;
    }
    this.messageService.add(message);
  }

  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.contextmenu = false;
    this.displayDialogBox = false;
    this.selectedAssets = [];
    this.destinationLocation = '';
    let tempParentFolder: IFolderDetails = this.objectForSourceTrack;

    if (this.isFolderSearchParams) {
      this.firstItem.children = this.folderMgmt.getFolderList2();
    } else {
      while (tempParentFolder.parentId != 0) {
        tempParentFolder = this.folderMgmt.getTrackRecord(
          tempParentFolder.parentId
        );
        tempParentFolder.isCollapse = true;
      }
    }

    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
    let params = { action: 'cancel' };
    this.triggerMethodAssets.emit(params);
  }
  //======================= dynamic nested listing of subfolder =========//
  async openFolder(item: IFolderDetails) {
    let queryParam;
    this.parentId = item.folderId;
    this.callFolderDetails(item);
    this.getParentFolderAccess(item);
    this.fileDetails = item;
    if (item.folderCount && parseInt(item.folderCount) >= 1) {
      this.isCopyVisible = true;
      if (item.isCollapse) {
        this.isCollapse = true;
        queryParam = {
          limit: 500,
          pageno: 1,
          parentId: item.folderId,
          userId: this.userId,
          isFolderSearch: false,
        };
        await this.folderMgmt.setFolderElementList(queryParam);
        item.children = this.folderMgmt.getFolderList();
        //set Track
        this.folderMgmt.setTrackRecord(item);
      } else {
        this.isCollapse = !this.isCollapse;
        //get previous track
        this.folderMgmt.setFolderList(
          this.folderMgmt.getTrackRecord(item.parentId).children
        );
      }
    } else {
      this.isCopyVisible = false;
    }
    this.previousFolderInfoForRedirction = item;

    //calling this method to store opened folder information in our 'breadCrumbArrayOfFolderItem' array
    this.addItemForBreadCrumb(item);
    if (item.parentId === 0) {
      this.previoulyPoppedFolderItem = null;
    }

    //adding item to our data structure to tranck opened folders
    this.folderMgmt.addItemToOpenedFolderDataStruct(item);
  } //refreshed

  //=========call Folder access api =========//
  getParentFolderAccess(item: any) {
    let folderId = item.folderId ? item.folderId : item;
    if (folderId !== 0) {
      this.restService
        .post(API_KEYPOINT.folder.getParentFolderAccess, {
          folderId: folderId,
        })
        .subscribe((data: any) => {
          if (data.code === 200) {
            this.parentFolderList = data.result;
            this.parentFolderList.filter((newel: any) => {
              if (this.userId === newel.assignedTo && newel.accessType === 2) {
                this.hasAccess = true;
              }
            });
          }
        });
    }
  }

  callFolderDetails(item: any) {
    const id = item.isCollapse ? item.folderId : item.parentId;
    this.destinationLocation = '';

    this.restService
      .get(API_KEYPOINT.folder.folderInfo + id)
      .subscribe((data: any) => {
        if (data && data.result.length && data.result[0]) {
        const folderData = data.result[0];
        
        if (folderData) {
          this.destinationfolderDetails = folderData;
          this.destinationFolderId = id;
          const idPath = folderData.id_path;
          
          if (idPath) {
            this.destinationLocation = idPath.substring(0, idPath.length - 1);
            const path = this.commonUtils.removeClinetID(this.destinationLocation.split('/'));  
            this.isDestinationFolderDisabled =
              this.sourceFolderId.toString() === this.destinationFolderId.toString() || path.length === 0;
          }
        }
      }
    });
  }

  //========Method for added tag using TAB press=======//
  //============= open delete folder confirmation modal  =========//
  opendeleteModal() {
    this.isDeleteModalShow = true;
    this.contextmenu = false;
  }
  //============= close delete folder modal  =========//
  closedeleteModal() {
    this.isDeleteModalShow = false;
  }

  //=========== close event modal ==========//
  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.uploadBulkTagsForm.patchValue({
        eventId: event.id,
      });
      this.getEvents();
    }
    this.IsmodelEventShow = false;
  }

  // Common Method for single and multi assets delete
  onItemDeleted() {
    let assetId: any = [];
    if (this.selectedAssets.length >= 1) {
      const assetsToDelete = this.selectedAssets.slice(); // Create a copy of selectedAssets array

      const observablesBatch = [];
      while (assetsToDelete.length > 0) {
        const batch = assetsToDelete.splice(0, 20); // Get a batch of 20 assets
        const assetArray = batch.map((item: any) => {
          const assetObj = {
            assetId: item.id,
            folderId: this.sourceFolderId,
          };
          assetId.push(item.id); // Append assetId to anotherArray.
          return assetObj;
        });
        const param: any = { assetArr: assetArray };
        const deleteObservable = this.restService.post(
          API_KEYPOINT.assets.assetDelete,
          param
        );
        observablesBatch.push(deleteObservable);
      }

      forkJoin(observablesBatch).subscribe({
        next: () => {
          // Handle success
          if (this.selectedAssets.length == 1) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Asset deleted Successfully!',
            });
          } else {
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Assets deleted Successfully!',
            });
          }
          this.isDeleteModalShow = false;
          this.contextmenu = false;
          this.selectedAssets = [];
          //User Story 163817: Number of assets in the folder on hover
          let updateCountDestinationParams = {
            fileDetails: this.objectForSourceTrack,
            assetUploadCount: true,
          };
          this.triggerMethod.emit(updateCountDestinationParams);
        },
        error: (err) => {
          this.isDeleteModalShow = false;
          this.contextmenu = false;
          this.selectedAssets = [];
          this.appDataService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
      this.logs(assetId,"ASSETDELETE");
    }
  }
  // Common Method for single and multi Assets download
  onItemDownloading() {
    this.assetArray = [];
    let assetId: any = [];
    this.selectedAssets.forEach((item: any) => {
      if (item.assetSize > 819200000) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success!',
          detail: 'File is downloading..',
        });
      }
    });
    // For single Download
    if (this.selectedAssets.length <= 1) {
      let myparams = {
        assetId: this.selectedAssets[0].id,
        assetName:
          this.selectedAssets[0].assetPath +
          '/' +
          this.selectedAssets[0].assetName,
        type: 0,
      };
      assetId.push(this.selectedAssets[0].id);
      const options = {
        responseType: 'blob',
      };

      this.restService
        .post(API_KEYPOINT.assets.assetDownload, myparams, options, false)
        .subscribe({
          next: (res: any) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(res);
            a.href = objectUrl;
            a.download = this.selectedAssets[0].assetName;
            a.click();
            URL.revokeObjectURL(objectUrl);
            this.selectedAssets = [];
            this.contextmenu = false;
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethodAssets.emit(params);
          },
          error: (error: any) => {
            this.selectedAssets = [];
            this.contextmenu = false;
            this.appDataService.addAssetToSelectedAssets(new Map());
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
    }
    // For MultiSelect Download
    else {
      let FolderPath: any = {};
      for (let item of this.selectedAssets) {
        this.assetArr.push(
          this.appService.s3BaseUrl + item.assetPath + '/' + item.assetName
        );
        assetId.push(item.id);
      }
      let path = this.selectedAssets[0].assetPath;
      FolderPath.Ids = path.split('/');
      FolderPath.Ids = this.commonUtils.removeClinetID(FolderPath.Ids);
      FolderPath.Url = this.shareFolderUrl();

      const params = {
        assetArr: this.assetArr,
        OriginPath: FolderPath,
      };

      const options = {
        responseType: 'blob',
      };

      this.restService
        .post(API_KEYPOINT.assets.assetMultiDownload, params, options, false)
        .subscribe({
          next: () => {
            this.selectedAssets = [];
            this.contextmenu = false;
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethodAssets.emit(params);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail:
                'Zip will be downloaded and will be sent to you via email.',
            });
            this.loader.setLoading(false);
          },
          error: (error: any) => {
            this.selectedAssets = [];
            this.contextmenu = false;
            this.appDataService.addAssetToSelectedAssets(new Map());
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
      this.assetArr = [];
    }
    this.logs(assetId,"ASSETDOWNLOAD");
  }

  shareFolderUrl() {
    let editUrl = '';
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath.split('/');
      editUrl = this.commonUtils
        .removeClinetID(editUrl)
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      APP_ROUTE.assets +
      '/' +
      '?folderPath=' +
      editUrl;
    return shareLink;
  }
  //function to copy the link to clipboard
  copyLinkToClipboard(asset: any) {
    this.appService.copyLinkToClipboard(this.preSignedUrl[asset.id]);
    this.messageService.add({
      severity: 'success',
      summary: 'Success!',
      detail: 'File Link Copied!',
    });
  }

  // This function is called when click on see More button to get batch of new assets and append it in previous list
  async seeMore() {
    for (let i = 0; i < this.selectedAssets.length; i++) {
      this.selectedAssets[i].isSelected = false;
    }
    this.selectedAssets = [];
    this.selectAllCheck = false;
    this.assetArray = [];
    this.appDataService.addAssetToSelectedAssets(new Map());
    this.queryParams.pageno += 1;
    await this.getNewAssetsList();
    this.newAssetDetails.forEach((ele: any) => {
      this.assetDetails.push(ele);
    });
  }

  //This function is to fetch new assets
  async getNewAssetsList() {
    //  this.queryParams.pageno = this.myparams.pageno;
    return await new Promise((resolve) => {
      this.restService
        .post(API_KEYPOINT.assets.assetsList, this.queryParams)
        .subscribe({
          next: (data: any) => {
            if (data.code == 200) {
              data.result.forEach((ele: any) => {
                // ele.isSelected = this.selectAllCheck;
                ele.isSelected = false;
              });
              this.selectAllCheck = false;
              this.totalRecords = data.totalCount ? data.totalCount : 0;
              this.newAssetDetails = data.result;
              this.newAssetDetails.filter((newel: any) => {
                this.arrayOfReTag = newel.tags;
                this.aiArrayOfTag = newel.aiTags;
                newel['contextmenu'] = false;
                newel['manualTags'] = [];
                newel['aiTagsArray'] = [];

                if (this.arrayOfReTag && this.arrayOfReTag.length) {
                  this.arrayOfReTag.forEach((obj) => {
                    newel['manualTags'].push(obj.tagname);
                    this.arrayOfReTagName.push(obj.tagname);
                  });
                }
                if (this.aiArrayOfTag && this.aiArrayOfTag.length) {
                  newel['aiTagsArray'] = this.commonUtils.removeDuplicatesByKey(
                    this.aiArrayOfTag,
                    'tagId',
                    'tagName'
                  );
                }
              });

              //populate TagArray
              this.newAssetDetails.forEach((el: any) => {
                this.tagArray.push({
                  assetId: el.id,
                  shortTagArrManual:
                    el.manualTags != undefined
                      ? el.manualTags.length > 2
                        ? [...el.manualTags].sort().splice(0, 2)
                        : [...el.manualTags].sort()
                      : [],
                  longTagArrManual:
                    el.manualTags != undefined ? [...el.manualTags].sort() : [],
                  shortTagArrAi:
                    el.aiTagsArray != undefined
                      ? el.aiTagsArray.length > 2
                        ? [...el.aiTagsArray].sort().splice(0, 2)
                        : [...el.aiTagsArray].sort()
                      : [],
                  longTagArrAi:
                    el.aiTagsArray != undefined
                      ? [...el.aiTagsArray].sort()
                      : [],
                  fileExt: this.commonUtils.getFileExt(el),
                  showMoreManualTag: false,
                  showMoreAiTag: false,
                }); //tagArray.push()
              }); //assetsList.forEach()
              resolve((this.multiSelect = false));
              this.isToolbarShown = false;
              this.multiSelect = false;
            }
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
            this.totalRecords = 0;
            this.newAssetDetails = null;
          },
        });
    });
  }

  changeSize(selectedFilter: any) {
    this.selectedFilter.pageSize = selectedFilter;
    this.queryParams.limit = selectedFilter.value;
    this.selectedAssets = [];
    this.selectAllCheck = false;
    this.assetArray = [];
    this.appDataService.addAssetToSelectedAssets(new Map());
    this.getAllAssetsList();
  }

  //function to open the image on clicking the downloadbutton
  async redirectToNewTab(asset: any, event: any) {
    event?.stopPropagation();
    this.preSignedUrl = await this.appDataService.generatePreSignedUrl(asset.id, this.preSignedUrl);
    window.open(this.preSignedUrl[asset.id],'_blank');
    this.logs([asset.id],"ASSETDOWNLOAD");
  }
  // Your logic to determine whether to show or hide the clear button
  determineClearButtonVisibility() {
    this.iscrossicon = this.tagStatusArray[0].name !== undefined;
  }
  //============= open detail assets modal  =========//
  openAssetDetailModal() {
    this.appService.searchResult(this.selectedAssets[0].id, {
      assetPath: this.selectedAssets[0].assetPath,
    });
  }
  //============= close delete folder modal  =========//

  //Below function handles click event of filter options for sort by category.
  //Below function handles click event of filter options for sort by status.
  selectAssetStatusForSorting(selectedFilter: any) {
    this.queryParams.assetStatus = this.assetStatus;
    this.selectedFilter.assetStatus = selectedFilter;
    this.determineClearButtonVisibility();
    this.myparams.pageno = 1;
    this.currentPage = 1;
    this.selectedAssets = [];
    this.appDataService.addAssetToSelectedAssets(new Map());
    this.updateCurrentFilters();
    this.getAllAssetsList();
    this.selectAllCheck = false;
  }

  updateCurrentFilters() {
    this.appDataService.setAssetsFilterState({
      assetTag: this.assetTag.toString(), // to avoid 0 as a false case,
    });
  }

  // for select all asset
  selectAll(event: any) {
    this.selectedAssets = [];
    this.assetArray = [];
    this.assetDetails.forEach((ele: any) => {
      ele.isSelected = event.target.checked;
      if (event.target.checked) {
        this.assetMapToStoreSelectedAssets.set(ele.id, ele);
        // this.selectedAssets.push(ele);
        this.isToolbarShown = true;
      } else {
        if (this.assetMapToStoreSelectedAssets.has(ele.id)) {
          this.assetMapToStoreSelectedAssets.delete(ele.id);
          this.contextmenu = false;
        }

        this.isToolbarShown = false;
      }
    });
    this.selectedAssets = [...this.assetMapToStoreSelectedAssets.values()];
    this.selectedAssets.forEach((ele: any) => {
      this.assetArray.push(ele.id);
    });
    this.appDataService.addAssetToSelectedAssets(
      this.assetMapToStoreSelectedAssets
    );
  }
  //==============Folder access permission based on folderId=========//
  getRightClickPermission(event: any, item: any) {
    let folderIdArr = (item.assetPath + '').split('/');
    let roleId = this.authService.getAuthData().roleId;
    this.assetContextMenuList.forEach((el: any) => {
      // allow syncWithELK for Admin and SuperAdmin for each folder's asset
      if (
        el.id == 'syncWithELK' &&
        (roleId == 1 || roleId == 2 || roleId == 6)
      ) {
        el['hasPermission'] = true;
      } else {
        //BugFix-165966:: DAM : Admin : User is able to move and copy to "read only" folder and get all the right access.
        el['hasPermission'] =
          this.permissionsEngine.folderAccessOnHasPermission(
            Number.parseInt(folderIdArr[folderIdArr.length - 1]),
            el.minPermission
          );
      }
    });
    this.onrightClick(event, item);
  }

  //method to bulk tag assets
  bulkTagAsset() {
    this.isBulkAssetTag = true;
    this.assetNameArray = [];
    this.assetsIdArray = [];
    // clear ManualTagArray
    this.manualTagArray = [];
    if (this.selectedAssets && this.selectedAssets.length) {
      this.selectedAssets.forEach((el: any) => {
        this.assetsIdArray.push(el.id);
        this.assetNameArray.push(el.assetName);
      });
      this.assetTag = this.selectedAssets[0].assetTag;
    }
    this.getEvents();
  }
  getEvents() {
    this.restService.get(API_KEYPOINT.event.getEvents).subscribe({
      next: (data: any) => {
        if (data.code == 200) {
          this.eventsList = data.result;
        }
      },
      error: (error: any) => {
        this.selectedAssets = [];
        this.appDataService.addAssetToSelectedAssets(new Map());
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
  }

  //This function is used to check valid tags while tagging any asset
  //==========================Open Create Event Modal while tagging asset================================//
  openEventModal() {
    this.IsmodelEventShow = true;
  }

  //=======================RESET BULK TAG FORM =========================
  resetBulkTagForm() {
    this.isViewMode = false;
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset({
      assetTag: [0], // Set the assetTag control to 0 (Pending) by default
    });
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
  }

  //========================Close POPUP For TaggingAsset=========================//
  goBack() {
    this.isViewMode = false;
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.isBulkAssetTag = false;
    this.selectedAssets = [];
    this.appDataService.notifyChange(this.objectForSourceTrack);
    this.appDataService.addAssetToSelectedAssets(new Map());
  }

  SaveBulkTagInfo() {
    this.assetDescription = this.uploadBulkTagsForm.value.assetDescription;
    this.assetTitle = this.uploadBulkTagsForm.value.assetTitle;
    this.manualTagArray = this.uploadBulkTagsForm.value.tagArray;
    this.bulkAssetStatus = this.uploadBulkTagsForm.value.assetTag;
    this.eventsList.filter((newel: any) => {
      if (newel.id === this.uploadBulkTagsForm.value.eventId) {
        this.event.eventName = newel.eventName;
        this.event.eventDescription = newel.description;
        this.event.eventLocation = newel.eventLocation;
        this.event.eventTime = newel.eventTime;
      }
    });
    if (this.manualTagArray && this.manualTagArray.length) {
      this.shortTagArrManual = this.manualTagArray.slice(0, 10);
      this.longTagArrManual = this.manualTagArray.slice(10);
    }
    this.isViewMode = false;
    this.uploadBulkTag();
    this.uploadBulkTagsForm.get('tagArray').reset([]);
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsUntouched();
    this.uploadBulkTagsForm.markAsPristine();
    this.isBulkAssetTag = false;
  }

  uploadBulkTag() {
    this.uploadBulkTagsForm.value.assetArr = this.assetsIdArray;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
      this.uploadTagsForm.value.assetTag === undefined ||
      this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadBulkTagsForm.value.folderId = this.childFolderId;
    // set Default value for form attributes
    if (this.uploadBulkTagsForm.value.tagArray == null) {
      this.uploadBulkTagsForm.value.tagArray = [];
    }
    if (this.uploadBulkTagsForm.value.assetTag == null) {
      this.uploadBulkTagsForm.value.assetTag = 0;
    }
    if (this.uploadBulkTagsForm.value.assetDescription == null) {
      this.uploadBulkTagsForm.value.assetDescription = '';
    }
    if (this.uploadBulkTagsForm.value.assetTitle == null) {
      this.uploadBulkTagsForm.value.assetTitle = '';
    }
    this.restService
      .post(API_KEYPOINT.assets.uploadBulkTags, this.uploadBulkTagsForm.value)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethodAssets.emit(params);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
          }
        },
        error: (error: any) => {
          this.isAssetTag = false;
          this.selectAllCheck = false;
          this.selectedAssets = [];
          this.isBulkAssetTag = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  //====================FUNCTION TO SAVE SHARE LINK HISTORY =============================
  saveShareLink(assets: any) {
    let assetArr: any = [];
    let folderPath = assets?.assetPath.split('/');
    folderPath = this.commonUtils.removeClinetID(folderPath);

    let assetArrObj = {
      assetName: assets.assetName,
      assetId: assets.id,
      folderId: Number(folderPath[folderPath.length - 1]),
    };
    assetArr.push(assetArrObj);
    const params = {
      assetArr: assetArr,
      OriginPath: { Ids: folderPath },
    };
    //call saveShareLink API to save the history of shared assets
    this.restService.post(API_KEYPOINT.assets.saveShareLink, params).subscribe({
      next: () => {
        return;
        //this.downloadHistoryList = data.result;
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
    this.logs([assets.id],"ASSETSHARE");
  }

  //this method is being used for adding element to the stack data structure based on some conditions
  addItemForBreadCrumb(item: any) {
    let newSet = new Set();
    //add items children to the set
    if (item.children) {
      item.children.map((itemChild: any) => {
        newSet.add(itemChild.folderId);
      });
    }
    let tempObject = {
      folderItem: item,
      setDS: newSet,
    };
    //now add tempObject to the current array that is our stack
    let idx = this.breadCrumbArrayOfFolderItem.length - 1;
    let flag = true;
    while (
      idx > -1 &&
      this.breadCrumbArrayOfFolderItem[idx].setDS.has(item.folderId) === false
    ) {
      if (
        this.breadCrumbArrayOfFolderItem[idx].folderItem.folderId ===
        item.folderId
      ) {
        let tempItem = this.breadCrumbArrayOfFolderItem.pop();
        this.previoulyPoppedFolderItem = tempItem;
        tempItem.folderItem.isCollapse = false;
        if (tempItem.folderItem.parentId === 0) {
          this.folderMgmt.setFolderList(
            this.folderMgmt.getTrackRecord(item.parentId).children
          );
        }
        flag = false;
        break;
      } else {
        let tempItem = this.breadCrumbArrayOfFolderItem.pop();
        this.previoulyPoppedFolderItem = tempItem;
        tempItem.folderItem.isCollapse = false;
        if (tempItem.folderItem.parentId === 0) {
          this.folderMgmt.setFolderList(
            this.folderMgmt.getTrackRecord(item.parentId).children
          );
        }
        idx = this.breadCrumbArrayOfFolderItem.length - 1;
      }
    }
    if (flag) {
      this.breadCrumbArrayOfFolderItem.push(tempObject);
      this.previoulyPoppedFolderItem = null;
      tempObject.folderItem.isCollapse = true;
    }

    this.folderMgmt.infoOfOpenedFolder$.next(this.breadCrumbArrayOfFolderItem);
  }
  //function to make our data structure empty.

  //method for opening folder on clickin bread crumb item
  async openFolderThroughBreadCrumb(item: any) {
    let idx = this.breadCrumbArrayOfFolderItem.length - 1;
    while (this.breadCrumbArrayOfFolderItem.length > 0) {
      if (
        this.breadCrumbArrayOfFolderItem[idx].folderItem.folderId ===
        item.folderId
      ) {
        this.breadCrumbArrayOfFolderItem.pop();
        item.isCollapse = true;
        await this.openFolder(item);
        break;
      } else {
        this.breadCrumbArrayOfFolderItem.pop();
      }
      idx = this.breadCrumbArrayOfFolderItem.length - 1;
    }
  }
  onCancel() {
    this.trimImageFrom.controls['imageName'].setValue(
      this.imageNameForOnCancel
    );
  }
  //recursive method for opening folders based on the opened folder array
  async doRedirectionUsingRedirectionArray(childrens: any, arr: any, idx: any) {
    let tempFlag;
    let tempIndex = 0;
    if (!arr || idx === arr.length || !childrens) return;
    for (let i = 0; i < childrens.length; i++) {
      tempIndex = i;
      if (childrens[i].folderId === arr[idx].folderItem.folderId) {
        tempFlag = true;
        break;
      } else {
        tempFlag = false;
      }
    }
    if (tempFlag) {
      childrens[tempIndex].isCollapse = true;
      await this.openFolder(childrens[tempIndex]);
      await this.doRedirectionUsingRedirectionArray(
        childrens[tempIndex].children,
        arr,
        idx + 1
      );
    }
  }

  //Bug 166465: DAM : Admin : Wrong folder gets selected.
  //Onclicking cross-icon of copy/move dialog box cross-icon
  onCopyMoveDialogShow() {
    let pdialogIcon = document.getElementsByClassName(
      'p-dialog-header-icons'
    )[0];

    pdialogIcon.addEventListener('click', () => {
      this.emptyFileList();
    });
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    let clickedInside = this.eRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.contextmenu = false;
    }
  }

  searchResult(id: any, assetPath: any) {
    this.appService.searchResult(id, { ...assetPath });
  }

  scrollToAssetContainerTop() {
    let ele = document.getElementById('assetsMngScrollContainer');
    if (ele) {
      ele.scrollTop = 0;
    }
  }

  handleAdd(tagToAdd: any) {
    const tagValue = tagToAdd.value.toLowerCase();
    const tagArray = this.uploadBulkTagsForm.value.tagArray;

    for (let i = 0; i < tagArray.length - 1; i++) {
      if (tagArray[i].toLowerCase() === tagValue) {
        // Tag already exists, remove the last added tag
        this.uploadBulkTagsForm.value.tagArray.pop();
      }
    }
  }

  async checkforPreSignedUrl(assetId?: any) {
    this.preSignedUrl = await this.appDataService.generatePreSignedUrl(assetId, this.preSignedUrl);
  }

  logs(assetId: any, action: any){
    const logs = {
      data : {
        assetId : assetId
      },
      action : action
    }
    this.appDataService.serverLog(logs);
  }  

  closeCropImage() {
    const editorElement = document.querySelector('lib-ngx-photo-editor');
    if (editorElement && editorElement.parentNode) {
      editorElement.parentNode.removeChild(editorElement);
    }
  }
}
