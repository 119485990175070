import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { environment } from "src/app/environment/environment";
import { LoaderService } from "src/app/core/services/loader.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';

// Global variable for Google api
declare var gapi: any;
declare var google: any;


@Injectable()
export class GdriveShare {
    //googleApi tokenClient for share To Drive
    tokenClient: any;
    private readonly apiUrl = 'https://www.googleapis.com/drive/v3/files';
    private readonly folderMimeType = 'application/vnd.google-apps.folder';

    constructor(private messageService: MessageService, private loader: LoaderService, private http: HttpClient, private appService: AppService) {
        //Init G-Drive Services
        this.gapiLoaded();
        this.gisLoaded();
    }//constructor

    /**
     * Callback after api.js is loaded.
     */
    gapiLoaded() {
        if (gapi) {
          gapi.load('client', this.initializeGapiClient);
        }
    }//gapiLoaded

    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    async initializeGapiClient() {
        await gapi.client.init({
            apiKey: environment.API_KEY,
            discoveryDocs: [environment.DISCOVERY_DOC],
        });
    }//initializeGapiClient

    /**
     * Callback after Google Identity Services are loaded.
     */
    async gisLoaded() {
        this.tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: environment.CLIENT_ID,
            scope: environment.SCOPES,
            callback: '', // defined later
        });
    }//gisLoaded


    /**
     *  Sign in the user upon button click.
     */
    async handleAuthClick(fileS3url: string, fileName: string) {
        this.tokenClient.callback = async (resp: any) => {
            if (resp.error !== undefined) {
                throw (resp);
            }
            await this.createFiles(fileS3url, fileName);
        };
        if (gapi.client.getToken() === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            this.tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            await this.createFiles(fileS3url, fileName);
        }
    }//handleAuthClick

    async handleAuthClickFolder(folderName: string, asset:any) {
        return new Promise(async(resolve, reject) => {
            this.tokenClient.callback = async (resp: any) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }
                let temp = await this.createFolder(folderName,asset);
                resolve(temp);
            };
            if (gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                this.tokenClient.requestAccessToken({ prompt: 'consent' });
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                let temp = await this.createFolder(folderName,asset)
                resolve(temp)
            }
            reject(undefined);
        });
    }
    /**
   * create metadata file.
   */
    async createFiles(fileS3url: string, fileName: string) {
        //set spiner-Loader true
        this.loader.setLoading(true);
        try {
            let blob = await fetch(fileS3url).then(r => r.blob());
            //set file metadata
            var metadata = {
                name: fileName,
                // mimeType : 'plain/text'
            };

            var formData = new FormData();
            formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            formData.append("file", blob);

            await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
                method: 'POST',
                headers: new Headers({ "Authorization": "Bearer " + gapi.auth.getToken().access_token }),
                body: formData
            }).then(function (response) {
                return response.json()
            }).then(value => {
                this.messageService.add({ severity: 'success', summary: 'Asset shared successfully', detail: `${value.name} file is shared on Drive.`});
            });
            //set spiner-Loader false
            this.loader.setLoading(false);
        } catch (err:any) {
            //set spiner-Loader false
            this.loader.setLoading(false);
            this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: `ShareToDrive Say's:: ${err.message}` });
            return;
        }
    }//createFiles

    isUserLoggedIn(){
        return gapi.client.getToken() !== null;
    }//isUserLoggedIn

    async getUserLoggedIn(callbackFun:any):Promise<any>{
        this.tokenClient.callback = async (resp: any) => {
            if (resp.error !== undefined) {
                throw (resp);
            }
            callbackFun();
        };
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        await this.tokenClient.requestAccessToken({ prompt: 'consent' });
    }


    async createFolder(folderName: string, asset:any){
        this.loader.setLoading(true);
        return new Promise(async(resolve, reject) => {
            try{
                let outPutOfFolderCreation = undefined;
                var folderMetadata = {
                    name: folderName,
                    mimeType: this.folderMimeType,
                  };
                  await fetch("https://www.googleapis.com/drive/v3/files", {
                    method: 'POST',
                    headers: {
                      "Authorization": "Bearer " + gapi.auth.getToken().access_token,
                      "Content-Type": "application/json", // Add Content-Type header
                    },
                    body: JSON.stringify(folderMetadata), // Convert folderMetadata to JSON string
                  }).then(function (response) {
                    return response.json();
                  }).then(async(value) => {
                    outPutOfFolderCreation = value;
                    this.messageService.add({ severity: 'success', summary: `Folder ${value.name} is created on Drive.`, detail:'Folder creation started on your g-drive'});
                    this.loader.setLoading(false);
                    const uploadPromises = [];
                    for(let i = 0 ; i < asset.length ; i++)
                    {
                        let assetItem = asset[i];
                        uploadPromises.push(this.shareAssetOnGdrive(assetItem,value.id));
                    }
                    await Promise.all(uploadPromises);
                    this.messageService.add({ severity: 'success', summary: 'Asset shared successfully', detail: `Success! All assets added to your g-drive` });
                  });
            resolve(outPutOfFolderCreation);
        } catch (err:any) {
            this.loader.setLoading(false);
            this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: `ShareToDrive Say's:: ${err.message}` });
            reject(undefined);
        }
    });
}

async shareAssetOnGdrive(_source: any,folderId:any) {
    await this.createFileInFolder(this.appService.s3BaseUrl + _source.assetPath + '/' + _source.assetName, _source.assetName,folderId);
  }

async createFileInFolder(fileS3url: string, fileName: string, folderId: string) {
    try {
      // Fetch the file content
      let blob = await fetch(fileS3url).then(r => r.blob());
      // Create file metadata with parent folder ID
      var metadata = {
        name: fileName,
        parents: [folderId],
      };
      var formData = new FormData();
      formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      formData.append("file", blob);

      await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + gapi.auth.getToken().access_token,
        },
        body: formData,
      }).then(function (response) {
        return response.json();
      }).then(value => {
        //this.messageService.add({ severity: 'success', summary: 'Asset shared successfully', detail: `This ${value.name} file is shared on Drive.` });
      });
    } catch (err: any) {
      // Handle errors
      this.loader.setLoading(false);
      this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: `Error sharing file: ${err.message}` });
    }
  }
}//GdriveShareAssetToFolder
