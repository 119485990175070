import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetNotFoundComponent } from './common/components/asset-not-found/asset-not-found.component';
import { AccessDeniedComponent } from './shared/components';
import { AuthGuard } from './core/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./theme/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./theme/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard], // Apply AuthGuard to protected routes
  },
  {
    path: 'client',
    data: { moduleSlug: 'client-manager' },
    loadChildren: () =>
      import('./theme/client/client.module').then((m) => m.ClientModule),
  },
  { path: 'asset-not-found', component: AssetNotFoundComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: AccessDeniedComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: false,
      paramsInheritanceStrategy: location.search.startsWith('?always')
        ? 'always'
        : 'emptyOnly',
    }),
  ],
  providers: [AuthGuard], // Provide AuthGuard
  exports: [RouterModule],
})
export class AppRoutingModule {}
