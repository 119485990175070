import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FIELDS } from 'src/app/core/constants';
import { globals } from 'src/app/core/constants/globals';
import { AppDataService, LocalStorageService} from 'src/app/core/services';

export interface IsearchWithAdvProperty {
  adv_search?: string;
  exact_phrase_search: Boolean;
  single_asset_from_single_folder: Boolean;
  approved_assets: Boolean;
  search_within: {
    ai_tags: Boolean;
    manual_tags: Boolean;
    description: Boolean;
    event: Boolean;
    location: Boolean;
    title: Boolean;
    default_tags: Boolean;
    asset_id: Boolean;
    srt:Boolean;
  };
  asset_type: {
    image: Boolean;
    video: Boolean;
    audio: Boolean;
    doc: Boolean;
    other: Boolean;
  };
  asset_size: {
    size: String;
    sizeInBytes: Number;
    size_for: 'less_than' | 'greater_than' | 'equal_to' | 'null';
  };
  date: {
    start_date: String;
    end_date: String;
  };
}
export interface IElk_searchRequestBody {
  searchString: String;
  filters: Array<any>;
  range: {
    assetSize: {
      gt: Number;
      lt: Number;
    };
  }; //range
  sort: {};
  size: number;
  from: number;
}

@Component({
  selector: 'app-advance-filters',
  templateUrl: './advance-filters.component.html',
  styleUrls: ['./advance-filters.component.scss'],
})
export class AdvanceFiltersComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() searchWithAdvProperty: IsearchWithAdvProperty;
  fields = FIELDS;
  filterForm: FormGroup;
  sortByAttributeName: any = '';
  sortByOrderName: any = 'desc';
  @Input() elk_searchRequestBody: IElk_searchRequestBody;
  searchInputText: any;
  calendarMaxDate : Date;
  calendarEndDate : Date;
  startDate : Date | null;
  endDate : Date | null;

  constructor(
    private appDataService: AppDataService,
    private localStorageService: LocalStorageService
  ) {
    const storedSearchWithAdvProperty = this.localStorageService.getItem(
      globals.searchWithAdvProperty
    );
    const storedAppliedFilter = this.localStorageService.getItem(globals.appliedFilter);

    if (storedAppliedFilter) {
      this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
      this.appDataService.setFlag(true);
    } else {
      this.searchWithAdvProperty = storedSearchWithAdvProperty
        ? JSON.parse(storedSearchWithAdvProperty)
        : {
            adv_search: '',
            exact_phrase_search: false,
            single_asset_from_single_folder: false,
            approved_assets: false,
            search_within: {
              ai_tags: false,
              manual_tags: false,
              description: false,
              event: false,
              location: false,
              title: false,
              default_tags: false,
              asset_id: false,
              srt:false,
            },
            asset_type: {
              image: false,
              video: false,
              audio: false,
              doc: false,
              other: false,
            },
            asset_size: {
              size: '',
              size_for: 'greater_than',
            },
            date: {
              start_date: '',
              end_date: '',
            },
          };
    }
  }

  ngOnInit(): void {
    this.calendarMaxDate = new Date();
  }

  async replaceAdvSearchWithMainSearch() {
    if (!this.searchWithAdvProperty.adv_search || this.checkDateValues() || this.searchWithAdvProperty.adv_search.trim() == '') {
      return; //adding this case to avoid the search result from enter key press when apply button is disabled
    }
    this.searchWithAdvProperty.date.start_date = this.searchWithAdvProperty.date.start_date != '' ? this.formatDate(new Date(String(this.searchWithAdvProperty.date.start_date))) : '';
    this.searchWithAdvProperty.date.end_date = this.searchWithAdvProperty.date.end_date != '' ? this.formatDate(new Date(String(this.searchWithAdvProperty.date.end_date))) : '';
    this.searchInputText =
      this.searchWithAdvProperty.adv_search == undefined
        ? ''
        : this.searchWithAdvProperty.adv_search;
    this.localStorageService.addItem(globals.searchtext, this.searchInputText);

    this.searchWithAdvProperty.asset_size.size_for =
      this.searchWithAdvProperty.asset_size.size ?
        (this.searchWithAdvProperty.asset_size.size_for === 'null' ?
          'equal_to' : this.searchWithAdvProperty.asset_size.size_for) : 'null';
    

    this.localStorageService.addItem(
      globals.appliedFilter,
      JSON.stringify(this.searchWithAdvProperty)
    );
    this.passEntry.emit({
      type: 'apply',
      data: {
        model: this.searchWithAdvProperty,
      },
    });
    this.closeAdvanceFilters();
  }

  closeAdvanceFilters() {
    document.getElementById('closeAdvFilter')?.click();
  }

  openAdvanceFilterModal(data: any) {
    if (data) {
      this.searchInputText = data;
      this.searchWithAdvProperty.adv_search = data;
      //initialize searchWithAdvProperty
      const storedSearchWithAdvProperty = this.localStorageService.getItem(
        globals.searchWithAdvProperty
      );
      const storedAppliedFilter = this.localStorageService.getItem(globals.appliedFilter);

      if (storedAppliedFilter) {
        this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
        this.searchWithAdvProperty.adv_search = data;
        this.startDate = this.searchWithAdvProperty.date.start_date != '' ? new Date(String(this.searchWithAdvProperty.date.start_date)) : null;
        this.endDate = this.searchWithAdvProperty.date.end_date != '' ? new Date(String(this.searchWithAdvProperty.date.end_date)) : null;
        this.searchWithAdvProperty.date.start_date = this.searchWithAdvProperty.date.start_date != '' ? String(new Date(String(this.searchWithAdvProperty.date.start_date))) : '';
        this.searchWithAdvProperty.date.end_date = this.searchWithAdvProperty.date.end_date != '' ? String(new Date(String(this.searchWithAdvProperty.date.end_date))) : '';
      } else {
        this.searchWithAdvProperty = storedSearchWithAdvProperty
          ? JSON.parse(storedSearchWithAdvProperty)
          : {
              adv_search: data,
              exact_phrase_search: false,
              single_asset_from_single_folder: false,
              approved_assets: false,
              search_within: {
                ai_tags: false,
                manual_tags: false,
                description: false,
                event: false,
                location: false,
                title: false,
                default_tags: false,
                asset_id: false,
                srt:false,
              },
              asset_type: {
                image: false,
                video: false,
                audio: false,
                doc: false,
                other: false,
              },
              asset_size: {
                size: '',
                size_for: 'null',
              },
              date: {
                start_date: '',
                end_date: '',
              },
            };
        this.searchWithAdvProperty.adv_search = data;
      }
    }else {
      this.searchWithAdvProperty.adv_search = data;
    }
  }

  changeRadioButtonForAssetSize(value: any) {
    if (
      value == 'less_than' &&
      this.searchWithAdvProperty.asset_size.size_for == 'less_than'
    ) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
      return;
    }
    if (
      value == 'greater_than' &&
      this.searchWithAdvProperty.asset_size.size_for == 'greater_than'
    ) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
      return;
    }
  }

  convertMegabyteToByte(e: any) {
    let valueConvertedToBytes = 0;
    if (!isNaN(e)) {
      valueConvertedToBytes = Math.trunc(e * 1024 * 1024);
    }
    this.searchWithAdvProperty.asset_size.sizeInBytes = valueConvertedToBytes;
  }

  onInputChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value !== '') {
      // Remove non-numeric and non-decimal point characters
      input.value = input.value.replace(/[^0-9.]/g, '');

      // Ensure there's only one decimal point
      const parts = input.value.split('.');
      if (parts.length > 2) {
        input.value = parts[0] + '.' + parts.slice(1).join('');
      }
      if (input.value === '.' || input.value.startsWith('.')) {
        input.value = '';
      }

      // Update the form control value
      this.searchWithAdvProperty.asset_size.size = input.value;
    }
    if (!this.searchWithAdvProperty.asset_size.size) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
    }
  }

  async resetSearchWithAdvProperty() {
    const storedSearchWithAdvProperty = this.localStorageService.getItem(
      globals.searchWithAdvProperty
    );
    const storedAppliedFilter =  this.localStorageService.getItem(globals.appliedFilter);

    if (storedAppliedFilter) {
      this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
    } else {
      this.searchWithAdvProperty = storedSearchWithAdvProperty
        ? JSON.parse(storedSearchWithAdvProperty)
        : {
            adv_search: this.searchInputText,
            exact_phrase_search: false,
            single_asset_from_single_folder: false,
            approved_assets: false,
            search_within: {
              ai_tags: false,
              manual_tags: false,
              description: false,
              event: false,
              location: false,
              title: false,
              default_tags: false,
              asset_id: false,
              srt:false,
            },
            asset_type: {
              image: false,
              video: false,
              audio: false,
              doc: false,
              other: false,
            },
            asset_size: {
              size: '',
              size_for: '',
            },
            date: {
              start_date: '',
              end_date: '',
            },
          };
    }

    this.startDate = null;
    this.endDate = null;

    this.passEntry.emit({
      type: 'reset',
      data: {
        model: this.searchWithAdvProperty,
      },
    });
  }

  //resetting searchTextField
  resetSearchTextField() {
    if (this.searchWithAdvProperty.search_within.asset_id == true) {
      this.searchWithAdvProperty.adv_search = "";
      this.searchWithAdvProperty.exact_phrase_search = false;
      this.searchWithAdvProperty.single_asset_from_single_folder = false;
      this.searchWithAdvProperty.approved_assets = false;

      this.searchWithAdvProperty.search_within.ai_tags = false;
      this.searchWithAdvProperty.search_within.manual_tags = false;
      this.searchWithAdvProperty.search_within.description = false;
      this.searchWithAdvProperty.search_within.event = false;
      this.searchWithAdvProperty.search_within.location = false;
      this.searchWithAdvProperty.search_within.title = false;
      this.searchWithAdvProperty.search_within.default_tags = false;
      this.searchWithAdvProperty.search_within.srt = false;

      this.searchWithAdvProperty.asset_type.image = false;
      this.searchWithAdvProperty.asset_type.video = false;
      this.searchWithAdvProperty.asset_type.audio = false;
      this.searchWithAdvProperty.asset_type.doc = false;
      this.searchWithAdvProperty.asset_type.other = false;

      this.searchWithAdvProperty.asset_size.size = '';
      this.searchWithAdvProperty.asset_size.size_for = 'null';

      this.searchWithAdvProperty.date.start_date = '';
      this.searchWithAdvProperty.date.end_date = '';
      this.startDate = null;
      this.endDate = null;
  }
}

  onKeyDown(event: KeyboardEvent) {
    if (this.searchWithAdvProperty.search_within.asset_id) {
      const invalidChars = ['-', '+', 'e', 'E','ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
  }

onModelChange(event: any) {
    if (this.searchWithAdvProperty.search_within.asset_id) {
      this.searchWithAdvProperty.adv_search = this.searchWithAdvProperty.adv_search?.replace(/\D/g, '');
    }
}

checkDateValues(): boolean {
  const startDate: any = this.searchWithAdvProperty.date.start_date != '' ? new Date(String(this.searchWithAdvProperty.date.start_date)) : '';
  const endDate: any = this.searchWithAdvProperty.date.end_date != '' ? new Date(String(this.searchWithAdvProperty.date.end_date)) : '';
  if (!startDate && endDate) {
    return true;
  }
  if (startDate <= this.getCurrentDate() && endDate >= startDate) {
    return false;
  }
  else{
    return true;
  }
}

getCurrentDate() {
  const currentDate = new Date();
  // Get the current date in YYYY-MM-DD format
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  const day = ('0' + currentDate.getDate()).slice(-2);
  return new Date(`${year}-${month}-${day}`);
}

onStartChange(event: any){
  const year = event.getFullYear();
  const month = ('0' + (event.getMonth() + 1)).slice(-2);
  const day = ('0' + event.getDate()).slice(-2);
  const date = `${year}-${month}-${day}`;
  this.searchWithAdvProperty.date.start_date = event;
  this.endDate = null;
  this.searchWithAdvProperty.date.end_date = '';
}

onendDateChange(event: any){
  const year = event.getFullYear();
  const month = ('0' + (event.getMonth() + 1)).slice(-2);
  const day = ('0' + event.getDate()).slice(-2);
  const date = `${year}-${month}-${day}`;
  this.searchWithAdvProperty.date.end_date = event;
}


formatDate(date: any) {
  // Get the date in YYYY-MM-DD format
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return (`${year}-${month}-${day}`);
}

closeAdvanceFilter() {
    this.passEntry.emit({
      type: 'close',
      data: {
        model: this.searchWithAdvProperty,
      },
    });
}

}

