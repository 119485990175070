import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-grid-zoom-slider',
  templateUrl: './grid-zoom-slider.component.html',
  styleUrls: ['./grid-zoom-slider.component.scss'],
})
export class GridZoomSliderComponent implements OnInit, OnDestroy {
  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();

  constructor() {}
  ngOnInit(): void {}

  onChange(event: Event) {
    const { target } = event;
    if (target) {
      this.value = Number((target as HTMLInputElement).value);
      this.valueChange.emit(this.value);
    }
  }

  sub() {
    if (this.value === 2 || this.value === 3) {
      this.value--;
      this.valueChange.emit(this.value);
    }
  }

  add() {
    if (this.value === 1 || this.value === 2) {
      this.value++;
      this.valueChange.emit(this.value);
    }
  }
  ngOnDestroy(): void {}
}
