<div class="navcntr d-lg-flex d-none justify-content-between w-100 px-3">
        <div class="d-flex gap-4 justify-content-between align-items-center">                      
          <ng-container >
            <div *ngFor="let item of theMenuItems;let ind=index">
              <a class="d-flex align-items-center font-medium fs-14" (click)="outputSelectedMenuItem( item)">
                <i class="nm_icon me-2">
                  <img src="{{item.icon}}" alt="">
                </i>
                {{item.name}}
              </a>
            </div> 
          </ng-container>
    
        </div>
    
        
        
      </div>
