export const FIELDS = [
    {
      minLength: 2,
      maxLength: 16,
      inputPattern: /^[a-zA-Z ]*$/,
      pattern: /^[A-Za-z][a-zA-Z ]*$/,
      type: "checkbox",
      subtype: "text",
      validators: "Validators.required",
      value: "",
      placeholder: "Name",
      formControlName: "name",
      serverField: "PiName",
      error: "",
      showError: false,
      instruction: "",
    },
    {
      minLength: 2,
      maxLength: 16,
      inputPattern: /^[a-zA-Z ]*$/,
      pattern: /^[A-Za-z][a-zA-Z ]*$/,
      type: "input",
      subtype: "text",
      validators: "Validators.required",
      value: "",
      icon: "edit.svg",
      placeholder: "Name",
      formControlName: "name",
      serverField: "PiName",
      error: "",
      showError: false,
      instruction: "Enter 2-16 characters including upto 2 spaces",
    },
    {
      type: "input",
      subtype: "email",
      validators: "Validators.required, Validators.email",
      pattern: /^[a-zA-Z0-9.@-_+]*$/,
      value: "",
      icon: "edit.svg",
      placeholder: "Email",
      formControlName: "email",
      serverField: "PiEmail",
      error: "",
      showError: false,
      instruction: "",
    },
    {
      maxLength: 10,
      minLength: 10,
      inputPattern: "[0-9]+",
      pattern: /^([0-9]+)$/,
      type: "input",
      subtype: "tel",
      validators: "Validators.required",
      value: "",
      icon: "edit.svg",
      placeholder: "Mobile Number",
      formControlName: "mobileNumber",
      serverField: "PiPhoneNo",
      error: "",
      showError: false,
      instruction: "Enter 10 digits",
    },
  ];
  