import { Injectable } from '@angular/core';

interface MenuMasterItem {
  route?: string;
  label?: string;
  menuType?: string;
  sequence?: number;
  pos?: string;
  isPage: boolean;
  viewType?: string;
}

interface InputItem {
  active: boolean;
  name: string;
  code: string;
  checked: boolean;
  groupBy: string;
  actions: Record<string, ActionItem>;
  subFeatures?: Record<string, unknown>;
}

interface ActionItem {
  active: boolean;
  label: string;
  checked: boolean;
}

@Injectable()
export class MenuItems {
  transformData = (
    input: Record<string, InputItem>,
    menuMaster: Record<string, MenuMasterItem>
  ) => {
    const result = {
      label: 'User Preferences',
      preferences: [] as Array<Record<string, unknown>>,
    };

    for (const [key, value] of Object.entries(input)) {
      // if (!value.active) continue;

      const menuItem = menuMaster[key];
      if (!menuItem) continue;

      const item = {
        id: menuItem.sequence || 0,
        label: menuItem.label ? menuItem.label : value.name,
        code: value.code.toLowerCase(),
        route: menuItem.route || '',
        sequence: menuItem.sequence || 0,
        menuType: menuItem.menuType || '',
        viewType: menuItem.viewType || '',
        isPage: menuItem.isPage || '',
        pos: menuItem.pos || '',
        actions: value.actions,
      };

      if (value.groupBy) {
        const groupItem = result.preferences.find(
          (pref) => (pref as any).code === value.groupBy
        );
        if (groupItem) {
          (groupItem as any).childrens.push(item);
          (groupItem as any).childrens.sort(
            (a: any, b: any) => (a.sequence as number) - (b.sequence as number)
          );
        } else {
          const groupMenuItem = menuMaster[value.groupBy];
          result.preferences.push({
            id: groupMenuItem ? groupMenuItem.sequence : 0,
            label: groupMenuItem ? groupMenuItem.label : value.groupBy,
            code: value.groupBy,
            route: groupMenuItem ? groupMenuItem.route : '',
            menuType: groupMenuItem ? groupMenuItem.menuType : '',
            viewType: groupMenuItem ? groupMenuItem.viewType : '',
            sequence: groupMenuItem ? groupMenuItem.sequence : 0,
            isPage: groupMenuItem?.isPage || false,
            pos: groupMenuItem?.pos || '',
            childrens: [item],
          });
        }
      } else {
        result.preferences.push(item);
      }
    }

    // result.preferences.forEach((pref) => {
    //   if ((pref as any).childrens) {
    //     (pref as any).childrens.sort(
    //       (a: any, b: any) => (a.sequence as number) - (b.sequence as number)
    //     );
    //   }
    // });

    result.preferences.sort(
      (a: Record<string, unknown>, b: Record<string, unknown>) =>
        (a.sequence as number) - (b.sequence as number)
    );

    return result;
  };
}
