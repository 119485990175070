import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { globals } from '../constants/globals';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userInfo: any;
  private deviceHash: any;
  private userPreference: any;
  private selectedCompany: any;
  private googleEmail = '';
  private googleToken = '';

  constructor(private cookie: CookieService) {}

  setUserFromAuthResponse(authResponse: any) {
    this.userInfo = authResponse.Userinfo;
    // store selected company as pref
    const prevStoredCompPref = this.cookie.get(globals.defaultCorp);
    this.selectedCompany = authResponse.CompanyList[0];
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 365);
    this.cookie.set(
      globals.defaultCorp,
      JSON.stringify(this.selectedCompany),
      expiredDate,
      '/'
    );
  }

  setGoogleUser(email: string, token: string) {
    this.googleEmail = email;
    this.googleToken = token;
  }

  getGoogleUser() {
    return {
      email: this.googleEmail,
      token: this.googleToken,
    };
  }

  setUserPreference(data: any) {
    this.userPreference = data;
  }

  getUserPreference() {
    return this.userPreference;
  }

  getUserInfo() {
    return this.userInfo;
  }

  getSelectedCompany() {
    const savedDefaultCorp = this.cookie.get(globals.defaultCorp);
    return (
      this.selectedCompany || (savedDefaultCorp && JSON.parse(savedDefaultCorp))
    );
  }

  getReleaseData() {
    const release = this.cookie.get(globals.releaseCookie);
    return release ? JSON.parse(release) : undefined;
  }

  clearReleaseData() {
    this.cookie.delete(globals.releaseCookie);
  }

  setReleaseData() {
    const release = {
      version: globals.releaseNumber,
    };
    return this.cookie.set(globals.releaseCookie, JSON.stringify(release));
  }

  hasCompanyAccess(compId: any) {
    if (!compId) {
      return false;
    }
    // for (let i = 0; i < this.getCompanyList().length; i++) {
    //   if (compId === this.getCompanyList()[i].CompanyId) {
    //     return true;
    //   }
    // }
    return false;
  }

  getDefaultNav() {
    const company = this.getSelectedCompany();
    return company.DefaultNav;
  }

  getPrefrenceByMenu(menuId: number) {
    const menu = this.userPreference.UsrPref.filter(
      (item: any) => item.MenuId === menuId
    )[0];
    return menu;
  }

  boxArrToObj(array: any[]) {
    const o = array.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {});
    return o;
  }

  isString(arg: any) {
    return typeof arg === 'string';
  }

  isObject(arg: null) {
    return arg !== null && typeof arg === 'object';
  }

  getBoxesVisibilityByMenu(menu: any) {
    switch (menu) {
      // Dashboard
      case 1002:
        const m = this.getPrefrenceByMenu(1002);
        const boxes: { key: string; value: any }[] = [];
        if (m && m.ReportList.length > 0) {
          m.ReportList.forEach((report: { Boxes: any[] }, index: any) => {
            report.Boxes.forEach((box) => {
              const boxDesc = 'BOX' + box.BoxId;
              boxes.push({ key: boxDesc, value: box.Value });
            });
          });
        }
        return this.boxArrToObj(boxes);
    }
  }

  getDeviceHash() {
    return this.deviceHash;
  }
}
