<div class="main-container mt-5 pt-5 ">
    <div class="container h-100">
      <div class="row h-100 row-cols-1 flex-column justify-content-center align-items-center">
        <div class="col col-md-6 text-center mt-5">
          <span>
            <img src="./assets/images/icons/ic_access_denied.svg" class="" alt="" />
          </span>
        </div>
        <div class="col text-center mt-5" style="color: #407bff">
          <h1 class="text-uppercase font-bold">ACCESS DENIED</h1>
          <p class="py-3 fs-5 font-regular text-dark">
            You don't have permission to view this. Please contact the administrator!
          </p>
        </div>
      </div>
    </div>
  </div>