import { AdvanceFiltersComponent } from './advance-filters/advance-filters.component';
import { AssetsMasterComponent } from './assets-master/assets-master.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { CustomContextMenuComponent } from './custom-context-menu/custom-context-menu.component';
import { DynamicNestedListComponent } from './dynamic-nested-list/dynamic-nested-list.component';
import { CreateFolderComponent } from './create-folder/create-folder.component';
import { HeaderComponent } from './header/header.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { SharedErrorPageComponent } from './shared-error-page/shared-error-page.component';
import { SidenavAdminComponent } from './sidenav-admin/sidenav-admin.component';
import { TableComponent } from './table/table.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UploadComponent } from './upload/upload.component';
import { SliderRangeComponent } from './video-trim/components/slider-range/slider-range.component';
import { VideoTrimComponent } from './video-trim/video-trim.component';
import { DeletepopupComponent } from './deletepopup/deletepopup.component';
import { BtnDropdownComponent } from './btn-dropdown/btn-dropdown.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { AssetPreviewComponent } from './asset-preview/asset-preview.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AddToCollectionComponent } from './add-to-collection/add-to-collection.component';
import { OnboardNewUserComponent } from './onboard-new-user/onboard-new-user.component';
import { GridZoomSliderComponent } from './grid-zoom-slider/grid-zoom-slider.component';
import { AddEventModalComponent } from './add-event-modal/add-event-modal.component';
import { NoPreviewComponent } from './no-preview/no-preview.component';

export * from './assets-master/assets-master.component';
export * from './context-menu/context-menu.component';
export * from './custom-context-menu/custom-context-menu.component';
export * from './dynamic-nested-list/dynamic-nested-list.component';
export * from './create-folder/create-folder.component';
export * from './header/header.component';
export * from './paginator/paginator.component';
export * from './sidenav-admin/sidenav-admin.component';
export * from './toolbar/toolbar.component';
export * from './upload/upload.component';
export * from './video-trim/components/slider-range/slider-range.component';
export * from './video-trim/video-trim.component';
export * from './table/table.component';
export * from './shared-error-page/shared-error-page.component';
export * from './advance-filters/advance-filters.component';
export * from './deletepopup/deletepopup.component';
export * from './btn-dropdown/btn-dropdown.component';
export * from './search-input/search-input.component';
export * from './asset-preview/asset-preview.component';
export * from './access-denied/access-denied.component';
export * from './add-to-collection/add-to-collection.component';
export * from './onboard-new-user/onboard-new-user.component';
export * from './grid-zoom-slider/grid-zoom-slider.component';
export * from './add-event-modal/add-event-modal.component';

export const components = [
  SidenavAdminComponent,
  HeaderComponent,
  SearchInputComponent,
  PaginatorComponent,
  DynamicNestedListComponent,
  CreateFolderComponent,
  ContextMenuComponent,
  UploadComponent,
  VideoTrimComponent,
  SliderRangeComponent,
  AssetsMasterComponent,
  ToolbarComponent,
  CustomContextMenuComponent,
  TableComponent,
  SharedErrorPageComponent,
  AdvanceFiltersComponent,
  DeletepopupComponent,
  BtnDropdownComponent,
  AssetPreviewComponent,
  AccessDeniedComponent,
  AddToCollectionComponent,
  OnboardNewUserComponent,
  GridZoomSliderComponent,
  AddEventModalComponent,
  NoPreviewComponent,
];
