import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

const CONFERENCE_INFO_KEY = 'conferenceInfo';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private prefix = 'jdam';
  private onSubject = new Subject<{ key: string; value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());
  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: any) {
    if (event.storageArea == localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }

  addItem(key: string, value: any) {
    try {
      localStorage.setItem(`${this.prefix}.${key}`, JSON.stringify(value));
    } catch (err) {
      console.error();
    }
  }

  removeItem(key: any) {
    try {
      localStorage.removeItem(`${this.prefix}.${key}`);
    } catch (err) {
      console.error();
    }
  }

  getItem(key: string) {
    try {
      const item = localStorage.getItem(`${this.prefix}.${key}`);
      return item ? JSON.parse(item) : null;
    } catch (err) {
      console.error();
    }
  }

  clear() {
    try {
      localStorage.clear();
    } catch (err) {
      console.error();
    }
  }

  storeConferenceInfo(conferenceInfo: any) {
    this.addItem(CONFERENCE_INFO_KEY, conferenceInfo);
  }

  getConferenceInfo() {
    return this.getItem(CONFERENCE_INFO_KEY);
  }
}
