import { FilterPipe } from './filter.pipe';
import { FormatBytesPipe } from './format-bytes.pipe';
import { FormatS3UrlPipe } from './format-s3-url.pipe';
import { GetFileExtPipe } from './get-file-ext.pipe';
import { HighlighterPipe } from './highlighter.pipe';

export * from './format-bytes.pipe';
export * from './format-bytes.pipe';
export * from './highlighter.pipe';
export * from './format-s3-url.pipe';
export * from './get-file-ext.pipe';

export const pipes = [
  FormatBytesPipe,
  FilterPipe,
  HighlighterPipe,
  FormatS3UrlPipe,
  GetFileExtPipe,
];
