import { Component } from '@angular/core';
import { LoaderService } from 'src/app/core/services';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  isLoading: boolean = false;

  constructor(
    public loader: LoaderService,
  ) {}

  ngOnInit() {
    this.loader.loaderState.subscribe((state: boolean) => {
      this.isLoading = state;
    });
  }
}
