<div class="preview-slider-modal d-block">
  <div (click)="closeSlider()">
    <button class="btn-close" type="button" (click)="closeSlider()"></button>
  </div>
  <div class="h-100">
    <div class="upper-slider-wrapper">
      <ngx-slick-carousel class="carousel upper-slider pb-0" #slickModal="slick-carousel" [config]="slideConfig"
        (beforeChange)="beforeChange($event)" (afterChange)="afterChange($event)">
        <div ngxSlickItem *ngFor="let asset of assetImageList; let i = index " class="slide">
          <div class="m-2 my-0">
            <div *ngIf="asset?.assetType== 0" class="text-center">
              <img *ngIf="(asset$ | async) as assets" [src]="assets" 
              class="mw-100" alt="Img" loading="lazy"/>
            </div>
            <div *ngIf="asset?.assetType== 1 " class="text-center">
              <video id="media{{i}}"
                [src]="asset$ | async"
                class="mw-100 mh-100 m-auto" alt="" width="" height="" controls disablePictureInPicture
                controlsList="noplaybackrate nodownload">
              </video>
            </div>
            <div *ngIf="asset?.assetType== 3 " class="text-center h-auto w-100">
              <img src="assets/images/icons/audio_icon.svg" class="mw-100 mh-100 radius-12 bg-white mb-4 w-25"
                alt="Audio">
              <audio id="media{{i}}"
                [src]="asset$ | async"
                class="w-50 m-auto" alt="" width="200px" height="" controls controlsList="noplaybackrate nodownload">
              </audio>
            </div>
            <div class="events__item-title d-flex justify-content-between px-3">
              <span class="text-truncate w-75">{{asset?.title || asset?.assetName}}</span>
              <div class="d-flex gap-lg-2">
                <i (click)="searchResult(asset._id, asset?.assetPath)" class="prev_page_dd_info_icons">
                  <img src="/assets/images/pages/asset-card/asset_preview_detail_icon.svg" alt="">
                  <span class="hover-text-details">Details</span>
                </i>
                <i (click)="onActionChange(asset)" class="prev_page_dd_info_icons">
                  <img src="/assets/images/pages/asset-card/ic_download.svg" style="height: 25px;">
                  <span class="hover-text-details">Download</span>
                </i>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>