<div *ngIf="type==='switch'">
  <div class="btn-group">
    <label class="btn btn-switch br-r-0 btn-mini m-b-0" *ngFor="let item of options;let i=index" (click)="onSelect(i)"
      [ngClass]="{'active':item.Value===selected.value}">
      <strong class="text-transform-uppercase" innerHtml="{{item.label}}"></strong>
    </label>
  </div>
</div>
<div class="btn-dropdown-container" *ngIf="type==='dropdown' && !multiselect">
  <button class="nh-icon-button">
    <ng-container *ngIf="!customBtnTemplate">
      <a class="drpdwn-btn dropdown-toggle text-decoration-none" role="button" id="dropdownMenuLink"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span innerHtml="{{title}}"></span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
      </a>
    </ng-container>
    <ng-container *ngIf="customBtnTemplate" [ngTemplateOutlet]="customBtnTemplate"
      [ngTemplateOutletContext]="{ data: { badge:getBadge(),dropdownRef:'dropdownMenuLink'} }">
    </ng-container>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <li *ngFor="let option of options;let i =index">
        <button class="dropdown-item" (click)="onSelect(i)" value="50"
          [ngClass]="{'active':option.value===selected.value}">
          <span class="text-transform-uppercase" innerHtml="{{option.label}}"></span>
        </button>
      </li>
    </ul>
  </button>
</div>
<div class="btn-dropdown-container" *ngIf="type==='dropdown' && multiselect">
  <button class="nh-icon-button">
    <ng-container *ngIf="!customBtnTemplate">
      <a class="drpdwn-btn dropdown-toggle text-decoration-none" role="button" id="multiSelectDropdownMenuLink"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span innerHtml="{{title}}"></span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
      </a>
    </ng-container>
    <ng-container *ngIf="customBtnTemplate" [ngTemplateOutlet]="customBtnTemplate"
      [ngTemplateOutletContext]="{ data: { badge:getBadge(),dropdownRef:'multiSelectDropdownMenuLink'} }">
    </ng-container>
    <ul class="dropdown-menu" aria-labelledby="multiSelectDropdownMenuLink">
      <li role="menuitem" class="dropdown-item multselect-item" *ngFor="let option of options; let i=index">
        <input type="checkbox" [value]="option.checked" id="btn-checkbox-{{option.id}}" name="{{option.label}}"
          (click)="onSelect(i)" [checked]="option.checked" />
        <label for="{{option.id}}">
          {{ option.label }}
        </label>
      </li>
    </ul>
  </button>
</div>
