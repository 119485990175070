import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AppDataService,
  EventData,
  EventEmitterService,
  LocalStorageService,
  RestService,
} from 'src/app/core/services';
import { PermissionsEngine } from '../../../common/permission/permission';
import { MessageService } from 'primeng/api';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader.service';
import { API_KEYPOINT, APP_EVENTS, APP_ROUTE } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { FolderMgmtUtill } from 'src/app/common/utils/folderMgmtUtill';
import { globals } from 'src/app/core/constants/globals';
export interface IHeaderModuleOption {
  url: string;
  label: string;
  // isShowSidebar : boolean;
  isShowMenu: Boolean;
  moduleSlug: string;
  isChildOpen: boolean;
  childs?: Array<{
    url: string;
    number: number;
    imgUrl: string;
    activeImgUrl: string;
    label: string;
    isShowMenu: Boolean;
    moduleSlug: string;
  }>;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userDetails: any;
  isUserRole: any;
  public routerConfig: any = [];
  getUserName: any = '';
  getUserFirstName: any = '';
  getUserLastName: any = '';
  getUserRoleName: any;
  getUserRoleId: any;
  activeIndex: any;
  isMenuCollapsed = true;
  toggleSearch: boolean = false;
  isAddedToCollection: boolean = false;
  isQueueListModalOpen = false;
  queueList: any = [];
  pollingSubscription: Subscription | undefined;
  id: any;
  clickOutsideListener: any;
  @ViewChild('searchtext') inputElementForSearch: ElementRef;
  @ViewChild('DropdownMenu') DropdownMenu: ElementRef;
  private subscriptions: Subscription[] = [];
  imageRevRequest = {
    circumference: 0,
    donePercentage: 0,
    status: '',
    doneCount: 0,
    totalCount: 0,
  };
  getUserAssignedClients: any;
  public appRouteUrl = APP_ROUTE;
  commonUtils = new CommonUtils();
  private eventSubscription: Subscription | undefined;
  constructor(
    private messageService: MessageService,
    public _router: Router,
    private restService: RestService,
    private localStorageService: LocalStorageService,
    public permissionsEngine: PermissionsEngine,
    private authService: AuthService,
    private renderer: Renderer2,
    private eventEmitterService: EventEmitterService,
    private loader: LoaderService,
    private appDataService: AppDataService,
    private folderMgmtUtils: FolderMgmtUtill
  ) {}

  ngOnInit() {
    this.userDetails = this.authService.getAuthData();
    this.isUserRole = this.userDetails.isSuperAdmin
      ? 'Client Manager'
      : this.userDetails?.role;
    // this.permissionsEngine.setup().then((date) => {
    //   this.initData();
    // });
    this.initData();
    this.isAddedToCollection = false;
    this.getLoggedInUserData();
    this.appDataService.getAddtoCollectionEvent().subscribe((data) => {
      // Handle the data change from search detail component
      this.isAddedToCollection = data;
    });
    // window.addEventListener('storage', (event) => {
    //   if (event.key === 'logout-event' && event.newValue === 'logout') {
    //     window.location.href = 'auth/login';
    //   }
    // });
    this.imageRevRequest.circumference = Math.PI * 2 * 40;
    this.subscriptions.push(
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            if (event.data.display) {
              if (event.data.visible) {
                this.appDataService.stopPollingFlag();
              } else {
                this.appDataService.startPollingFlag();
              }
            } else {
            }
            break;
        }
      }),
      this.appDataService.flag$.subscribe((flag: any) => {
        this.imageRevRequest.doneCount = flag.processed || 0.1;
        this.imageRevRequest.totalCount = flag.total;
        const percentageDone = Math.round(
          (100 * this.imageRevRequest.doneCount) /
            this.imageRevRequest.totalCount
        );
        const progress =
          this.imageRevRequest.circumference * (percentageDone / 100);
        this.imageRevRequest.donePercentage =
          this.imageRevRequest.circumference - progress;
        if (this.imageRevRequest.totalCount == this.imageRevRequest.doneCount) {
          this.appDataService.stopPollingFlag();
        }
      })
    );
    this.subscribeToEvent();
    this.subscriptions.push(this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Re-subscribe to the event if necessary when navigation ends
        if (!this.eventSubscription || this.eventSubscription.closed) {
          this.subscribeToEvent();
        }
      }
    }));
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe((event: EventData) => {
      if (event.type === APP_EVENTS.BROWSER_BACK) {
        if (this.isQueueListModalOpen) {
          this.getQueueList();
        }
        else{
          this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
            data: {
              display: true,
              visible: false,
            },
          });
        }
      }
    });
    this.subscriptions.push(this.eventSubscription);
  }

  ngAfterViewInit() {
    this.folderMgmtUtils.openQueueListModal$.subscribe((data) => {
      if (data === true) {
        const dropdownToggle = document.getElementById('navbarDropdown');
        if (dropdownToggle) {
          if (this.DropdownMenu.nativeElement) {
            this.isQueueListModalOpen = false;
            this.DropdownMenu.nativeElement.classList.add('show');
            this.getQueueList();
          }
        }
      }
    });
  }

  logout() {
    let authProvider = this.localStorageService.getItem(
      globals.oAuthProvider
    );
    this.restService
      .post(API_KEYPOINT.logOut + (authProvider ? '/' + authProvider : ''), {})
      .subscribe({
        next: (res: any) => {
          this.appDataService.stopPollingFlag();
          this.authService.logout(res.logoutUrl);
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Please relogin',
            detail: `error in logout Says: ${error.error.message}`,
          });
        },
      });
  }

  initData() {
    this.routerConfig = '';
    let myHeaderOptions: Array<IHeaderModuleOption> = [
      {
        url: this.appRouteUrl.dashboard,
        label: 'Dashboard',
        isShowMenu: this.permissionsEngine.hasPermission(
          'dashboard',
          'view_asset_dashboard'
        ),
        moduleSlug: 'dashboard',
        isChildOpen: false,
      },
      {
        url: '',
        label: 'Management',
        isShowMenu: this.permissionsEngine.hasPermission(
          'folder',
          'view_folder'
        ),
        moduleSlug: 'management',
        isChildOpen: true,
        childs: [
          {
            url: this.appRouteUrl.users,
            number: 1,
            label: 'Users',
            imgUrl: './assets/images/pages/management/users.svg',
            activeImgUrl: './assets/images/pages/management/users-active.svg',
            isShowMenu: this.permissionsEngine.hasPermission(
              'user',
              'view_users'
            ),
            moduleSlug: 'user',
          },
          {
            url: this.appRouteUrl.roles,
            number: 2,
            label: 'Roles',
            imgUrl: './assets/images/pages/management/roles.svg',
            activeImgUrl: './assets/images/pages/management/roles-active.svg',
            isShowMenu: this.permissionsEngine.hasPermission(
              'role',
              'view_roles'
            ),
            moduleSlug: 'role',
          },
          {
            url: this.appRouteUrl.folders,
            number: 3,
            label: 'Folders',
            imgUrl: './assets/images/pages/management/roles.svg',
            activeImgUrl: './assets/images/pages/management/roles-active.svg',
            isShowMenu: this.permissionsEngine.hasPermission(
              'folder',
              'folder_access_management'
            ),
            moduleSlug: 'folder',
          },
          {
            url: this.appRouteUrl.tags,
            number: 3,
            label: 'Tags',
            imgUrl: './assets/images/pages/management/tags.svg',
            activeImgUrl: './assets/images/pages/management/tags-active.svg',
            isShowMenu: this.permissionsEngine.hasPermission(
              'tags',
              'tag_management'
            ),
            moduleSlug: 'tags',
          },
          {
            url: this.appRouteUrl.events,
            number: 4,
            label: 'Events',
            imgUrl: './assets/images/pages/management/events.svg',
            activeImgUrl: './assets/images/pages/management/events-active.svg',
            isShowMenu: this.permissionsEngine.hasPermission(
              'events',
              'event_management'
            ),
            moduleSlug: 'events',
          },
        ],
      },
      {
        url: this.appRouteUrl.assets,
        label: 'Assets',
        moduleSlug: 'folder',
        isChildOpen: false,
        isShowMenu: this.permissionsEngine.hasPermission(
          'folder',
          'view_folder'
        ),
      },
    ];
    myHeaderOptions.forEach((tab) => {
      if (tab.isChildOpen) {
        tab.isShowMenu =
          tab.childs != undefined &&
          tab.childs.filter((childTab) => childTab.isShowMenu == true).length >
            0;
      }
    });
    this.routerConfig = myHeaderOptions;
  }
  isShowMenu(arr: String[]): boolean {
    const hasAllElems = arr.every((elem: any) =>
      this.routerConfig.includes(elem)
    );
    return !!hasAllElems;
  }

  getLoggedInUserData() {
    this.restService
      .get(API_KEYPOINT.user.userDetails + this.userDetails.userId)
      .subscribe((data: any) => {
        this.getUserName = data.result.firstName;
        this.getUserFirstName = data.result.firstName.charAt(0).toUpperCase();
        this.getUserLastName = data.result.lastName.charAt(0).toUpperCase();
        this.getUserRoleName = data.result.roleName;
        this.getUserAssignedClients = data.result.assignedClients;
        this.getUserRoleId = data.result.roleId;
        if (data.result?.isRequestForImageRevSearch) {
          this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
            data: {
              display: true,
              visible: false,
            },
          });
        } else {
          this.imageRevRequest.totalCount = 0;
          this.imageRevRequest.doneCount = 0;
        }
      });
  }

  setRouterPath(item: any) {
    if (!item.url) {
      item.isChildOpen = !item.isChildOpen;
      return false;
    }
    this._router.navigate([item.url]);
  }
  redirectChild(item: any) {
    this._router.navigate([item.url]);
  }

  toggleSidenav(item: any) {
    if (item.url) {
      this._router.navigate([item.url]);
      // this.headerService.emitSidenavSubject(item);
    } else {
      // this.headerService.emitSidenavSubject(item);
    }
  }

  setActiveIndex(index: any) {
    this.activeIndex = index;
  }

  getActiveClass(i: any) {
    return this.activeIndex == i ? 'active' : '';
  }

  getAssetsListSearch(searchtext: any) {
    if (searchtext) {
      this.appDataService.setHeader(true);
      this.localStorageService.addItem(globals.searchtext, searchtext);
      this._router.navigateByUrl(this.appRouteUrl.searchDetails);
    }
  }

  onToggleSearch() {
    this.toggleSearch = !this.toggleSearch;
  }

  goToPeopleManagement() {
    this._router.navigateByUrl(this.appRouteUrl.peoples);
  }

  goToCliplist() {
    this._router.navigateByUrl(this.appRouteUrl.cliplist);
  }

  goToCollections() {
    this._router.navigateByUrl(this.appRouteUrl.collections);
  }

  showQueueListModal = false;
  getQueueList() {
    // Toggle the modal state
    this.isQueueListModalOpen = !this.isQueueListModalOpen;

    // Toggle polling based on the modal state
    if (this.isQueueListModalOpen) {
      this.startPolling();
    } else {
      this.stopPolling();
    }

    // If the modal is open, add a click event listener to close it when clicking outside
    if (this.isQueueListModalOpen) {
      this.addClickOutsideListener();
    } else {
      // If the modal is closed, remove the click event listener (if previously added)
      this.removeClickOutsideListener();
    }
  }

  addClickOutsideListener() {
    if (!this.clickOutsideListener) {
      this.clickOutsideListener = this.renderer.listen(
        'window',
        'click',
        (e: Event) => {
          const modalElement = document.getElementById('queueListToggleButton');

          if (!modalElement?.contains(e.target as Node)) {
            // Clicked outside of the modal, close it and stop polling
            this.isQueueListModalOpen = false;
            this.stopPolling();
            this.removeClickOutsideListener();
          }
        }
      );
    }
  }

  removeClickOutsideListener() {
    if (this.clickOutsideListener) {
      this.clickOutsideListener = undefined; // Clear the listener reference
    }
  }

  startPolling() {
    if (!this.pollingSubscription) {
      this.pollingSubscription = interval(1000) // Emit a value every 5 seconds
        .pipe(
          switchMap(() =>
            this.restService.post(
              API_KEYPOINT.dashboard.getQueueStatus,
              '',
              '',
              false
            )
          )
        )
        .subscribe({
          next: (data: any) => {
            if (data.code == 200) {
              this.queueList = data.result;
            }
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
    }
  }

  stopPolling() {
    // Check if polling is active and unsubscribe to stop it
    if (this.pollingSubscription) {
      this.restService
        .delete(API_KEYPOINT.dashboard.clearDoneListOfQueue, '', '', false)
        .subscribe({
          next: (data) => {},
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
      this.DropdownMenu.nativeElement.classList.remove('show');
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = undefined; // Clear the subscription
    }
  }

  deleteAssetFromQueue(e: any) {
    //this.stopPolling();
    this.restService
      .delete(API_KEYPOINT.dashboard.deleteFromQueue + e)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.isQueueListModalOpen = false;
            this.getQueueList();
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  //to reset input search bar. 153469->Text is not getting vanished after closing search bar
  handleOpenSearchBar() {
    this.inputElementForSearch.nativeElement.value = '';
    this.inputElementForSearch.nativeElement.focus();
  }

  // retry functionality

  async retryDownload(
    id: any,
    assetArr: any,
    folderPath: any,
    folderId: any,
    downloadType: any
  ) {
    this.stopPolling();
    if (downloadType == 'M') {
      let FolderPath: any = {};
      FolderPath.Ids = folderId;
      FolderPath.Url = folderPath;
      const cleanedString = assetArr.replace(/[\[\]"\s]/g, '');
      // Split the cleaned string by commas to get an array of links
      const assets = cleanedString.split(',');
      const params = {
        assetArr: assets,
        OriginPath: FolderPath,
      };

      const options = {
        responseType: 'blob',
      };

      this.restService
        .post(API_KEYPOINT.assets.assetMultiDownload, params, options)
        .subscribe({
          next: (res: any) => {
            this.deleteAssetFromQueue(id);
            this.loader.setLoading(false);
            this.folderMgmtUtils.setOpenQueueTrigger(true);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!!!',
              detail: 'Zip will be downloaded and sent to you via email!',
            });
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message || 'Something went wrong !',
            });
          },
        });
    } else {
      const params = {
        folderId: folderId,
        folderURL: folderPath,
      };

      const options = {
        responseType: 'blob',
      };

      this.restService
        .post(API_KEYPOINT.folder.folderDownload, params, options)
        .subscribe({
          next: (res: any) => {
            this.deleteAssetFromQueue(id);
            this.loader.setLoading(false);
            this.folderMgmtUtils.setOpenQueueTrigger(true);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!!!',
              detail: 'Zip will be downloaded and sent to you via email!',
            });
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message || 'Something went wrong !',
            });
          },
        });
    }
  }

  maximizeGlobalUploadModal() {
    this.appDataService.stopPollingFlag();
    this.eventEmitterService.emit({
      type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
      data: {
        display: true,
        visible: true,
      },
    });
  }

  ngOnDestroy() {
    this.folderMgmtUtils.setOpenQueueTrigger(false);
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
