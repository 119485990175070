import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  SimpleChanges,
  SimpleChange,
  OnDestroy,
} from '@angular/core';

export interface BtnDropdownDto {
  id: number;
  value: any;
  label: string;
  checked?: boolean;
  selectAll?: boolean;
}
@Component({
  selector: 'app-btn-dropdown',
  templateUrl: './btn-dropdown.component.html',
  styleUrls: ['./btn-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BtnDropdownComponent implements OnInit, OnDestroy {
  @Output() filterSelected = new EventEmitter<any>();
  @Input() options: any = [];
  @Input() base: any;
  @Input() type: any = 'dropdown';
  @Input() title: any;
  @Input() badge: boolean = false;
  @Input() multiselect?: boolean = false;
  @Input() customBtnTemplate?: any;
  selected: any;

  constructor() {}

  ngOnInit() {
    if (this.multiselect) {
      this.options.forEach((o: any) => {
        // check all option by ref to base array
      });
    } else {
      this.selected = this.base === undefined ? this.options[0] : this.base;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const _base: SimpleChange = changes.base;
    if (_base && _base.currentValue) {
      this.selected = _base.currentValue;
    }
  }

  checkSelection(collection: any[], pre: any) {
    // let selectAllIndex = collection.findIndex((item) => item.selectAll);
    // let withOutSelectAll = collection.reduce((t) => t.selectAll, 0);
    let withOutSelectAll = collection.filter((obj) => !obj.selectAll);
    return withOutSelectAll.every((obj: any) => obj[pre]);
  }

  emitSelection() {
    if (this.multiselect) {
      this.filterSelected.emit(this.getAllSelected());
    } else {
      this.filterSelected.emit(this.selected);
    }
  }

  onSelect(index: number) {
    if (this.multiselect) {
      this.options[index].checked = !this.options[index].checked;
      if (this.options[index].selectAll) {
        if (this.options[index].checked) {
          this.updateAll(true);
          this.emitSelection();
          return;
        } else {
          this.updateAll(false);
          this.emitSelection();
          return;
        }
      }
      if (this.checkSelection(this.options, 'checked')) {
        this.updateAllSelectBoxes(true);
        this.emitSelection();
        return;
      } else {
        this.updateAllSelectBoxes(false);
        this.emitSelection();
        return;
      }
    } else {
      this.selected = this.options[index];
      this.emitSelection();
    }
  }

  updateAll(value?: boolean) {
    this.options.forEach((o: any) => {
      o.checked = value;
    });
  }

  updateAllSelectBoxes(value?: boolean) {
    this.options.forEach((o: any) => {
      if (o.selectAll) {
        o.checked = value;
      }
    });
  }

  getAllSelected() {
    let selections = this.options.filter((o: any) => o.checked);
    if (selections?.length === this.options.length) {
      return [];
    }
    return this.options.filter((o: any) => o.checked);
  }

  getBadge() {
    let withOutSelectAll = this.options.filter((o: any) => !o.selectAll);
    if (this.badge) {
      return withOutSelectAll.filter((x: any) => x.checked).length;
    } else {
      return 0;
    }
  }

  ngOnDestroy(): void {
    this.options.forEach((input: any) => {
      input.checked = false;
    });
  }
}
