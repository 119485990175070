import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonUtils } from 'src/app/common/utils/common.utils';
@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Input() disable: boolean = false;
  @Input() searchUrl: any;
  @Input() placeholder: string = 'Search using keyword';
  @Input() searchText?: string = '';
  commonUtils = new CommonUtils();

  constructor(){}
  
  ngOnInit(): void {
    this.searchText = this.searchText ? this.searchText : '';
  }

  @Output()
  searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onSearchModelChange: EventEmitter<string> = new EventEmitter<string>();

  onSearchTextChanged(input: any) {
    if (this.searchText && this.commonUtils.isValidSearch(this.searchText)) {
      this.searchTextChanged.emit(this.searchText.trim());
    } else if (input.dirty && this.searchText === '') {
      this.searchTextChanged.emit(this.searchText.trim());
      input.reset();
      this.searchText = '';
    }
  }

  onSearchChange() {
    this.onSearchModelChange.emit(this.searchText?.trim());
  }

  reset(input: any) {
    input.reset();
    this.searchText = '';
    this.searchTextChanged.emit(this.searchText);
  }
}
