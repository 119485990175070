<div class="main-container h-100">
    <div class="container position-relative h-100">
        <div class="notFoundBox position-absolute row row-cols-1 flex-column justify-content-center align-items-center">
            <div class="col col-md-6">
                <span>
                    <img src="/assets/images/icons/img-404.svg" class="img-fluid" alt="" />
                </span>
            </div>
            <div class="col text-center" style="color: #407bff">
                <h1 class="text-uppercase font-bold">Asset not found</h1>
                <p class="py-3 fs-5 font-regular text-dark">
                    The asset may have been deleted or is no longer available in this location.
                </p>
            </div>
        </div>
    </div>
</div>