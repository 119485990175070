import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileExt',
})
export class GetFileExtPipe implements PipeTransform {
  transform(inputStr: string, ...args: unknown[]): unknown {
    if (args?.length) {
      const prefix = args[0] || '';
      const suffix = args[1] || '';
      return (
        prefix +
        (inputStr ? inputStr.substring(inputStr.lastIndexOf('.') + 1) : '') +
        suffix
      );
    }
    return inputStr.substring(inputStr.lastIndexOf('.') + 1);
  }
}
