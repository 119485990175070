import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/core/services/app.service';
import { S3UploadServices } from '../../../core/services/s3-upload-services.service';
import { LoaderService, RestService } from 'src/app/core/services';
import pLimit from 'p-limit';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormatBytesPipe } from 'src/app/shared/pipes';
import { API_KEYPOINT, ASSET_TYPE_CONFIG } from 'src/app/core/constants';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  originUrl: String = window.location.origin;
  @Input() childFolderId: any;
  @Input() assetPathofFolder: any;
  @Input() modalType: any;
  @Output() closeModalEvent = new EventEmitter<any>();
  progressInfos: any = [];
  message: string[] = [];
  selectedFiles: File[] = [];
  isModalClose: boolean = true;
  isSaveButtonClicked = false;
  isSaveDisabled: boolean = false;
  isModalCopy: boolean = false;
  formD: any = '';
  progressInfosDetails: number = 0;
  success: Boolean = false;
  successFileNameArray: any = [];
  fileAlreadyExistsOrError: any = [];
  selectedFileNameArray: any = [];
  progressList: any = [];
  zipSize: any = 0;
  successFileDetails: any = [];
  uploadDone: boolean = false;
  uploadDoneWithError: boolean = false;
  assetNames: any = [];
  internalServerErrorWithFile: any = [];
  unSupportedFile: any = [];
  allow: any = true;
  @ViewChild('fileInput') fileInput: ElementRef;
  zipSizeValue: boolean = false;
  progressOfUplodingFile: any = [];
  totalAssetSize: number = 0;
  selectedFilesList: File[] = [];
  userDetails: any;
  isFileZip: boolean = false;
  isUploadClicked: boolean = false;
  private subscriptions: Subscription[] = [];
  formatBytesPipe = new FormatBytesPipe();

  constructor(
    private s3UploadService: S3UploadServices,
    public appService: AppService,
    public authService: AuthService,
    public loader: LoaderService,
    private messageService: MessageService,
    private restService: RestService
  ) {
    this.s3UploadService.notificationForProgress.subscribe((data) => {
      const { i, progress } = data;
      // Check if the index exists in the array
      if (this.progressOfUplodingFile[i]) {
        // Update the progress if the index exists
        this.progressOfUplodingFile[i].progress = progress;
      } else {
        // If the index doesn't exist, initialize it with 0 progress
        this.progressOfUplodingFile[i] = { i, progress: 0 };
      }
    });
  }

  ngOnInit(): void {
    this.allow = this.modalType !== 'zip';
    this.userDetails = this.authService.getAuthData();
  }

  // ========== Browse files method for select single and multiple assets for upload=====
  selectFiles(event: any) {
    const processFilesChunk = async (
      files: any[],
      startIndex: number,
      chunkSize: number
    ) => {
      const endIndex = Math.min(startIndex + chunkSize, files.length);

      const filesToProcess = files.slice(startIndex, endIndex);
      await Promise.all(filesToProcess.map(processFile));

      if (endIndex < files.length) {
        await processFilesChunk(files, endIndex, chunkSize);
      } else {
        if (this.modalType === 'zip') {
          this.callUploadZipFile();
        }
      }
    };

    const processFile = async (file: any) => {
      try {
        // Processing logic for all file types
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (
          !this.selectedFileNameArray.includes(file.name) &&
          !this.successFileNameArray.includes(file.name) &&
          (ext != 'zip' || this.modalType == 'zip')
        ) {
          this.isSaveDisabled = false;
          this.isSaveButtonClicked = false;
          this.uploadDone = false;
          this.isUploadClicked = false;
          this.progressOfUplodingFile = [];
          this.totalAssetSize += file.size;
          this.selectedFiles.push(file);
          this.selectedFilesList = [...this.selectedFiles]; // Update selectedFilesList
          this.selectedFileNameArray.push(file.name);
        } else if (
          this.selectedFileNameArray.includes(file.name) ||
          this.successFileNameArray.includes(file.name)
        ) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'File already exist in the list',
          });
        } else if (ext == 'zip') {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'Please use "Zip Upload" to upload zip file.',
          });
        }
      } catch (error) {
        console.error();
      }
    };

    const handleFiles = () => {
      if (event && event.target && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        const chunkSize = 100; // Batch process 100 files

        processFilesChunk(files, 0, chunkSize);
      } else {
        let dragArray: any = Array.from(event);
        this.selectedFiles.push(dragArray);
        this.selectedFiles = Array.prototype.concat.apply(
          [],
          this.selectedFiles
        );
      }
    };

    if (this.childFolderId || parseInt(this.childFolderId) === 0) {
      handleFiles();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Folder not selected, Please select the folder.',
      });
    }
    event = null;
  }

callUploadZipFile() {
  if (!this.selectedFiles || !this.selectedFiles.length) {
    return;
  }

  const zipExtensions = ['zip'];

  this.selectedFiles = this.selectedFiles.filter((file: any) => {
    const ext = file.name.split('.').pop().toLowerCase();
    const fileSize:any = this.formatBytesPipe.transform(file.size, 2);
    const [size, unit] = fileSize.split(' ');
    const sizeValue = parseFloat(size);
    this.isFileZip = !zipExtensions.includes(ext);
    this.zipSize = sizeValue;
    this.zipSizeValue = unit === 'MB' && sizeValue > 100;

    if (this.isFileZip) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Invalid File Extension, Zip file required.',
      });
      return false; // Remove the file
    }

    if (unit === 'GB' && sizeValue > 5) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'File size must be less than 5GB.',
      });
      this.selectedFiles = [];
      this.progressInfos = [];
      this.selectedFileNameArray = [];
      return false; // Remove the file
    }

    return true; // Keep the file
  });
}

  //----------------------------Function to upload multiple Files----------------------
  async uploadFiles() {
    this.isUploadClicked = true;
    this.isSaveDisabled = true;
    this.isSaveButtonClicked = true;
    this.message = [];

    if (!this.selectedFilesList || this.selectedFilesList.length === 0) {
      this.message.push('File Required');
      return;
    }

    const batchSize = 25; // Set batch size to 25
    const limit = pLimit(batchSize); // Limit concurrency to 25 uploads

    const uploadingFilesList = [...this.selectedFilesList];

    if (this.modalType === 'zip') {
      this.zipUpload(this.selectedFiles);
    } else {
      let ReUploadAssets: any = [];
      let i = 0;

      const uploadPromises = [];

      // Function to upload a single file and handle errors
      const uploadFile = async (file: any, index: any) => {
        try {
          if (this.uploadDoneWithError) {
            if (this.internalServerErrorWithFile.includes(file)) {
              const indexOfFile =
                this.internalServerErrorWithFile.indexOf(file);
              if (indexOfFile !== -1) {
                this.internalServerErrorWithFile.splice(indexOfFile, 1);
                this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
              }
              return await this.upload(file, index);
            }
          } else {
            if (!this.successFileNameArray.includes(file.name)) {
              return await this.upload(file, index);
            } else {
              this.progressList[index] = 0;
            }
          }
        } catch (error) {
          ReUploadAssets.push(file); // Collect failed uploads
          this.progressList[index] = 0;
        }
        return null;
      };

      while (i < uploadingFilesList.length) {
        const batch = uploadingFilesList.slice(i, i + batchSize);
        const uploadBatchPromises = batch.map((file, index) => {
          return limit(() => uploadFile(file, i + index));
        });
        await Promise.all(uploadBatchPromises);
        i += batchSize;
      }

      // Move failed files to the top
      await this.fileAlreadyExistsOrError.map((fileName: any) => {
        const index = this.selectedFilesList.findIndex(
          (file) => file.name === fileName
        );
        if (index !== -1) {
          const [removedFile] = this.selectedFilesList.splice(index, 1);
          ReUploadAssets.push(removedFile);
        }
      });

      this.selectedFilesList = ReUploadAssets.concat(this.selectedFilesList);
      if (this.internalServerErrorWithFile.length > 0) {
        this.uploadDoneWithError = true;
        this.isUploadClicked = false;
      } else {
        this.uploadDone = true;
        this.uploadDoneWithError = false;
      }
    }
  }

  //----------------------------Single Asset Uplaod Function---------------------------------
  async upload(file: any, i: any) {
    return new Promise(async (resolve) => {
      this.isSaveButtonClicked = true;
      this.progressList[i] = 5;
      this.formD = new FormData();
      this.formD.append('folderId', this.childFolderId);
      this.formD.append('uploadedBy', '1');
      this.formD.append('assetPath', this.assetPathofFolder);
      this.formD.append('asset', file, file.name);
      this.formD.append('fileType', file?.type);

      const params = {
        fileName: file?.name,
        folderId: this.childFolderId,
        uploadedBy: '1',
        assetPath: this.assetPathofFolder,
        fileType: file?.type,
        assetSize: file?.size,
        uploadType: ASSET_TYPE_CONFIG.NORMAL_UPLOAD
      };

      this.progressInfos.push({
        name: file.name,
        index: i,
      });

      if (file) {
        try {
          let event = await this.s3UploadService.uploadFile(params, file, i);

          if (event.code === 200) {
            if (event.result[1] != null) {
              if (!this.successFileNameArray.includes(file.name)) {
                event.result[1].mimeType = file?.type;
                this.successFileDetails.push(event.result[1]);
                this.assetNames.push(event.result[2]);
                this.removeFileFromList(
                  this.internalServerErrorWithFile,
                  file.name
                );
                this.removeFileFromList(
                  this.fileAlreadyExistsOrError,
                  file.name
                );
              }
              this.successFileNameArray.push(file.name);
              resolve((this.success = true));
            } else if (event.result[0].length > 0) {
              this.fileAlreadyExistsOrError.push(file.name);
              resolve(false);
            }
          } else if (event.error) {
            this.handleUploadError(event, file, i);
            resolve(false);
          } else {
            this.handleUnknownError(file, i);
            resolve(false);
          }

          this.resetProgress(i);
        } catch (error) {
          console.error(error);
          this.handleUnknownError(file, i);
          resolve(false);
        }
      }
    });
  }

  removeFileFromList(fileList: any[], fileName: string) {
    const index = fileList.indexOf(fileName);
    if (index !== -1) {
      fileList.splice(index, 1);
    }
  }

  handleUploadError(event: any, file: any, i: any) {
    const { code, message } = event.error;
    this.fileAlreadyExistsOrError.push(file.name);

    if (code === 400) {
      if (!this.unSupportedFile.includes(file.name)) {
        this.unSupportedFile.push(file);
      }
    } else if (code === 500) {
      if (!this.internalServerErrorWithFile.includes(file)) {
        this.internalServerErrorWithFile.push(file);
      }
    } else {
      const msg =
        message === 'Request Entity Too Large.'
          ? 'Please do not upload over 10 files at once.'
          : 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
      this.internalServerErrorWithFile.push(file);
      this.message.push(msg);
    }

    this.resetProgress(i);
  }

  handleUnknownError(file: any, i: any) {
    const msg =
      'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    this.fileAlreadyExistsOrError.push(file.name);
    this.internalServerErrorWithFile.push(file);
    this.message.push(msg);
    this.resetProgress(i);
  }

  resetProgress(i: any) {
    this.progressList[i] = 0;
    this.progressOfUplodingFile[i] = 0;
  }

  async saveFiles() {
    const uploadLimit = 1000;
    const totalAssets = this.successFileDetails.length;
    const unlink = JSON.stringify(this.assetNames);
    
    const processBatch = async (batch: any[]) => {
      const params = {
        details: JSON.stringify(batch),
        unlink: unlink,
      };
  
      try {
        const data = await this.restService.post(API_KEYPOINT.assets.saveUploads, params).toPromise();
        this.handleBatchResponse(data);
      } catch (err: any) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message,
        });
      }
    };
  
    const batches = [];
    for (let i = 0; i < totalAssets; i += uploadLimit) {
      batches.push(this.successFileDetails.slice(i, i + uploadLimit));
    }
  
    for (const batch of batches) {
      await processBatch(batch);
    }
  }
  
  // Extract the common logic to a separate function for reusability
  handleBatchResponse(data: any) {
    this.successFileNameArray = [];
    this.uploadDone = false;
    this.isModalClose = false;
    this.fileAlreadyExistsOrError = [];
    this.selectedFiles = [];
    this.progressInfosDetails = 0;
    this.message = [];
    this.selectedFileNameArray = [];
    this.isSaveButtonClicked = false;
    this.isModalCopy = false;
    this.assetNames = [];
    let params = {
      assetUploadCount: this.successFileDetails.length,
      folderId: this.successFileDetails[0].folderId,
    };
    this.closeModalEvent.emit(params);
    this.successFileDetails = [];
    if (data.code == 200) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: 'Asset(s) uploaded successfully!',
      });
    }
  }

  zipUpload(file: any) {
    this.isSaveButtonClicked = true;
    this.formD = new FormData();
    this.formD.append('parentFolderId', this.childFolderId);
    this.formD.append('folderPath', this.assetPathofFolder + '/');
    
    this.selectedFiles.forEach((selectedFile, i) => {
      this.formD.append('zipFolder', selectedFile);
      this.progressInfos.push({
        name: selectedFile.name,
        index: i,
      });
    });
  
    if (file) {
      let messageDisplayed = false;
      const options = {
        reportProgress: true,
        observe: 'events',
        contentType: 'multipart/form-data',
      };
  
      this.subscriptions.push(
        this.restService.post(API_KEYPOINT.folder.uploadZipFolder, this.formD, options).subscribe({
          next: (event: any) => {
            this.loader.setLoading(messageDisplayed);
            
            if (event.type === HttpEventType.UploadProgress) {
              this.progressOfUplodingFile[0] = {
                i: 0,
                progress: Math.round((100 * event.loaded) / event.total),
              };
  
              if (this.progressOfUplodingFile[0].progress === 100 && !messageDisplayed) {
                messageDisplayed = true;
                this.loader.setLoading(true);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success!',
                  detail: 'Zip file is being extracted. Please wait for a while...',
                });
              }
            } else if (event instanceof HttpResponse) {
              this.handleSuccess(event.body.result.folderId, event.body.result.isAssetSkipped);
            }
          },
          error: (err) => {
            this.handleError(err);
          }
        })
      );
    }
  }
  
  private handleSuccess(folderId: string, isAssetSkipped: boolean) {
    this.isSaveButtonClicked = false;
    setTimeout(() => {
      this.isModalClose = false;
      this.selectedFileNameArray = [];
      this.progressInfosDetails = 0;
      this.closeModalEvent.emit({ success: true, folderId });
  
      if (isAssetSkipped) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'Uploaded successfully! However, some files were skipped due to unsupported formats.',
        });
      } else {
        this.messageService.add({
          severity: 'success',
          summary: 'Success!',
          detail: 'Zip uploaded and files extracted successfully.',
        });
      }
    }, 500);
  }
  
  private handleError(err: any) {
    this.isSaveButtonClicked = false;
    this.closeModalEvent.emit(false);
  
    let msg = 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    if (err.error.message === 'Request Entity Too Large.') {
      msg = 'Please do not upload files over 1 GB at once.';
    } else if (err.error.message === 'Cannot upload empty ZIP file.') {
      msg = 'Cannot upload an empty ZIP file.';
    }
    else{
      msg = err.error.message || "Something went wrong!"
    }
  
    this.message.push(msg);
    this.messageService.add({
      severity:'warn',
      summary: 'Warning!',
      detail: msg,
    });
  
    setTimeout(() => {
      this.isModalClose = false;
      this.selectedFileNameArray = [];
      this.progressInfosDetails = 0;
    }, 1000);
  }
  

  reset() {
    this.fileInput.nativeElement.value = '';
  }
  //================= delete selected file for media uploader============
  removeImage(media: any, idx: number) {
    this.progressInfos.splice(idx, 1);
    this.selectedFiles.splice(idx, 1);
    this.selectedFilesList.splice(idx, 1);
    this.message.pop();

    if (this.selectedFilesList.length == this.successFileDetails.length) {
      this.uploadDone = true;
    }

    const removedFileSize = media.size;
    this.totalAssetSize -= removedFileSize;

    const fileName = media.name;
    const ext = media.name.substring(media.name.lastIndexOf('.') + 1);

    if (ext === 'zip') {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.uploadDone = false;
      this.success = false;
      this.selectedFiles = [];
      this.progressInfosDetails = 0;
      this.successFileDetails = [];
      this.message = [];
      this.selectedFileNameArray = [];
      this.isSaveButtonClicked = false;
      this.isModalCopy = false;
      this.assetNames = [];
    } else {
      const successFileIndex = this.successFileNameArray.indexOf(fileName);
      if (successFileIndex !== -1) {
        const assetToBeDeleted: [] = this.successFileDetails.splice(
          successFileIndex,
          1
        );
        const assetPathToBeDeleted: [] = this.assetPathofFolder;

        const params = {
          assetArr: assetToBeDeleted,
          assetPath: assetPathToBeDeleted,
        };

        if(params.assetArr.length>0) this.restService.post(API_KEYPOINT.assets.clearStorage,params).subscribe(() => {});

        this.successFileNameArray.splice(successFileIndex, 1);
      }
      const fileIndex = this.fileAlreadyExistsOrError.indexOf(fileName);
      if (fileIndex !== -1) {
        this.fileAlreadyExistsOrError.splice(fileIndex, 1);
      }
      const fileIndexOfError = this.internalServerErrorWithFile.indexOf(media);
      if (fileIndexOfError !== -1) {
        this.internalServerErrorWithFile.splice(fileIndexOfError, 1);
      }
      this.selectedFileNameArray.splice(idx, 1);
    }
    if (this.selectedFiles.length == 0 || this.selectedFilesList.length == 0) {
      this.uploadDone = false;
      this.uploadDoneWithError = false;
      this.isUploadClicked = false;
      this.success = false;
      this.selectedFiles = [];
      this.progressInfosDetails = 0;
      this.successFileDetails = [];
      this.message = [];
      this.selectedFileNameArray = [];
      this.isSaveButtonClicked = false;
      this.isModalCopy = false;
      this.assetNames = [];
    }
    this.reset();
  }

  //=====================delete Assets after cancel===========================================

  delteAfterCancel() {
    let params = {
      assetArr: this.successFileDetails,
      assetPath: this.assetPathofFolder,
    };
    if(params.assetArr.length>0) this.restService.post(API_KEYPOINT.assets.clearStorage, params).subscribe(() => {});
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    if (this.uploadDone == true || this.successFileDetails) {
      this.delteAfterCancel();
    }
    this.uploadDone = false;
    this.success = false;
    this.closeModalEvent.emit(false);
    this.isModalClose = false;
    this.selectedFiles = [];
    this.progressInfosDetails = 0;
    this.successFileDetails = [];
    this.message = [];
    this.selectedFileNameArray = [];
    this.isSaveButtonClicked = false;
    this.isModalCopy = false;
    this.assetNames = [];
  }
  replaceName(name: any) {
    return (
      name.split(/\.(?=[^\.]+$)/)[0].replace(/[^A-Z0-9]+/gi, '_') +
      '.' +
      name.split(/\.(?=[^\.]+$)/)[1]
    );
  }

  async singleRetry(file: any, i: any) {
    this.message.pop();
    let indexOfFile = this.internalServerErrorWithFile.indexOf(file);
    if (indexOfFile !== -1) {
      this.internalServerErrorWithFile.splice(indexOfFile, 1);
      this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
    }
    await this.upload(file, i);
    if (this.internalServerErrorWithFile.length > 0) {
      this.uploadDoneWithError = true;
      this.isUploadClicked = false;
    } else {
      this.uploadDone = true;
      this.uploadDoneWithError = false;
    }
  }

  allowMultipleSelection() {
    if (this.modalType == 'zip') {
      return false;
    } else {
      return 'allow';
    }
  }
}
