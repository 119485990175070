<ul class="context-menu__items d-block p-0 mb-0">
  <ng-container *ngFor="let menuItem of theMenuItems">
    <li class="context-menu__item position-static ms-0 mb-0 cursorpointer" *ngIf="menuItem.hasPermission &&!(menuItem.id=='bulkTag' && this.selectedAssets.length < 2)" [ngClass]="selectedAssets.length < 2 && menuItem.id==='bulkTag'? 'd-none':''" [ngClass]="showSubContextMenu && menuItem.id== 'share' ?'active':''"
      (click)="checkBeforeCloseOfContextMenu($event);outputSelectedMenuItem( menuItem)">
      <div>
        <a class="context-menu__link d-flex align-items-center text-decoration-none" > 
          <i class="nm_icon me-2 w-10 ps-3">
            <img [src]="menuItem.icon" alt="Icons" width="15px" height="15px">
          </i>
          <span class="ms-3">{{ menuItem.name }}</span>
          <i class="nm_icon position-absolute right-arrow" *ngIf="menuItem.id == 'share'"  (click)="toggleSubContextMenu()">
            <img src="/assets/images/pages/context-menu/folder-arrow.png" alt="Icons">
          </i>
        </a>
      </div>
    </li>
  </ng-container>
</ul>
<div class="shareSubContextMenu" *ngIf="showSubContextMenu">
  <ng-container *ngFor="let menuItem of subContextMenuList">
    <li class="context-menu__item position-static ms-0 mb-0 cursorpointer"
      (click)="outputSelectedMenuItem( menuItem)">
      <div>
        <a class="context-menu__link d-flex align-items-center text-decoration-none">
          <i class="nm_icon me-2">
            <img [src]="menuItem.icon" alt="Icons" width="15px" height="15px">
          </i>
          <span>{{ menuItem.name }}</span>
        </a>
      </div>
    </li>
  </ng-container>
</div>

